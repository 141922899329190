import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";

interface ConfirmationModalProps {
    open: boolean;
    title: string;
    contentText: string;
    onClose: () => void;
    onConfirm: () => void;
    confirmButtonText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    title,
    contentText,
    onClose,
    onConfirm,
    confirmButtonText,
}) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{contentText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm} color="primary">
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmationModal;
