import { makeStyles } from '@material-ui/core';
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles({
	payoutContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "3rem",
		margin: "2rem 0",
	},
	radioGroup: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
	},
	textFields: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "40%",
	},
	left: {
		display: "flex",
		flexDirection: "row",
	},
	right: {
		display: "flex",
		flexDirection: "column",
		// justifyContent: 'center',
	},
	threshold: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
		alignItems: "center",
	},
	cap: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
		alignItems: "center",
		marginLeft: "2.2rem",
	},
	select: {
		width: "4rem",
	},
	inputThreshold: {
		maxWidth: "36.5%",
	},
	inputCap: {
		maxWidth: "40%",
	},
	paper: {
		padding: "1rem",
		maxWidth: "550px",
		marginBottom: "3rem",
	},
	advancedText: {
		maxWidth: 195,
	},
	advancedTextFields: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
	},
	advancedContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
		width: "100%",
		justifyContent: "center",
	},
	budgetContainer: {
		display: "flex",
		flexDirection: "row",
	},
	button: {
		cursor: "pointer",
		transition: "color 0.3s ease, background-color 0.3s ease",
		borderRadius: "50%",
		color: "#8B8589",
		"&:hover": {
			color: indigo[400],
		},
		"&:active": {
			backgroundColor: "rgba(0, 0, 0, 0.1)",
		},
	},
	buttonContainer: {
		display: "flex",
		paddingTop: "1rem",
	},
});
