import { create } from "zustand";
import api from "../../../utils/api";
import { ISortDirection, objColumns } from "../utils/types";
import {
	IGeneralQuery,
	IGeneralQueryWithCopy,
	IPaginatedQuery,
	IQueryFavorite,
	IQueryFavoriteWithSearch,
	IQueryFiltered,
} from "../../../hooks/useQuery";
import snack from "../../../utils/snack";
import { Objective } from "../../usersManagement/types/objectiveTypes";
import { IBuyer, IUser } from "../../../interfaces";
import { IWholeSaler } from "../../usersManagement/types/types";
import { AdvancedPayment } from "../../usersManagement/types/incentivesTypes";

export interface SalesRep {
	customId: string;
	name: string;
}

export interface Accounts {
	_id: string;
	name: string;
	salesRep: SalesRep;
	customId: string;
}

interface Store {
	objectives: Objective[];
	groupedObjectives: Objective[];
	filteredObjectives: Objective[];
	accounts: Accounts[];
	filteredCustomers: any;
	totalPages: number;
	totalFilteredPages: number;
	currentFilteredPage: number;
	loadingFiltered: boolean;
	totalGroupedPages: number;
	currentGroupedPage: number;
	loadingGrouped: boolean;
	currentPage: number;
	secondaryCurrentPage: number;
	reportingGroupedPage: number;
	secondaryTotalPages: number;
	reportingGroupedTotalPages: number;
	setSecondaryCurrentPage: (page: number) => void;
	setReportingGroupedPage: (page: number) => void;
	loading: boolean;
	archivedTotalPages: number;
	archivedCurrentPage: number;
	archivedSecondaryCurrentPage: number;
	archivedSecondaryTotalPages: number;
	setArchivedSecondaryCurrentPage: (page: number) => void;
	loadingArchived: boolean;
	archivedObjectives: Objective[];
	favoriteObjectives: Objective[];
	totalFavPages: number;
	currentFavPage: number;
	accountTypes: string[];
	users: IUser[];
	products: IWholeSaler | null;
	customers: IBuyer[] | null;
	setLoading: (loading: boolean) => void;
	setLoadingFiltered: (loading: boolean) => void;
	setLoadingGrouped: (loading: boolean) => void;
	setLoadingArchived: (loading: boolean) => void;
	setCurrentPage: (page: number) => void;
	setCurrentFilteredPage: (page: number) => void;
	setCurrentGroupedPage: (page: number) => void;
	setCurrentFavPage: (page: number) => void;
	setArchivedCurrentPage: (page: number) => void;
	fetchObjectives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFilteredObjectives: (
		query: IQueryFiltered,
		callback?: () => void
	) => void;
	fetchSubObjectives: (query: IGeneralQuery, objectiveId: string) => void;
	fetchChildrenByUser: (
		query: IGeneralQuery,
		parentId: string,
		userId: string
	) => void;
	fetchGroupedObjectives: (
		query: IQueryFavorite,
		objectiveId: string
	) => void;
	fetchArchivedObjectives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFavoriteObjectives: (query: IQueryFavoriteWithSearch) => void;
	editObjective: (query: IGeneralQuery, objective: Partial<Objective>) => any;
	editSubObjective: (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => any;
	columns: string[];
	rows: any[];
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setRows: (rows: any[]) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	addObjective: (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">
	) => Promise<Objective | undefined>;
	addSubObjective: (
		query: IGeneralQuery,
		newObjective: Omit<Objective, "_id" | "customId">,
		parentObjectiveId: string
	) => Promise<Objective>;
	addGroupedObjectives: (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentObjectiveId: string
	) => Promise<Objective[]>;
	deleteObjective: (query: IGeneralQuery, objectiveId: string) => void;
	deleteSubObjective: (
		query: IGeneralQuery,
		objectiveId: string,
		parentId: string
	) => Promise<Objective | undefined>;
	fetchAccountTypes: (query: IGeneralQuery) => void;
	fetchAllUsers: (query: IGeneralQuery) => void;
	fetchProducts: (query: IGeneralQuery) => void;
	fetchCustomers: (query: IGeneralQuery) => void;
	fetchCustomersPaginated: (query: IPaginatedQuery) => void;
	fetchObjectivesReportTable: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	runQueryForObjective: (query: IGeneralQuery, objectiveId: string) => void;
	resetValuesForObjective: (
		query: IGeneralQuery,
		objectiveId: string
	) => void;
	updateUserTargetAndPayout: (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		isGrouped?: boolean
	) => Promise<Objective[]>;
}

export const objectivesStoreZustand = create<Store>((set, get) => ({
	objectives: [],
	filteredObjectives: [],
	groupedObjectives: [],
	accounts: [],
	totalPages: 1,
	totalFilteredPages: 1,
	currentFilteredPage: 1,
	totalGroupedPages: 1,
	currentGroupedPage: 1,
	currentPage: 1,
	secondaryCurrentPage: 1,
	reportingGroupedPage: 1,
	secondaryTotalPages: 1,
	reportingGroupedTotalPages: 1,
	setSecondaryCurrentPage: (page: number) =>
		set({ secondaryCurrentPage: page }),
	setReportingGroupedPage: (page: number) =>
		set({ reportingGroupedPage: page }),
	loading: false,
	loadingFiltered: false,
	loadingGrouped: false,
	archivedTotalPages: 1,
	archivedCurrentPage: 1,
	archivedSecondaryCurrentPage: 1,
	archivedSecondaryTotalPages: 1,
	setArchivedSecondaryCurrentPage: (page: number) =>
		set({ archivedSecondaryCurrentPage: page }),
	loadingArchived: false,
	favoriteObjectives: [],
	totalFavPages: 1,
	currentFavPage: 1,
	archivedObjectives: [],
	accountTypes: [],
	users: [],
	products: null,
	customers: null,
	filteredCustomers: null,
	hoveredId: undefined,
	columns: objColumns,
	sortColumnName: undefined,
	sortDirection: "asc",
	rows: [],
	setLoading: (loading: boolean) => set({ loading }),
	setLoadingFiltered: (loadingFiltered: boolean) => set({ loadingFiltered }),
	setLoadingGrouped: (loadingGrouped: boolean) => set({ loadingGrouped }),
	setLoadingArchived: (loadingArchived: boolean) => set({ loadingArchived }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setCurrentFilteredPage: (page: number) =>
		set({ currentFilteredPage: page }),
	setCurrentGroupedPage: (page: number) => set({ currentGroupedPage: page }),
	setCurrentFavPage: (page: number) => set({ currentFavPage: page }),
	setArchivedCurrentPage: (page: number) =>
		set({ archivedCurrentPage: page }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setRows: (rows: any) => set({ rows }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	fetchObjectives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log("error fetching objectives");
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	fetchFilteredObjectives: async (
		query: IQueryFiltered,
		callback?: () => void
	) => {
		set({ loadingFiltered: true });
		const res = await api.fetch({
			path: "/api/v2/filteredObjectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				filteredObjectives: res.payload,
				totalFilteredPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingFiltered: false });
		} else {
			console.log("error fetching objectives");
			set({ filteredObjectives: [] });
			set({ loadingFiltered: false });
		}
	},
	fetchSubObjectives: async (query: IGeneralQuery, objectiveId: string) => {
		const res = await api.fetch({
			path: `/api/v2/objective/${objectiveId}/subObjectives`,
			method: "GET",
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === objectiveId) {
						return {
							...objective,
							subObjectives: res.payload,
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
		} else {
			console.log(
				"error fetching subObjectives or no subObjectives found",
				res
			);
		}
	},
	fetchChildrenByUser: async (
		query: IGeneralQuery,
		parentId: string,
		userId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/groupedObjectives/${parentId}/children/user/${userId}`,
			method: "GET",
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentId) {
						return {
							...objective,
							groupedObjectives: res.payload,
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
		} else {
			return;
		}
	},
	fetchGroupedObjectives: async (
		query: IQueryFavorite,
		objectiveId: string
	) => {
		set({ loadingGrouped: true });
		const res = await api.fetch({
			path: `/api/v2/groupedObjectives/${objectiveId}/children`,
			method: "GET",
			query,
		});
		if (res.ok === true && res.payload?.length > 0) {
			set({
				groupedObjectives: res.payload,
				totalGroupedPages: res.payload.count,
			});
			set({ loadingGrouped: false });
		} else {
			set({ groupedObjectives: [] });
			set({ loadingGrouped: false });
		}
	},
	fetchArchivedObjectives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loadingArchived: true });
		const res = await api.fetch({
			path: "/objectives/archived",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				archivedObjectives: res.payload,
				archivedTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingArchived: false });
		} else {
			console.log("error fetching objectives");
			set({ archivedObjectives: [] });
			set({ loadingArchived: false });
		}
	},
	fetchFavoriteObjectives: async (query: IQueryFavoriteWithSearch) => {
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				favoriteObjectives: res.payload,
				totalFavPages: res?.meta?.totalPages,
				currentFavPage: res?.meta?.page,
			});
		} else {
			console.log("error fetching objectives");
			set({ favoriteObjectives: [] });
		}
	},
	addObjective: async (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">
	) => {
		const res = await api.fetch({
			path: "/objective",
			method: "POST",
			query,
			body: newObjective,
		});

		if (res.ok) {
			set((state) => ({
				objectives: [...state.objectives, res.payload],
			}));
			if (query.parentId) {
				snack.success("The objective has been successfully copied");
			}
			return res.payload;
		} else {
			console.log("error adding objective");
		}
	},
	addSubObjective: async (
		query: IGeneralQuery,
		newObjective: Omit<Objective, "_id" | "customId">,
		parentObjectiveId: string
	) => {
		const res = await api.fetch({
			path: "/subObjective",
			method: "POST",
			query,
			body: { ...newObjective, parentObjectiveId },
		});

		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its subObjectives array
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentObjectiveId) {
						return {
							...objective,
							subObjectives: [
								...(objective.subObjectives || []),
								res.payload,
							],
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
			return res.payload as Objective;
		} else {
			console.log("error adding sub-objective", res);
			return {} as Objective;
		}
	},
	addGroupedObjectives: async (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentObjectiveId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/addGroupedObjectivesChildren/${parentObjectiveId}`,
			method: "POST",
			query,
			body: { childIds },
		});
		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its groupedObjectives array
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentObjectiveId) {
						return {
							...objective,
							groupedObjectives: [
								...(objective.groupedObjectives || []),
								...(childIds
									.map(({ id }) =>
										state.objectives.find(
											(obj) => obj._id === id
										)
									)
									.filter(Boolean) as Objective[]),
							],
						};
					}
					return objective;
				});
				return { ...state, objectives: updatedObjectives };
			});
			return childIds
				.map(({ id }) => get().objectives.find((obj) => obj._id === id))
				.filter(Boolean) as Objective[];
		} else {
			console.log("error linking grouped objectives", res);
			return [];
		}
	},
	deleteObjective: async (query: IGeneralQuery, objectiveId: string) => {
		const res = await api.fetch({
			path: `/objective/${objectiveId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			set((state) => ({
				objectives: [
					...state.objectives.filter(
						(obj) => obj._id !== objectiveId
					),
				],
			}));
		} else {
			console.log("error deleting objective");
		}
	},
	deleteSubObjective: async (
		query: IGeneralQuery,
		objectiveId: string,
		parentId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objectiveId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			let updatedParentObjective = undefined;
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentId) {
						updatedParentObjective = {
							...objective,
							subObjectives: objective.subObjectives?.filter(
								(sub) => sub._id !== objectiveId
							),
						};
						return updatedParentObjective;
					}
					return objective;
				});
				return { objectives: updatedObjectives };
			});

			if (updatedParentObjective) {
				snack.success("Delete completed 👍");
				return updatedParentObjective;
			} else {
				throw new Error("Parent objective not found");
			}
		} else {
			snack.error("Delete failed");
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === "asc" ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === "asc" ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>
	) => {
		snack.info("Updating...");

		const res = await api.fetch({
			path: `/api/v2/objective/${objective._id}`,
			method: "PATCH",
			query,
			body: objective,
		});

		if (res.ok) {
			snack.success("Update completed 👍");
			console.log("Update completed 👍", res.payload);
			set((state) => {
				// Find the index of the objective being updated
				const objectiveIndex = state.objectives.findIndex(
					(o) => o._id === objective._id
				);

				// Find the index of the archived objective being updated
				const archivedObjectiveIndex =
					state.archivedObjectives.findIndex(
						(o) => o._id === objective._id
					);

				// Preserve existing subObjectives or similar data
				const existingSubObjectives =
					objectiveIndex !== -1
						? state.objectives[objectiveIndex].subObjectives
						: null;

				// Assuming res.payload contains the updated objective without subObjectives
				const updatedObjective =
					objectiveIndex !== -1
						? {
								...state.objectives[objectiveIndex],
								...res.payload, // Merge updated objective data
								subObjectives: existingSubObjectives, // Keep existing subObjectives unchanged
						  }
						: res.payload;

				// Clone the existing objectives array for immutability
				const updatedObjectives =
					objectiveIndex !== -1
						? [...state.objectives]
						: state.objectives;
				if (objectiveIndex !== -1) {
					updatedObjectives[objectiveIndex] = updatedObjective;
				}

				const updatedArchivedObjectives =
					archivedObjectiveIndex !== -1
						? state.archivedObjectives.map((o) =>
								o._id === objective._id
									? { ...o, ...res.payload }
									: o
						  )
						: state.archivedObjectives;

				const newArchivedObjectives = res.payload.archived
					? [...updatedArchivedObjectives, res.payload]
					: updatedArchivedObjectives.filter(
							(o) => o._id !== objective._id
					  );

				const newObjectives = res.payload.archived
					? updatedObjectives.filter((o) => o._id !== objective._id)
					: updatedObjectives;
				// Return the new state
				return {
					objectives: newObjectives,
					archivedObjectives: newArchivedObjectives,
				};
			});

			return res.payload;
		} else {
			console.log("error updating objective", res);
		}
	},

	editSubObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info("Updating...");
		}

		console.log("editSubObjective objective", objective);
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objective._id}`,
			method: "PATCH",
			query,
			body: objective,
		});
		if (res.ok) {
			set((state) => {
				const updatedObjectives = state.objectives.map((obj) => {
					if (obj._id === parentId) {
						return {
							...obj,
							subObjectives: obj.subObjectives?.map((sub) =>
								sub._id === objective._id ? res.payload : sub
							),
						};
					} else {
						return obj;
					}
				});

				return { objectives: updatedObjectives };
			});
			if (!suppressSnack) {
				snack.success("Update completed 👍");
			}

			return res.payload;
		} else {
			console.log("error updating objective");
		}
	},
	fetchAccountTypes: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/distinctAccountTypes",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ accountTypes: res.payload });
		} else {
			console.log("error fetching objectives");
			set({ accountTypes: [] });
		}
	},
	fetchAllUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/users",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
		} else {
			console.log("error fetching users");
			set({ users: [] });
		}
	},
	fetchProducts: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/wholesaler",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ products: res.payload });
		} else {
			console.log("error fetching users");
			set({ products: null });
		}
	},
	fetchCustomers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/buyersByAccountType",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ customers: res.payload });
		} else {
			console.log("error fetching users");
			set({ customers: null });
		}
	},
	fetchCustomersPaginated: async (query: IPaginatedQuery) => {
		const res = await api.fetch({
			path: "/buyersByAccountTypePaginated",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ filteredCustomers: res });
		} else {
			console.log("error fetching users");
			set({ filteredCustomers: null });
		}
	},
	fetchObjectivesReportTable: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log("error fetching objectives");
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	runQueryForObjective: async (
		query: IQueryFavorite,
		objectiveId: string
	) => {
		const res = await api.fetch({
			path: `/runQueryForObjective/${objectiveId}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			console.log("error");
		}
	},
	resetValuesForObjective: async (
		query: IQueryFavorite,
		objectiveId: string
	) => {
		const res = await api.fetch({
			path: `/resetValuesForObjective/${objectiveId}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			console.log("error");
		}
	},
	updateUserTargetAndPayout: async (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		isGrouped?: boolean
	) => {
		const res = await api.fetch({
			path: "/api/v2/updateUserTargetAndPayout",
			method: "POST",
			query,
			body: {
				itemId,
				userId,
				payoutType,
				payout,
				target,
				isOpenEnded,
				threshold,
				cap,
				isObjective: true,
				isEdited: true,
				minQuantityValue,
				advancedBudget,
				advancedPayments,
			},
		});

		if (res.ok) {
			let fetchQuery = { ...query, page: get().secondaryCurrentPage };

			if (isGrouped) {
				fetchQuery = { ...fetchQuery, type: "grouped" };
			}

			const res = await api.fetch({
				path: "/api/v2/objectives",
				method: "GET",
				query: fetchQuery,
			});

			if (res.ok) {
				set({
					objectives: res.payload,
					totalPages: res?.meta?.totalPages,
				});

				const objectives = res.payload;
				for (const objective of objectives) {
					if (objective.type === "multi") {
						await get().fetchSubObjectives(query, objective._id);
					}
				}
				const updatedObjectives = get().objectives;
				let foundObjective = updatedObjectives.find(
					(o) => o._id === itemId
				);

				let parentObjective = null;
				if (!foundObjective) {
					for (const objective of updatedObjectives) {
						if (
							objective.type === "multi" &&
							objective.subObjectives
						) {
							foundObjective = objective.subObjectives.find(
								(sub) => sub._id === itemId
							);
							if (foundObjective) {
								parentObjective = objective;
								break;
							}
						}
					}
				}

				let filteredSubObjectives;
				if (parentObjective) {
					filteredSubObjectives =
						parentObjective?.subObjectives?.filter(
							(sub) => sub._id !== itemId
						);
				} else {
					filteredSubObjectives = updatedObjectives.filter(
						(o) => o._id !== itemId
					);
				}

				const users = foundObjective?.users?.map((u) => {
					if (u.user === userId) {
						return {
							...u,
							target: target,
							payout: payout,
							payoutType: payoutType,
							isOpenEnded: isOpenEnded,
							threshold: threshold,
							cap: cap,
							minQuantityValue: minQuantityValue,
							advancedBudget: advancedBudget,
							advancedPayments: advancedPayments,
						};
					}
					return u;
				});

				if (users === undefined || foundObjective === undefined) {
					set(() => ({
						objectives: updatedObjectives,
					}));

					return updatedObjectives;
				}

				const updatedObjective = {
					...foundObjective,
					users: users,
				};
				set(() => ({
					objectives: parentObjective
						? updatedObjectives.map((obj) =>
								obj._id === parentObjective._id
									? {
											...parentObjective,
											subObjectives: [
												...filteredSubObjectives,
												updatedObjective,
											],
									  }
									: obj
						  )
						: [...filteredSubObjectives, updatedObjective],
				}));

				const rows = get().rows;
				let updatedRows = rows;

				const updatedUser = updatedObjective.users.find(
					(u) => u.user === userId
				);
				if (updatedUser) {
					updatedRows = rows.map((row) => {
						if (row.userId === userId && row.itemId === itemId) {
							return {
								...row,
								payout: isOpenEnded
									? "∞"
									: updatedUser.totalPayout,
								target: isOpenEnded ? "∞" : target,
								payoutMTD: updatedUser.totalAchieved,
								minQuantityValue: minQuantityValue,
							};
						}
						return row;
					});

					set({ rows: updatedRows });
				}

				return parentObjective
					? updatedObjectives.map((obj) =>
							obj._id === parentObjective._id
								? {
										...parentObjective,
										subObjectives: [
											...filteredSubObjectives,
											updatedObjective,
										],
								  }
								: obj
					  )
					: [...filteredSubObjectives, updatedObjective];
			} else {
				console.log("error adding objective");
				return [];
			}
		} else {
			console.log("error adding objective");
			return [];
		}
	},
}));
