import { Button } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import * as XLSX from "xlsx";
import { mapForExport } from "./mapForExport";

interface ExportXLSButtonProps {
	columns: string[];
	rows: any[];
	calculateTotalPayout: (rows: any[]) => number;
	calculateTotalPayoutMTD: (rows: any[]) => number;
	calculateTotalTarget: (rows: any[]) => number;
}

export const ExportXLSButton = ({
	columns,
	rows,
	calculateTotalPayout,
	calculateTotalPayoutMTD,
	calculateTotalTarget,
}: ExportXLSButtonProps) => {
	const exportToXLSX = () => {
		const wb = XLSX.utils.book_new();

		const filteredColumns = columns.filter(column => column !== "Accounts" && column !== "Products");

		const totalPayout = isNaN(calculateTotalPayout(rows))
			? "∞"
			: calculateTotalPayout(rows);
		const totalPayoutMTD = isNaN(calculateTotalPayoutMTD(rows))
			? "∞"
			: calculateTotalPayoutMTD(rows);
		const totalTarget = isNaN(calculateTotalTarget(rows))
			? "∞"
			: calculateTotalTarget(rows);
		const totalRow = [
			"Total",
			...filteredColumns.slice(1).map((column) => {
				if (column === "Potential") return totalPayout;
				if (column === "Payout MTD") return totalPayoutMTD;
				if (column === "Target") return totalTarget;
				return "";
			}),
		];

		const transformedRows = rows.map((row) =>
			mapForExport(row, filteredColumns)
		);

		const dataWithTotal = [filteredColumns, ...transformedRows, totalRow];

		const ws = XLSX.utils.aoa_to_sheet(dataWithTotal);
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "exported_data.xlsx");
	};

	return (
		<Button
			size="small"
			variant="outlined"
			color="primary"
			startIcon={<PrintIcon />}
			onClick={exportToXLSX}
		>
			Print
		</Button>
	);
};
