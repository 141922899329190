import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  row: {
    height: 65,
    cursor: "pointer",
  },
  nameCell: {
    width: "100%",
  },
  accessCell: {
    minWidth: 348,
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
}));

export const smallCellSize = 63;

interface IProps {
  folderName: string;
  isArchive?: boolean;
  onClick: () => void;
}
export default function FilesFolderRow(props: IProps) {
  const classes = useStyles();

  return (
    <TableRow className={classes.row} hover onClick={props.onClick}>
      <TableCell align="right" width={smallCellSize}>
        <IconButton size="small" edge="end">
          <FolderIcon />
        </IconButton>
      </TableCell>
      <TableCell>{props.folderName}</TableCell>
      <TableCell></TableCell>
      <TableCell width={smallCellSize}></TableCell>
    </TableRow>
  );
}
