import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

import { commonObjIncStoreZustand } from '../../objectivesIncentives/store/commonObjIncStore';

const useManualProducts = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const updateProducts = (
        itemId:string,
		newProducts: { productId: string; checked: boolean }[],
        isObjective: boolean,
	) => {
        let typeKey: string;
        if (isObjective) {
            typeKey = 'objectiveId';
        } else {
            typeKey = 'incentiveId';
        }
		commonObjIncStoreZustand
			.getState()
			.updateProducts(
				isObjective,
				{ [typeKey]: itemId, wholesalerId },
				newProducts
			);
	};

	return {
		updateProducts,
	};
};

export default useManualProducts;
