import { useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { Incentive } from "../types/incentivesTypes";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useHandleIncentiveUpdate = () => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);

	const addIncentive = async (
		newIncentive: Omit<Incentive, "_id">,
		parentId?: string
	) => {
		const obj = await incentivesStoreZustand
			.getState()
			.addIncentive({ wholesalerId, parentId }, { ...newIncentive });

		return obj;
	};

	const addSubIncentive = (
		newIncentive: Omit<Incentive, "_id">,
		parentIncentiveId: string
	) => {
		const inc = incentivesStoreZustand
			.getState()
			.addSubIncentive(
				{ wholesalerId },
				{ ...newIncentive },
				parentIncentiveId
			);
		incentivesStoreZustand
			.getState()
			.fetchSubIncentives({ wholesalerId }, parentIncentiveId);
		return inc;
	};

	const editIncentive = (
		incentiveId: string,
		updatedFields: Partial<Incentive>
	) => {
		incentivesStoreZustand
			.getState()
			.editIncentive(query, { _id: incentiveId, ...updatedFields });
	};

	const deleteIncentive = (incentiveId: string) => {
		incentivesStoreZustand.getState().deleteIncentive(query, incentiveId);
	};

	const editSubIncentive = (
		incentiveId: string,
		parentId: string,
		updatedFields: Partial<Incentive>,
		suppressSnack: boolean
	) => {
		incentivesStoreZustand
			.getState()
			.editSubIncentive(
				query,
				{ _id: incentiveId, ...updatedFields },
				parentId,
				suppressSnack
			);
	};

	const deleteSubIncentive = (incentiveId: string, parentId: string) => {
		const updatetIncentive = incentivesStoreZustand
			.getState()
			.deleteSubIncentive(query, incentiveId, parentId);
		incentivesStoreZustand
			.getState()
			.fetchSubIncentives({ wholesalerId }, parentId);
		return updatetIncentive;
	};

	const runQueryForIncentive = (incentiveId: string) => {
		incentivesStoreZustand.getState().runQueryForIncentive(query, incentiveId);
	};

	const resetValuesForIncentive = (incentiveId: string) => {
		incentivesStoreZustand
			.getState()
			.resetValuesForIncentive(query, incentiveId);
	};

	return {
		addIncentive,
		addSubIncentive,
		editIncentive,
		deleteIncentive,
		editSubIncentive,
		deleteSubIncentive,
		runQueryForIncentive,
		resetValuesForIncentive,
	};
};

export default useHandleIncentiveUpdate;
