import React, { useState } from 'react';
import {
	Button,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	DialogTitle,
} from '@material-ui/core';
import { MeasureState, objectiveTypes } from '../../../types/objectiveTypes';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Item, initialItemState } from '../../../types/commonObjIncTypes';
import { useStyles } from './AddNewItemComponent.styles';
import { incentiveTypes } from '../../../types/incentivesTypes';

interface AddNewItemComponentProps {
	open: boolean;
	itemType: 'objective' | 'incentive';
	onAdd: (data: Omit<Item, '_id'>) => Promise<Item | undefined>;
	onClose: () => void;
}

export const AddNewItemComponent: React.FC<AddNewItemComponentProps> = ({
	open,
	itemType,
	onAdd,
	onClose,
}) => {
	const [objective, setObjective] =
		useState<Omit<Item, '_id'>>(initialItemState);
	const classes = useStyles();
	const handleChange = (
		event: React.ChangeEvent<{ name?: string; value: unknown }>
	) => {
		const name = event.target.name as keyof Item;
		setObjective((prev) => ({
			...prev,
			[name]: event.target.value,
		}));
	};
	const handleSubmit = () => {
		if (objective.type === 'quantity') {
			onAdd({
				...objective,
				measure: {
					label: 'Vol (CEs)',
					startDate: new Date().toISOString(),
					endDate: new Date().toISOString(),
					checked: true,
					trackingOption: "default",
					trackingPeriod: 30,
					excludeTrackingDays: [],
					trackingPeriodStart: new Date().toISOString(),
					trackingPeriodEnd: new Date().toISOString(),
					referenceTrackingPeriod: new Date().toISOString(),
				} as MeasureState,
			});
			if (itemType === 'objective') {
				setObjective({
					...initialItemState,
					measure: {
						label: 'Vol (CEs)',
						startDate: new Date().toISOString(),
						endDate: new Date().toISOString(),
						checked: false,
						symbol: '$',
						trackingOption: "default",
						trackingPeriod: 30,
						excludeTrackingDays: [],
						trackingPeriodStart: new Date().toISOString(),
						trackingPeriodEnd: new Date().toISOString(),
						referenceTrackingPeriod: new Date().toISOString(),
					} as MeasureState,
				});
			} else {
				setObjective({
					...initialItemState,
					measure: {
						label: 'Vol (CEs)',
						startDate: new Date().toISOString(),
						endDate: new Date().toISOString(),
						checked: false,
						symbol: '$',
						trackingOption: "default",
						trackingPeriod: 30,
						excludeTrackingDays: [],
						trackingPeriodStart: new Date().toISOString(),
						trackingPeriodEnd: new Date().toISOString(),
						referenceTrackingPeriod: new Date().toISOString(),
					} as MeasureState,
				});
			}
		} else {
			onAdd(objective);
			setObjective(initialItemState);
		}

		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialog}
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				New {itemType === 'objective' ? 'Objective' : 'Incentive'}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					name="name"
					placeholder="Max of characters" // Updated placeholder
					value={objective.name}
					onChange={handleChange}
				/>
				<FormControl fullWidth className={classes.formControl}>
					<InputLabel id="type-label">Type</InputLabel>
					<Select
						labelId="type-label"
						id="type"
						name="type"
						value={objective.type}
						onChange={handleChange}
						label="Type"
					>
						{itemType === "objective" ? (
							objectiveTypes.map((typeObj) => (
								<MenuItem key={typeObj.value} value={typeObj.value}>
									{typeObj.label}
								</MenuItem>
							))
						) : (
							incentiveTypes.map((typeInc) => (
								<MenuItem key={typeInc.value} value={typeInc.value}>
									{typeInc.label}
								</MenuItem>
							))
						)}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions className={`${classes.buttonContainer}`}>
				<Button
					onClick={handleSubmit}
					className={`${classes.button} ${classes.addButton}`}
				>
					Add
				</Button>
				<Button
					onClick={onClose}
					color="default"
					className={`${classes.button} ${classes.cancelButton}`}
				>
					Cancel
				</Button>
				<Button
					onClick={onClose}
					color="default"
					className={`${classes.button} ${classes.cancelButton} ${classes.addFromFavoritesButton}`}
				>
					Add from favorites
				</Button>
			</DialogActions>
		</Dialog>
	);
};
