import { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import { userStoreZustand } from './userStore';
import UserManagementReportTable from './UserManagementReportTable';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
}));

export default function UserManagementSummary() {
	const classes = useStyles();
	const { rows, columns } = userStoreZustand();
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	useEffect(() => {
		userStoreZustand.getState().fetchUsers({ wholesalerId });
	}, [wholesalerId]);

	return (
		<Paper className={classes.container}>
			<UserManagementReportTable columns={columns} rows={rows} />
		</Paper>
	);
}
