import { makeStyles } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles({
	customTable: {
		width: "100%",
	},
	customCell: {
		backgroundColor: "lightgrey",
		padding: "15px",
	},
	customRow: {
		padding: "5px 10px",
	},
	actionsButtons: {
		maxWidth: "30%",
		width: "40%",
		justifyContent: "flex-start",
		flexWrap: "wrap",
	},
	customRowName: {
		maxWidth: "40%",
		width: "40%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customRowPeriod: {
		maxWidth: "25%",
		width: "25%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginLeft: "50%",
	},
	customQuantityType: {
		maxWidth: "15%",
		width: "15%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	tableHead: {
		"& .MuiTableCell-head": {
			color: "white",
			backgroundColor: indigo[300],
		},
	},
	notFound: {
		marginTop: "2rem",
		marginLeft: "1rem",
	},
});
