import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	formWrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
	formControl: {
		display: 'flex',
		flexDirection: 'row',
		margin: '20px 0',
	},
	datePicker: {
		width: '55%',
		marginBottom: '20px',
	},
	periodFormControl: {
		display: 'flex',
		flexDirection: 'row',
		margin: '0px 20px',
	},
});
