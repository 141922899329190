import { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useAccountsItem from '../Item/useAccountsItem';
import { useStyles } from './AccountsProductsList.styles';

interface AccountsListProps {
    itemId:string;
    userId:string;
    isObjective:boolean;
}


export default function AccountsList({ itemId, userId, isObjective }:AccountsListProps) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const {
        accounts,
        secondaryLoading,
        currentPage,
        setCurrentPage,
        secondaryTotalPages,
        fetchAccounts,
    } = useAccountsItem(itemId, userId, isObjective);

    const handleClickOpen = () => {
        setOpen(true);
        fetchAccounts();  
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPage(1);
    };

    const handleChangePage = (_:React.ChangeEvent<unknown>, value:number) => {
        setCurrentPage(value);  
    };

    useEffect(() => {
        if (open) { 
            fetchAccounts();
        }
    }, [currentPage]);
    
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className={classes.center}
            >
                See All
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.center}>All Accounts</DialogTitle>
                <DialogContent>
                    {secondaryLoading ? (
                        <Typography className={classes.center}>Loading...</Typography>
                    ) : (
                        <>
                            {accounts.length === 0 ? (
                                <Typography className={classes.center}>No account selected that belongs to this User</Typography>
                            ) : (
                                <>
                                    <List className={classes.list}>
                                        {accounts.map((buyer) => (
                                            <ListItem key={buyer._id} className={classes.listItem}>
                                                <ListItemText primary={`Name: ${buyer.name}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Pagination
                                        className={classes.pagination}
                                        count={secondaryTotalPages}
                                        page={currentPage}
                                        onChange={handleChangePage}
                                    />
                                </>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions className={classes.center}>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

