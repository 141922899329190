import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ReactDiffViewer from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import useQuery, { IDiffQuery } from "../../hooks/useQuery";
import { getDiff } from "./diffSlice";
import ButtonGroupView from "../buttonGroup/ButtonGroupView";

const useStyles = makeStyles((theme) => ({
  container: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
  },
  secondContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  card: {
    width: "100%",
    maxWidth: 1200,
    marginBottom: theme.spacing(2),
  },
  emptyWrapper: {
    display: "flex",
    flex: 1,
    alignSelf: "center",
  },
  controlsWrapper: {
    paddingBottom: theme.spacing(2),
  },
  diffHeaderWrapper: {
    padding: theme.spacing(2),
  },
  diffHeader: {
    fontWeight: "bold",
  },
}));

interface IDiff {
  diffs: {
    preview: string;
    prod: string;
    customId?: string;
    label?: string;
  }[];
  groupLabel: string;
}

interface IDiffs {
  wholesaler: IDiff;
  salesRep: IDiff;
  teamLead: IDiff;
  manager: IDiff;
  hidden: IDiff;
}

export default function DiffView() {
  const classes = useStyles();
  const { query, setQuery } = useQuery<IDiffQuery>();
  const { wholesalerId } = query;
  const diffWholesalerId = useSelector(
    (state: RootState) =>
      state.diff.previewWholesaler?.customId ||
      state.diff.prodWholesaler?.customId
  );
  const isNotReady = useSelector(
    (state: RootState) => state.diff.status !== "ready"
  );
  const diffs = useSelector((state: RootState) => {
    const _diffs: IDiffs = {
      wholesaler: {
        diffs: [
          {
            preview: JSON.stringify(state.diff.previewWholesaler, null, 2),
            prod: JSON.stringify(state.diff.prodWholesaler, null, 2),
            customId:
              state.diff.previewWholesaler?.customId ||
              state.diff.prodWholesaler?.customId,
            label:
              state.diff.previewWholesaler?.title ||
              state.diff.prodWholesaler?.title,
          },
        ],
        groupLabel: `Wholesaler`,
      },
      salesRep: { groupLabel: "Sales Reps", diffs: [] },
      teamLead: { groupLabel: "Team Leads", diffs: [] },
      manager: { groupLabel: "Managers", diffs: [] },
      hidden: { groupLabel: "Hidden / View Only", diffs: [] },
    };
    for (const userId of state.diff.allUsersIds) {
      const previewUser = state.diff.previewUsersById[userId];
      const prodUser = state.diff.prodUsersById[userId];
      const previewUserString = JSON.stringify(previewUser, null, 2);
      const prodUserString = JSON.stringify(prodUser, null, 2);
      if (previewUserString === prodUserString) {
        continue;
      }
      if (previewUser?.hidden || previewUser?.viewOnly) {
        _diffs.hidden.diffs.push({
          preview: previewUserString,
          prod: prodUserString,
          customId: userId,
          label: previewUser?.name || prodUser?.name,
        });
      } else {
        const role = previewUser?.role || prodUser?.role;
        // @ts-ignore
        _diffs[role]?.diffs.push({
          preview: previewUserString,
          prod: prodUserString,
          customId: userId,
          label: previewUser?.name || prodUser?.name,
        });
      }
    }

    return _diffs;
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDiff(
        { wholesalerId },
        {
          silent: diffWholesalerId === wholesalerId,
        }
      )
    );
  }, [wholesalerId]);

  if (isNotReady || diffWholesalerId !== wholesalerId) {
    return <></>;
  }

  const diffsToDisplay =
    query.group === "manager"
      ? diffs.manager.diffs
      : query.group === "teamLead"
      ? diffs.teamLead.diffs
      : query.group === "salesRep"
      ? diffs.salesRep.diffs
      : query.group === "hidden"
      ? diffs.hidden.diffs
      : diffs.wholesaler.diffs;
  return (
    <Box className={classes.container}>
      <Box className={classes.secondContainer}>
        <Box className={classes.controlsWrapper}>
          <ButtonGroupView
            value={query.group || "wholesaler"}
            buttons={Object.keys(diffs).map((diffKey) => ({
              // @ts-ignore
              label: `${diffs[diffKey].groupLabel} (${diffs[diffKey].diffs.length})`,
              value: diffKey,
            }))}
            onClick={(val: string) => {
              setQuery({ group: val });
            }}
          />
        </Box>
        {diffsToDisplay.map((diff) => (
          <Card className={classes.card} key={diff.customId}>
            <Box className={classes.diffHeaderWrapper}>
              <Typography variant="h5" className={classes.diffHeader}>
                {diff.label} ({diff.customId})
              </Typography>
            </Box>
            <ReactDiffViewer
              disableWordDiff
              leftTitle={"Production"}
              rightTitle={"Preview"}
              oldValue={diff.prod}
              newValue={diff.preview}
              splitView={true}
            />
          </Card>
        ))}
      </Box>
    </Box>
  );
}
