import React from "react";
import lottie from "lottie-web";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  container: {
    maxHeight: "80vh",
    flex: 1,
    alignSelf: "center",
  },
}));

export default function NotFoundView() {
  const classes = useStyles();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("404")!, // the dom element that will contain the animation
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "404.json", // the path to the animation json
    });
  }, []);
  return <Box id="404" className={classes.container}></Box>;
}
