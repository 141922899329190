import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	TextField,
} from '@material-ui/core';
import { DividerFormControl } from '../../../common/DividerFormControl';
import { useStyles } from '../EditComponent.styles';
import { useFormContext } from 'react-hook-form';

interface TargetComponentProps {
	name?: string;
	target?: number;
	isOpenEnded?: boolean;
	onTargetChange: (value: number) => void;
	onIsOpenEndedChange: (checked: boolean) => void;
	itemType?: string;
}

export const TargetComponent = ({
	name = 'Target',
	target,
	isOpenEnded,
	onTargetChange,
	onIsOpenEndedChange,
	itemType,
}: TargetComponentProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onIsOpenEndedChange(event.target.checked);
	};

	const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(event.target.value);
		onTargetChange(newValue);
	};

	const helperText = errors.target && errors.target.message
		? errors.target.message
		: itemType === "imageValidation"
			? "Note: for Image Validation type, the target will be tied to the number of cases"
			: "";

	return (
		<DividerFormControl>
			<div className={classes.targetContainer}>
				<FormLabel className={classes.targetText}>{name}: </FormLabel>
				<div className={classes.target}>
					<TextField
						required
						error={!!errors.target}
						helperText={helperText}
						margin="dense"
						variant="outlined"
						label="Units accepted"
						type="number"
						fullWidth
						name="target"
						defaultValue={target}
						disabled={isOpenEnded}
						onChange={handleTargetChange}
						inputRef={register({
							validate: {
								positiveNumber: value => {
									if (isOpenEnded) {
										return true;
									}
									return parseInt(value) > 0 || "Target must be greater than 0";
								}
							}
						})}
					/>
				</div>

				<FormControlLabel
					control={
						<Checkbox checked={isOpenEnded} onChange={handleCheckboxChange} />
					}
					label="Open-ended"
				/>
			</div>
		</DividerFormControl>
	);
};
