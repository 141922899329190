import { FormProvider, useForm } from "react-hook-form";
import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	Item,
	MeasureState,
	MeasureSymbol,
} from "../../types/commonObjIncTypes";
import { useStyles } from "./EditComponent.styles";
import { useState } from "react";
import {
	getPayoutType,
	initializeMeasures,
	PayoutDetails,
	updatedUsers,
} from "./utils";
import { ObjectiveTypeEnum, ObjectiveUser } from "../../types/objectiveTypes";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Divider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../utils";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod";
import { PayoutComponent } from "./payoutComponents";
import { TargetComponent } from "./targetComponent";
import { DividerFormControl } from "../../common/DividerFormControl";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import { CustomUsersSelection } from "./accountsProductsUsersSelect/usersSelect/CustomUsersSelection";

interface EditGroupedItemComponentProps<T> {
	initialGroupedItem: T;
	open: boolean;
	itemType: "objective" | "incentive";
	onSave: (item: T) => void;
	onClose: () => void;
}

export const EditGroupedItemComponent = <T extends Item>({
	initialGroupedItem,
	itemType,
	open,
	onSave,
	onClose,
}: EditGroupedItemComponentProps<T>) => {
	const classes = useStyles();
	const methods = useForm();
	const [groupedItem, setGroupedItem] = useState<T>(initialGroupedItem);
	const [selectionType, setSelectionType] = useState("custom");
	const [selectionTypeProd, setSelectionTypeProd] = useState("custom");

	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>(
			groupedItem.productsFilters || {
				supplier: [],
				brand: [],
				packageType: [],
				premise: [],
				size: [],
				productType: [],
			}
		);
	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			groupedItem.accountsFilters || {
				accountType: [],
				premise: [],
			}
		);

	const [measures, setMeasures] = useState(
		initializeMeasures(groupedItem.measure)
	);

	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: groupedItem?.target || 0,
		payoutType: groupedItem?.payoutType || "perAction",
		allOrNothingAmount: groupedItem?.quantityPayout || 0,
		perActionAmount: groupedItem?.quantityPayout || 0,
		atRiskAmount: groupedItem?.quantityPayout || 0,
		threshold: groupedItem?.threshold || { units: "percentage", value: 0 },
		cap: groupedItem?.cap || { units: "percentage", value: 100 },
		isOpenEnded: groupedItem?.isOpenEnded || false,
		advancedPayments: groupedItem?.advancedPayments || [],
		advancedBudget: groupedItem?.advancedBudget || 0,
	});

	const [minQuantityValue, setMinQuantityValue] = useState(
		groupedItem.minQuantityValue || 0
	);

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setGroupedItem((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriod?: number,
		excludeTrackingDays?: string[],
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							checked,
							symbol,
							trackingOption,
							trackingPeriod,
							excludeTrackingDays,
							trackingPeriodStart,
							trackingPeriodEnd,
							referenceTrackingPeriod,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleChange = (name: string, value: string | number) => {
		setGroupedItem((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};
	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setGroupedItem((prevGroupedItem) => ({
			...prevGroupedItem,
			isOpenEnded: checked,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
		}));
	};

	const [valueInputType, setValueInputType] = useState(
		groupedItem.valueInputType || "gt"
	);
	const [valueInput, setValueInput] = useState(groupedItem.valueInput);

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setGroupedItem((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setGroupedItem((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	const [selectedUsers, setSelectedUsers] = useState<string[]>(
		groupedItem?.users ? groupedItem?.users?.map((user) => user.user) : []
	);

	const handleUserSelectionChange = (selectedUsers: string[]) => {
		setSelectedUsers(selectedUsers);

		setGroupedItem((prev) => ({
			...prev,
			users: updatedUsers(
				selectedUsers,
				groupedItem,
				payoutDetails.target,
				getPayoutType(payoutDetails) || 0,
				payoutDetails.payoutType,
				groupedItem.isOpenEnded,
				payoutDetails.cap,
				payoutDetails.threshold,
				minQuantityValue
			) as ObjectiveUser[],
		}));
	};

	const handleGroupedItemSubmit = () => {
		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = groupedItem?.users?.find(
				({ user }) => user === selectedUserId
			);

			if (existingUser) {
				return existingUser;
			}

			return {
				user: selectedUserId,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				target: payoutDetails.target,
				payout: getPayoutType(payoutDetails) || 0,
				payoutType: payoutDetails.payoutType,
				isOpenEnded: groupedItem.isOpenEnded,
				cap: payoutDetails.cap,
				threshold: payoutDetails.threshold,
				isEdited: false,
				minQuantityValue: minQuantityValue,
			};
		});

		let updatedItem = {
			...groupedItem,
			users: transformedUsers,
			measure: getMeasuresForSave(measures),
			payoutType: payoutDetails.payoutType,
			quantityPayout: getPayoutType(payoutDetails) || 0,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			payout: payoutDetails.target,
			target: payoutDetails.target,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			minQuantityValue: minQuantityValue,
		};

		onSave(updatedItem);
	};
	if (initialGroupedItem === undefined) return null;

	return (
		<FormProvider {...methods}>
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialogSubObj}
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				{`GROUPED-${itemType.toLocaleUpperCase()}: ${capitalizeFirstLetter(
					groupedItem.type
				)} ${capitalizeFirstLetter(itemType)}`}
			</DialogTitle>
			<DialogContent className={classes.dialogContentSubObj}>
				<NameDescriptionPeriodComponent
					item={groupedItem}
					handleChange={handleChange}
				/>
				<>
					<TargetComponent
						name={"Target"}
						target={payoutDetails?.target || 0}
						isOpenEnded={groupedItem.isOpenEnded}
						onTargetChange={handleTargetChange}
						onIsOpenEndedChange={handleIsOpenEndedChange}
						itemType={groupedItem.type}
					/>
				</>
				{groupedItem.type === "imageValidation" && (
					<MinimumQuantityComponent
						minQuantityValue={minQuantityValue}
						onMinQuantityValueChange={handleMinQuantityValueChange}
					/>
				)}
				<Divider className={classes.divider} />
				<div className={classes.flexColumn}>
					<PayoutComponent
						payoutDetails={payoutDetails}
						setPayoutDetails={setPayoutDetails}
					/>
				</div>

				{groupedItem.type === ObjectiveTypeEnum.Quantity && (
					<QuantityForm
						measures={measures}
						handleMeasureChange={handleMeasureChange}
					/>
				)}

				{groupedItem.type === ObjectiveTypeEnum.Validation && (
					<DividerFormControl>
						<div className={classes.validationObjContainer}>
							<div>
								<ValueInput
									valueInputType={groupedItem.valueInputType}
									valueInput={groupedItem.valueInput}
									handleChange={handleValueInputChange}
								/>
							</div>
						</div>
					</DividerFormControl>
				)}

				<>
					<Accordion>
						<AccordionSummary
							aria-expanded={true}
							expandIcon={<ExpandMore />}
							aria-controls="panel1-content"
							id="panel1-header"
							className={classes.accordeonFontSize}
						>
							Accounts:
						</AccordionSummary>
						<AccordionDetails className={classes.flexColumn}>
							<SelectionTabs
								selectionType={selectionType}
								setSelectionType={(type) => setSelectionType(type)}
							/>
							{selectionType === "custom" && (
								<CustomAccountsSelection
									selectedAccountsFilters={selectedAccountsFilters}
									setSelectedAccountsFilters={setSelectedAccountsFilters}
								/>
							)}
							{selectionType === "manual" && (
								<ManualAccountsSelection item={groupedItem} />
							)}
						</AccordionDetails>
					</Accordion>

					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1-content"
							id="panel1-header"
							className={classes.accordeonFontSize}
						>
							Products:
						</AccordionSummary>
						<AccordionDetails className={classes.flexColumn}>
							<SelectionTabs
								selectionType={selectionTypeProd}
								setSelectionType={(type) => setSelectionTypeProd(type)}
							/>
							{selectionTypeProd === "custom" && (
								<CustomProductsSelection
									selectedProductsFilters={selectedProductsFilters}
									setSelectedProductsFilters={setSelectedProductsFilters}
								/>
							)}
							{selectionTypeProd === "manual" && (
								<ManualProductsSelection item={groupedItem} />
							)}
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1-content"
							id="panel1-header"
							className={classes.accordeonFontSize}
						>
							Users:
						</AccordionSummary>
						<AccordionDetails className={classes.flexColumn}>
							<CustomUsersSelection
								initialSelectedUserIds={selectedUsers}
								onChange={handleUserSelectionChange}
							/>
						</AccordionDetails>
					</Accordion>
				</>
			</DialogContent>
			<div className={classes.buttonsWrapper}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
					<Button onClick={methods.handleSubmit(handleGroupedItemSubmit)} color="primary">
					Save
				</Button>
			</div>
		</Dialog>
		</FormProvider>
	);
};
