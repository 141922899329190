import { useCallback, useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery"
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";

const useProductsItem = (itemId: string, userId: string, isObjective: boolean) => {
    const {query} = useQuery<IGeneralQuery>();
    const queryWholesalerId = query.wholesalerId;

    const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [productsPageSize, setProductsPageSize] = useState(10);

    const {products, productsCurrentPage, setProductsCurrentPage} = commonObjIncStoreZustand();

    useEffect(() => {
        if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

    const fetchProducts = useCallback(() => {
        commonObjIncStoreZustand.getState().fetchProducts(
            {
                wholesalerId,
                page: productsCurrentPage,
                limit: productsPageSize,
            },
            itemId,
            userId,
            isObjective,
        );
    }, [wholesalerId, productsCurrentPage, productsPageSize, itemId, userId, isObjective])

    return {
        products,
        productsCurrentPage,
        setProductsCurrentPage,
        productsPageSize,
        setProductsPageSize,
        productsTotalPages: commonObjIncStoreZustand((state) => state.productsTotalPages),
		productsLoading: commonObjIncStoreZustand((state) => state.productsLoading),
		setProductsLoading: commonObjIncStoreZustand((state) => state.setProductsLoading),
		fetchProducts,
    }
}

export default useProductsItem;