import config from "../config";
const makeAvatarUrl = (
  wholesalerId?: string,
  customId?: string,
  hash?: string
) =>
  !wholesalerId || !customId
    ? undefined
    : `${config.bucketUrl}/images/${wholesalerId}/${customId}.jpg#${hash}`;
export default makeAvatarUrl;
