import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../app/store";
import { ISliceStatus } from "../../interfaces";
import { IAnalyticsQuery, IClient } from "./AnalyticsQuery";
import { IGeneralQuery } from "../../hooks/useQuery";

export type IPeriod = "thisMonth" | "thisWeek" | "lastMonth" | "lastWeek";
export const periods: IPeriod[] = [
  "thisWeek",
  "lastWeek",
  "thisMonth",
  "lastMonth",
];

interface ITrackers {
  perPage: {
    page: string;
    minutes: number;
    day: number;
  }[];
  perUser: {
    userId: string;
    minutes: number;
    day: number;
  }[];
}
interface IState {
  trackersByKey: {
    [trackerKey: string]: ITrackers;
  };
  status: ISliceStatus;
}

const initialState: IState = {
  trackersByKey: {},
  status: "idle",
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    trackerFetched: (
      state,
      action: PayloadAction<{ trackers: ITrackers; trackerKey: string }>
    ) => {
      state.status = "ready";
      state.trackersByKey[action.payload.trackerKey] = action.payload.trackers;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, trackerFetched } = analyticsSlice.actions;

interface IGetTrackerQuery extends IGeneralQuery {
  users?: string[];
  period: IPeriod;
  client: IClient;
}
export const getTrackers = (query: IAnalyticsQuery): AppThunk => async (
  dispatch,
  getState
) => {
  const { analytics } = getState();
  const requestQuery: IGetTrackerQuery = {
    users: query.users,
    period: query.period,
    client: query.client,
  };

  if (!analytics.trackersByKey[makeTrackerKey(query)]) {
    dispatch(statusChanged("loading"));
  }

  const res = await api.fetch({
    path: "/tracker",
    method: "GET",
    query: requestQuery,
  });
  if (res.ok) {
    dispatch(
      trackerFetched({
        trackers: res.payload,
        trackerKey: makeTrackerKey(query),
      })
    );
  }
};

export function makeTrackerKey(query: IAnalyticsQuery) {
  return `${query.users?.join()}_${query.period}_${query.client}`;
}

export default analyticsSlice.reducer;
