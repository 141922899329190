import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { setMonth, momentFormat } from "./filesSlice";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
const twelveMonths = Array(12)
  .fill(undefined)
  .map((_, index) => moment().subtract(index, "month").format(momentFormat));

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function MonthSelect() {
  const classes = useStyles();
  const months = useSelector((state: RootState) =>
    twelveMonths.map((month) => ({
      month,
      filesCount: state.files.namesByMonth[month]?.length || 0,
    }))
  );
  const selectedMonth = useSelector(
    (state: RootState) => state.files.selectedMonth
  );
  const dispatch = useDispatch();
  return (
    <Box className={classes.container}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-native-simple">Month</InputLabel>
        <Select
          native
          value={selectedMonth}
          onChange={(val) => {
            dispatch(setMonth(String(val.target.value)));
          }}
          label="Month"
          inputProps={{
            name: "month",
            id: "outlined-age-native-simple",
          }}
        >
          <option value="All">All</option>
          {months.map((item) => (
            <option key={item.month} value={item.month}>
              {item.month} ({item.filesCount} files)
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
