import React, { useEffect, useState } from "react";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { IBuyer } from "../../../../../../interfaces";
import usePaginatedCustomers from "../../../../hooks/usePaginatedCustomers";
import { Incentive } from "../../../../types/incentivesTypes";
import { Objective } from "../../../../types/objectiveTypes";
import snack from "../../../../../../utils/snack";
import { useStyles } from "./AccountsSelection.styles";

type AllAccountsComponentProps = {
    item: Incentive | Objective;
    searchTerm: string;
    accountType: string;
    premise: string;
    allSelected: boolean;
    clearSelection: boolean;
};

const AllAccountsComponent: React.FC<AllAccountsComponentProps> = ({
    item,
    searchTerm,
    accountType,
    premise,
    allSelected,
    clearSelection,
}) => {
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAccounts, setSelectedAccounts] = useState<
        { buyerId: string; checked: boolean }[]
    >([]);

    const { allBuyers, totalPages, updateAccounts, loading } =
        usePaginatedCustomers({
            page: currentPage,
            item: item,
            accountType:
                accountType !== "" && accountType !== "All"
                    ? accountType
                    : undefined,
            premise: premise !== "" ? premise : undefined,
            searchPhrase: searchTerm !== "" ? searchTerm : undefined,
        });

    useEffect(() => {
        const initialSelections = allBuyers;
        const selections = initialSelections.map((acc) => ({
            buyerId: acc.customId,
            checked: acc.isSelected || false,
        }));

        setSelectedAccounts(selections);
    }, [allBuyers]);

    useEffect(() => {
        if (allSelected) {
            setSelectedAccounts((prev) =>
                prev.map((a) => ({ ...a, checked: true }))
            );
        }
    }, [allSelected]);

    useEffect(() => {
        if (clearSelection) {
            setSelectedAccounts((prev) =>
                prev.map((a) => ({ ...a, checked: false }))
            );
        }
    }, [clearSelection]);

    const handleCheck = (account: IBuyer) => {
        setSelectedAccounts((prev) => {
            const updatedAccounts = prev.map((a) =>
                a.buyerId === account.customId
                    ? { ...a, checked: !a.checked }
                    : a
            );
            return updatedAccounts;
        });
    };

    const handleSave = (showSnack = false) => {
        const accountsToUpdate = selectedAccounts.map((account) => ({
            buyerId: account.buyerId,
            checked: account.checked,
        }));
        updateAccounts(accountsToUpdate);
        if (showSnack) {
            snack.success("Accounts updated 👍");
        }
    };

    const handlePageChange = (_event: any, page: number) => {
        handleSave();
        setCurrentPage(page);
    };

    return (
        <div className={classes.listContainer}>
            <div className={classes.content}>
                {loading ? (
                    <div className={classes.progress}>
                        <CircularProgress size={"1rem"} />
                    </div>
                ) : allBuyers.length === 0 ? (
                    <Typography className={classes.notFound} variant="body1">
                            No accounts found
                        </Typography>
                    ) : (
                            <Paper className={classes.table}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table aria-label="accounts table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Select</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Premise</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allBuyers.map((account) => (
                                                <TableRow key={account.customId}>
                                                    <TableCell>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={
                                                        selectedAccounts.find(
                                                            (a) =>
                                                                a.buyerId ===
                                                                account.customId
                                                        )?.checked || false
                                                    }
                                                    onChange={() =>
                                                        handleCheck(account)
                                                    }
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {account.name}
                                            </TableCell>
                                            <TableCell>
                                                {account.accountType}
                                            </TableCell>
                                            <TableCell>
                                                {account.premise}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className={classes.saveButtonContainer}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSave(true)}
                                        className={classes.button}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                    </Paper>
                )}
                <div className={classes.pagination}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default AllAccountsComponent;
