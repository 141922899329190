import { ButtonGroup, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import { getColumnsToShowGrouped, transformColumnName } from "../Item/utils";
import { useStyles } from "./GroupedManagementSummary.styles";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { objectivesStoreZustand } from "../store/objectivesStore";
import React, { useState } from "react";
import { mapGroupedRowToColumn } from "./mapGroupedRowToColumns";
import SearchField from "../utils/Search";
import { ExportXLSButton } from "../utils/ExportXLSXButton";
import { ExportCSVButton } from "../utils/ExportCSVButton";

interface GroupedReportTableProps {
    rows: any;
    itemType: string;
    loading: boolean;
    onEdit: (row: any) => void;
}

export const GroupedReportTable = ({ rows, itemType, loading, onEdit }: GroupedReportTableProps) => {
    const classes = useStyles();

    const [searchValue, setSearchValue] = useState<string>("");

    const handleSearchChange = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    const store =
        itemType === "objective"
            ? objectivesStoreZustand()
            : incentivesStoreZustand();

    const {
        sortColumnName,
        sortDirection,
        setDirection,
        setColumnName,
        setOrder,
    } = store;

    const columnsToShowGrouped = getColumnsToShowGrouped(itemType);

    const filteredRows = rows.filter((row) => {
        return (
            row.userName?.toLowerCase().includes(searchValue) ||
            row.userType?.toLowerCase().includes(searchValue) ||
            row.objective?.toLowerCase().includes(searchValue) ||
            (typeof row.groupedObjectives === "string" &&
                row.groupedObjectives.toLowerCase().includes(searchValue)) ||
            (row.startDate &&
                new Date(row.startDate)
                    .toLocaleDateString()
                    .toLowerCase()
                    .includes(searchValue)) ||
            (row.endDate &&
                new Date(row.endDate)
                    .toLocaleDateString()
                    .toLowerCase()
                    .includes(searchValue)) ||
            row.type?.toLowerCase().includes(searchValue) ||
            row.route?.toLowerCase().includes(searchValue)
        );
    });

    const calculateTotalTarget = (rows: any[]): number => {
        let totalTarget = 0;
        rows.forEach((row) => {
            totalTarget += parseFloat(row.target || 0);
        });
        return totalTarget;
    };

    const calculateTotalPayout = (rows: any[]): number => {
        let totalPayout = 0;
        rows.forEach((row) => {
            totalPayout += parseFloat(row.payout || 0);
        });
        return totalPayout;
    };

    const calculateTotalPayoutMTD = (rows: any[]): number => {
        let totalPayoutMTD = 0;
        rows.forEach((row) => {
            totalPayoutMTD += parseFloat(row.payoutMTD || 0);
        });
        return totalPayoutMTD;
    };


    return (
        <div className={classes.overflowHidden}>
            <ButtonGroup className={classes.buttonGroupContainer}>
                <SearchField
                    label="Search in report table"
                    value={searchValue}
                    onChange={handleSearchChange}
                />
                <ExportXLSButton
                    columns={columnsToShowGrouped}
                    rows={rows}
                    calculateTotalPayout={calculateTotalPayout}
                    calculateTotalPayoutMTD={calculateTotalPayoutMTD}
                    calculateTotalTarget={calculateTotalTarget}
                />
                <ExportCSVButton
                    columns={columnsToShowGrouped}
                    rows={rows}
                    calculateTotalPayout={calculateTotalPayout}
                    calculateTotalPayoutMTD={calculateTotalPayoutMTD}
                    calculateTotalTarget={calculateTotalTarget}
                />
            </ButtonGroup>
            {!loading && (
            <TableContainer className={classes.tableContainerHeight}>
                <Table stickyHeader size="medium">
                    <TableHead>
                        <TableRow>
                                {columnsToShowGrouped.map((column, index) => (
                                <TableCell className={`${classes.headerCell}`} key={index}>
                                    <TableSortLabel
                                        active={sortColumnName === column}
                                        direction={sortDirection}
                                        onClick={() => {
                                            const transformedColName = transformColumnName(column);
                                            setDirection(sortDirection === "asc" ? "desc" : "asc");
                                            setColumnName(column);
                                            setOrder(
                                                sortDirection === "asc" ? "desc" : "asc",
                                                transformedColName
                                            );
                                        }}
                                    >
                                        {column}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {filteredRows.length !== 0 &&
                                filteredRows.map((row, index) => {
                                    const orderedRow = mapGroupedRowToColumn(
                                    row,
                                        columnsToShowGrouped,
                                    classes,
                                        onEdit
                                );
                                return (
                                    <TableRow key={index} className={classes.clickableRow}>
                                        {orderedRow.map((cellContent, cellIndex) => (
                                            <React.Fragment key={cellIndex}>
                                                {cellContent}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                );
                            })}
                            {rows.length !== 0 && (
                                <TableRow className={classes.totalRow}>
                                    <TableCell>Total</TableCell>
                                    {columnsToShowGrouped.slice(1).map((column, index) => (
                                        <TableCell key={index}>
                                            {column === "Target"
                                                ? isNaN(calculateTotalTarget(filteredRows))
                                                    ? "∞"
                                                    : calculateTotalTarget(filteredRows)
                                                : ""}
                                            {column === "Payout MTD"
                                                ? isNaN(calculateTotalPayoutMTD(filteredRows))
                                                    ? "∞"
                                                    : calculateTotalPayoutMTD(filteredRows)
                                                : ""}
                                            {column === "Potential"
                                                ? isNaN(calculateTotalPayout(filteredRows))
                                                    ? "∞"
                                                    : calculateTotalPayout(filteredRows)
                                                : ""}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
                    {rows.length === 0 && (
                        <div className={classes.emptyBox}>
                            <Typography variant="subtitle1">No data</Typography>
                        </div>
                    )}
            </TableContainer>
            )}
            {loading && (
                <div className={classes.loadingFlex}>
                    <CircularProgress
                        size={"sm"}
                        className={classes.loading}
                    />
                </div>
            )}
        </div>
    )
}
