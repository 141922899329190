import React from 'react';
import {
	Box,
	Tab,
	Tabs,
} from '@mui/material';

const SelectionTabs = ({
	selectionType,
	setSelectionType,
}: {
	selectionType: string;
	setSelectionType: (selectionType: string) => void;
}) => {
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectionType(newValue);
	};

	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '2rem' }}>
			<Tabs
				value={selectionType}
				onChange={handleChange}
				aria-label="selection type tabs"
			>
				<Tab value="custom" label="Custom Selection" />
				<Tab value="manual" label="Manual Selection" />
			</Tabs>
		</Box>
	);
};

export default SelectionTabs;
