import { useEffect, useState } from "react";
import { Item } from "../../../types/commonObjIncTypes";
import { payoutBasedOnType } from "../utils";
import { FormLabel, TextField } from "@material-ui/core";
import { DividerFormControl } from "../../../common/DividerFormControl";
import { useStyles } from "../EditComponent.styles";

interface MultiPayoutComponentProps<T> {
    subItems?: T[];
    onPayoutChange: (payout: number) => void;
}

export const MultiPayoutComponent = <T extends Item>({ subItems, onPayoutChange }: MultiPayoutComponentProps<T>) => {
    const classes = useStyles();
    const [calculatedPayout, setCalculatedPayout] = useState<number>(0);

    useEffect(() => {
        let payout = 0;
        if (subItems && subItems.length > 0) {
            subItems.forEach((subItem) => {
                payout += payoutBasedOnType(subItem);
            });
        }
        if (payout !== calculatedPayout) {
            setCalculatedPayout(payout);
            onPayoutChange(payout);
        }
    }, [subItems]);


    return (
        <DividerFormControl>
            <FormLabel className={classes.spacedFormLabel}>Payout: </FormLabel>
            <TextField
                margin="dense"
                variant="outlined"
                type="text"
                value={calculatedPayout || 0}
                disabled
                className={classes.smallTextField}
            />
        </DividerFormControl>
    );
};