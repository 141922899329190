import React, { useState } from 'react';
import { FavoriteObjectivesComponent } from './FavoriteObjectivesComponent';
import { ButtonGroup, Button } from '@material-ui/core';
import { FavoriteIncentivesComponent } from './FavoriteIncentivesComponent';
import { useStyles } from './FavoritesComponent.styles';

export const FavoriteComponent: React.FC = () => {
	const classes = useStyles();
	const [activeTab, setActiveTab] = useState<'objective' | 'incentive'>(
		'objective'
	);

	return (
		<div className={classes.container}>
			<ButtonGroup color="primary" aria-label="outlined primary button group">
				<Button
					onClick={() => setActiveTab('objective')}
					variant={activeTab === 'objective' ? 'contained' : 'outlined'}
				>
					Objectives
				</Button>
				<Button
					onClick={() => setActiveTab('incentive')}
					variant={activeTab === 'incentive' ? 'contained' : 'outlined'}
				>
					Incentives
				</Button>
			</ButtonGroup>
			<div className={classes.tableWrapper}>
				{activeTab === 'objective' ? (
					<FavoriteObjectivesComponent />
				) : (
					<FavoriteIncentivesComponent />
				)}
			</div>
		</div>
	);
};
