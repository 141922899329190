import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { AppThunk } from '../../app/store';
import { ISliceStatus } from '../../interfaces';
import { IGeneralQuery } from '../../hooks/useQuery';
import snack from '../../utils/snack';

interface IEntityStatus {
	updatedAt: number;
	status:
		| 'Preparing'
		| 'Running GBQ'
		| 'GBQ error'
		| 'Transferring to MongoDB'
		| 'Transfer error'
		| 'Completed';
	message?: string;
	url?: string;
}

interface IWholesalerUpdate {
	wholesalerId: string;
	brands: IEntityStatus;
	buyers: IEntityStatus;
	products: IEntityStatus;
	suppliers: IEntityStatus;
	users: IEntityStatus;
	wholesaler: IEntityStatus;
}

interface IState {
	wholesalerUpdate: IWholesalerUpdate;
	status: ISliceStatus;
}

const initialState: IState = {
	wholesalerUpdate: {
		wholesalerId: 'ats',
		brands: { updatedAt: 0, status: 'Completed' },
		buyers: { updatedAt: 0, status: 'Completed' },
		products: { updatedAt: 0, status: 'Completed' },
		suppliers: { updatedAt: 0, status: 'Completed' },
		users: { updatedAt: 0, status: 'Completed' },
		wholesaler: { updatedAt: 0, status: 'Completed' },
	},
	status: 'idle',
};

export const wholesalerSlice = createSlice({
	name: 'wholesaler',
	initialState,
	reducers: {
		statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
			state.status = action.payload;
		},
		wholesalerUpdateFetched: (
			state,
			action: PayloadAction<IWholesalerUpdate>
		) => {
			state.status = 'ready';
			state.wholesalerUpdate = action.payload;
		},
	},
	extraReducers: {
		'session/logOut': () => initialState,
	},
});

export const { statusChanged, wholesalerUpdateFetched } =
	wholesalerSlice.actions;

export const getWholesalerUpdate =
	(isRefreshing?: boolean): AppThunk =>
	async (dispatch) => {
		!isRefreshing && dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/wholesalerUpdate',
			method: 'GET',
			silent: true,
		});
		if (res.ok) {
			dispatch(wholesalerUpdateFetched(res.payload));
		}
	};

export const postWholesalerUpdate =
	(query: IGeneralQuery): AppThunk =>
	async (dispatch) => {
		dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/wholesalerUpdate',
			method: 'POST',
			query,
		});
		if (res.ok) {
			dispatch(wholesalerUpdateFetched(res.payload));
		}
	};

export const postObjectivesIncentivesUpdate =
	(query: IGeneralQuery): AppThunk =>
	async (dispatch) => {
		dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/objectivesIncentivesUpdate',
			method: 'POST',
			query,
		});
		if (res.ok) {
			dispatch(wholesalerUpdateFetched(res.payload));
		}
	};

export const publish =
	(query: IGeneralQuery): AppThunk =>
	async (dispatch) => {
		snack.info('Publishing...');
		dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/publish',
			method: 'POST',
			query,
		});
		if (res.ok) {
			snack.success('Published 🚀');
		}
		dispatch(statusChanged('ready'));
	};

export const reset =
	(query: IGeneralQuery): AppThunk =>
	async (dispatch) => {
		snack.info('Resetting...');
		dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/reset',
			method: 'POST',
			query,
		});
		if (res.ok) {
			snack.success('Reset complete ⏮');
		}
		dispatch(statusChanged('ready'));
	};

interface IDeleteTransactionsBody {
	start: string;
	end: string;
}
export const deleteTransactions =
	(query: IGeneralQuery, body: IDeleteTransactionsBody): AppThunk =>
	async (dispatch) => {
		snack.info('Deleting transactions...');
		dispatch(statusChanged('loading'));
		const res = await api.fetch({
			path: '/transactions',
			method: 'DELETE',
			query,
			body,
		});
		if (res.ok) {
			snack.success('Deletion complete ✅');
		}
		dispatch(statusChanged('ready'));
	};

export default wholesalerSlice.reducer;
