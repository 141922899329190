import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import makeAvatarUrl from "../../utils/makeAvatarUrl";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import isEmail from "../../utils/isEmail";
import Divider from "@material-ui/core/Divider";
import { Box } from "@material-ui/core";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import { patchProfile, postUserAvatar } from "../session/sessionSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 375,
    width: 375,
    minWidth: 375,
    display: "flex",
    flexDirection: "column",
  },
  blockWrapper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    width: 200,
    height: 200,
    alignSelf: "center",
    fontSize: 100,
    cursor: "pointer",
  },
  card: {},
}));

export default function ProfileView() {
  const classes = useStyles();
  const session = useSelector((state: RootState) => state.session);
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { query } = useQuery<IGeneralQuery>();
  if (!session.customId) {
    return <></>;
  }

  return (
    <Paper className={classes.container}>
      <Box className={classes.blockWrapper}>
        <Avatar
          onClick={() =>
            dispatch(
              postUserAvatar({
                wholesalerId: query.wholesalerId,
                db: query.db,
              })
            )
          }
          className={classes.avatar}
          sizes="300px"
          src={makeAvatarUrl(
            session.wholesalerId,
            session.customId,
            session.avatarHash
          )}
        >
          {session.name
            ?.match(/\b(\w)/g)
            ?.join("")
            .substring(0, 2)
            .toUpperCase()}
        </Avatar>
      </Box>
      <Divider />
      <Box className={classes.blockWrapper}>
        <TextField
          size="small"
          defaultValue={session.name}
          error={errors.name}
          name="name"
          inputRef={register({
            validate: (value: string) =>
              /^[a-zA-Z0-9 ']{1,30}$/.test(value) ||
              "Should be 1 to 30 letters and spaces",
          })}
          label="Name"
          helperText={errors.name}
          fullWidth
          variant="outlined"
        />
        <SpacingBoxView />
        <TextField
          size="small"
          defaultValue={session.email}
          error={errors.email}
          name="email"
          inputRef={register({
            validate: (val: string) =>
              isEmail(val) || "It doesn't look like an email",
          })}
          label="Email"
          helperText={errors.email}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Divider />
      <Box className={classes.blockWrapper}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleSubmit((vals) => {
            dispatch(
              patchProfile(
                {
                  wholesalerId: query.wholesalerId,
                  db: query.db,
                },
                { email: vals.email, name: vals.name }
              )
            );
          })}
        >
          Save
        </Button>
      </Box>
    </Paper>
  );
}
