import {
	defaultAdvancedPayments,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";

export const columnKeyMap: Record<string, string> = {
	"User Name": "userName",
	Route: "route",
	"User Type": "userType",
	Objective: "objective",
	Incentive: "incentive",
	"Sub Objective": "subObjectives",
	"Sub Incentive": "subIncentives",
	"Grouped Objective": "groupedObjective",
	"Grouped Incentive": "groupedIncentive",
	Type: "type",
	"Start Date": "startDate",
	"End Date": "endDate",
	Status: "status",
	Quantity: "totalItems",
	"Grouped Quantity": "groupedQuantity",
	Target: "target",
	Potential: "payout",
	"Payout MTD": "payoutMTD",
	Supplier: "supplierContribution",
	Image: "images",
	Accounts: "accounts",
	Products: "products",
	"Input Value": "valueInput",
	"Input Value Type": "valueInputType",
	"Minimum Quantity": "minQuantityValue",
	Weight: "weight",
	"Individual Status": "individualStatus",
	"Grouped Status": "groupedStatus",
};

export const transformColumnName = (colName: string): string => {
	return columnKeyMap[colName] || colName;
};

export const formatDate = (dateString: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		timeZone: "UTC",
	};

	const date = new Date(dateString);
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const initialPayoutDetails: PayoutDetails = {
	target: 0,
	allOrNothingAmount: 0,
	perActionAmount: 0,
	atRiskAmount: 0,
	payoutType: "perAction",
	threshold: { units: "percentage", value: 0 },
	cap: { units: "percentage", value: 100 },
	isOpenEnded: false,
	advancedBudget: 0,
	advancedPayments: defaultAdvancedPayments,
};

export function formatTypeLabel(type: string): string {
	return type
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.replace(/^\w/, (c) => c.toUpperCase());
}

