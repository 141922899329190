import React from "react";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { postUser } from "../../warehouse/usersWarehouse";
import createUserPrompt from "../../utils/createUserPrompt";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(),
    },
  },
}));

export default function SalesRepsControls() {
  const { query, setQuery } = useQuery<ISalesRepsQuery>();
  const classes = useStyles();

  const dispatch = useDispatch();
  const addUser = () => {
    const creds = createUserPrompt();
    if (creds) {
      dispatch(
        postUser(
          { wholesalerId: query.wholesalerId, db: query.db },
          {
            role: "teamLead",
            ...creds,
          },
          () => {
            setQuery({
              selectedUser: creds.customId,
            });
          }
        )
      );
    }
  };

  return (
    <Box className={classes.container}>
      <Button variant="outlined" onClick={addUser}>
        Create Team Lead
      </Button>
    </Box>
  );
}
