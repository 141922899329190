import { useEffect, useState } from "react";
import useQuery, { IQueryFiltered } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useFilteredIncentives = ({ parentId }: { parentId: string }) => {
	const { query } = useQuery<IQueryFiltered>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(10);

	const loading = incentivesStoreZustand((state) => state.loadingFiltered);

	const currentPage = incentivesStoreZustand(
		(state) => state.currentFilteredPage
	);
	const setCurrentPage = incentivesStoreZustand(
		(state) => state.setCurrentFilteredPage
	);

	const totalPages = incentivesStoreZustand(
		(state) => state.totalFilteredPages
	);

	const filteredIncentives = incentivesStoreZustand(
		(state) => state.filteredIncentives
	);

	useEffect(() => {
		incentivesStoreZustand.getState().fetchFilteredIncentives({
			wholesalerId,
			page: currentPage,
			limit: pageSize,
			parentId,
		});
	}, [wholesalerId, currentPage, pageSize, parentId]);

	return {
		filteredIncentives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
	};
};

export default useFilteredIncentives;
