import React from "react";
import {
  ITableRows,
  ITableCell,
  ITableName,
  setSelectIndex,
  setHoverIndex,
  setOrder,
} from "./executiveReportSlice";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps {
  columns: string[];
  rows: ITableRows;
  tableName: ITableName;
  total: ITableCell[];
}

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  hoveredRow: {
    backgroundColor: theme.palette.action.hover,
  },
  emptyBox: {
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    display: "flex",
    flex: 1,
  },
  headerCell: {
    minWidth: 105,
  },
  firstHeaderCell: {
    minWidth: 105,
    zIndex: 3,
  },
}));

export default function ExecutiveReportTable(props: IProps) {
  const { columns, rows, tableName, total } = props;
  const status = useSelector(
    (state: RootState) => state.executiveReport.status
  );
  const sortDirection = useSelector(
    (state: RootState) => state.executiveReport[tableName].sortDirection
  );
  const sortColumnName = useSelector(
    (state: RootState) => state.executiveReport[tableName].sortColumnName
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                className={
                  index === 0 ? classes.firstHeaderCell : classes.headerCell
                }
                key={index}
              >
                <TableSortLabel
                  active={sortColumnName === column}
                  direction={sortDirection}
                  onClick={() =>
                    dispatch(
                      setOrder({
                        table: tableName,
                        sortColumnName: column,
                        sortDirection: sortDirection === "asc" ? "desc" : "asc",
                      })
                    )
                  }
                >
                  {column}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {status !== "empty" &&
            rows.map((row, index) => (
              <ExecutiveReportTableRow
                key={tableName + row[0].content}
                data={row}
                rowIndex={index}
                tableName={tableName}
              />
            ))}
          {!!total.length && (
            <ExecutiveReportTableRow
              data={total}
              rowIndex={0}
              tableName={tableName + "total"}
            />
          )}
        </TableBody>
      </Table>
      {status === "empty" && (
        <Box className={classes.emptyBox}>
          <Typography variant="subtitle1">No data</Typography>
        </Box>
      )}
    </TableContainer>
  );
}

interface IRowProps {
  data: ITableCell[];
  rowIndex: number;
  tableName: string;
}

function ExecutiveReportTableRow(props: IRowProps) {
  const classes = useStyles();
  const selected = useSelector(
    (state: RootState) =>
      state.executiveReport.selectId === props.data[0].content
  );
  const hovered = useSelector(
    (state: RootState) =>
      state.executiveReport.hoverId === props.data[0].content
  );
  const dispatch = useDispatch();
  return (
    <TableRow
      className={hovered ? classes.hoveredRow : undefined}
      hover
      onMouseEnter={() => dispatch(setHoverIndex(props.data[0].content))}
      selected={selected}
      onClick={() => dispatch(setSelectIndex(props.data[0].content))}
    >
      {props.data.map((cell, index) => {
        const cellComponent = (
          <TableCell
            variant={index === 0 ? "head" : "body"}
            key={props.tableName + String(props.rowIndex) + index}
            className={cell.color ? classes[cell.color] : undefined}
          >
            {cell.content}
          </TableCell>
        );
        return cell.tooltip ? (
          <Tooltip
            key={"tooltip" + props.tableName + String(props.rowIndex) + index}
            title={cell.tooltip}
          >
            {cellComponent}
          </Tooltip>
        ) : (
          cellComponent
        );
      })}
    </TableRow>
  );
}
