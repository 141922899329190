import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	container: {
		maxWidth: "20%",
		minWidth: "20%",
		margin: "10px 20px",
		maxHeight: "100%",
		boxSizing: "border-box",
		direction: "rtl",
		position: "relative",
	},
	activeArchivedButtons: {
		marginBottom: "1rem",
		maxWidth: "100%",
		minWidth: "50%",
		width: "100%",
		direction: "ltr",
	},
	widthButton: {
		width: "100%",
	},
	typesButton: {
		marginBottom: "1rem",
		width: "100%",
		direction: "ltr",
	},
	pagination: {
		marginTop: "1rem",
		marginBottom: "1rem",
		display: "flex",
		justifyContent: "center",
		direction: "ltr",
		position: "absolute",
		bottom: 0,
		width: "100%",
	},
	searchField: {
		marginBottom: "1rem",
		width: "100%",
		direction: "ltr",
	},
	circularProgress: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		marginTop: "20px",
		direction: "ltr",
	},
	itemsList: {
		overflowY: "auto",
		maxHeight: "70%",
		minHeight: "60%",
	},
	notFound: {
		direction: "ltr",
		textAlign: "center",
		marginTop: 20,
	},
});
