import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  inputWrapper: {
    minWidth: 140,
  },
  smallSelect: {
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: "unset",
  },
}));

interface IOption {
  key: string;
  label: string;
}
interface IMultiselectProps {
  label: string;
  options?: string[] | IOption[];
  selected?: string[];
  small?: boolean;
  onSelect: (value?: string[]) => void;
}

const anyLabel = "Nothing selected";
export function MultiSelect(props: IMultiselectProps) {
  const { options = [], selected = [anyLabel], onSelect, label, small } = props;
  const classes = useStyles();
  // @ts-ignore
  const menuItems: IOption[] = options.map((item) =>
    typeof item === "string" ? { key: item, label: item } : item
  );
  return (
    <FormControl
      fullWidth
      className={classes.inputWrapper}
      variant="outlined"
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={selected}
        classes={{
          root: small ? classes.smallSelect : undefined,
        }}
        renderValue={(val) => {
          const value = val as string[];
          return value[0] === anyLabel ? anyLabel : `${value.length} items`;
        }}
        onChange={(e) => {
          const value = (e.target.value as string[]) || [];
          onSelect(value);
          if (value[value.length - 1] === anyLabel) {
            onSelect();
          } else {
            onSelect(value.filter((val) => val !== anyLabel));
          }
        }}
        multiple
        defaultValue={anyLabel}
        label={label}
      >
        <MenuItem value={anyLabel}>{anyLabel}</MenuItem>
        {menuItems.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface ISelectProps {
  label: string;
  options?: string[] | IOption[];
  onSelect: (value?: string) => void;
  noDefault?: boolean;
  defaultValue?: string;
  isReseted?: boolean;
}

export function SingleSelect(props: ISelectProps) {
  const classes = useStyles();
  const { options = [], defaultValue, onSelect, label, noDefault } = props;
  // @ts-ignore
  const menuItems: IOption[] = options.map((item) =>
    typeof item === "string" ? { key: item, label: item } : item
  );
  const [value, setvalue] = useState<string>(defaultValue || anyLabel);

  useEffect(() => {
    if (props.isReseted) {
      setvalue(defaultValue || anyLabel);
    }
  }, [props.isReseted, defaultValue]);
  return (
    <FormControl
      className={classes.inputWrapper}
      variant="outlined"
      size="small"
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        defaultValue={anyLabel}
        onChange={(e) => {
          if (e.target.value === anyLabel) {
            setvalue(anyLabel);
            onSelect();
          } else {
            setvalue(e.target.value as string);
            onSelect(e.target.value as string);
          }
        }}
        label={label}
      >
        {!noDefault && <MenuItem value={anyLabel}>{anyLabel}</MenuItem>}
        {menuItems.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
