import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((_) => ({
	card: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "3px 10px",
		marginBottom: "10px",
		cursor: "pointer",
		maxWidth: "100%",
		minWidth: "65%",
	},
	mainBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		wordBreak: "break-word",
	},
	expandedContent: {
		padding: "10px",
		borderTop: "1px solid #ccc",
	},
	expandedText: {
		marginTop: "10px",
		marginBottom: "10px",
	},
	title: {
		fontWeight: "bold",
	},
	subItemBox: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "10px",
		marginTop: "10px",
		overflowX: "auto",
		whiteSpace: "nowrap",
	},
	subItemContainer: {
		maxHeight: "350px",
		overflowY: "auto",
	},
}));
