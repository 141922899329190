import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { useEffect } from "react";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useGroupedIncentive = ({ itemId }: { itemId: string }) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const loadingGrouped = incentivesStoreZustand(
		(state) => state.loadingGrouped
	);

	const currentGroupedPage = incentivesStoreZustand(
		(state) => state.currentGroupedPage
	);
	const setCurrentGroupedPage = incentivesStoreZustand(
		(state) => state.setCurrentGroupedPage
	);

	const totalGroupedPages = incentivesStoreZustand(
		(state) => state.totalGroupedPages
	);

	const addGroupedIncentives = (
		children: { id: string; weight: number }[],
		parentIncentiveId: string
	) => {
		const inc = incentivesStoreZustand
			.getState()
			.addGroupedIncentives({ wholesalerId }, children, parentIncentiveId);
		incentivesStoreZustand
			.getState()
			.fetchGroupedIncentives({ wholesalerId }, parentIncentiveId);

		return inc;
	};

	useEffect(() => {
		incentivesStoreZustand
			.getState()
			.fetchGroupedIncentives({ wholesalerId }, itemId);
	}, [itemId, wholesalerId]);

	const groupedIncentives = incentivesStoreZustand(
		(state) => state.groupedIncentives
	);

	return {
		addGroupedIncentives,
		currentGroupedPage,
		totalGroupedPages,
		setCurrentGroupedPage,
		groupedIncentives,
		groupedTotalPages: incentivesStoreZustand.getState().totalGroupedPages,
		loadingGrouped,
	};
};

export default useGroupedIncentive;
