import { create } from 'zustand';
import api from '../../utils/api';
import { ISortDirection, ITableRows } from './types/types';
import { IGeneralQuery } from '../../hooks/useQuery';
import {
	IPatchUsersBody,
	IPatchUsersQuery,
	IUserPatch,
} from '../../warehouse/usersWarehouse';
import snack from '../../utils/snack';
import { IUser } from '../../interfaces';
import { transformToSummaryRow, userColumns } from './utils';

interface Store {
	users: IUser[];
	fetchUsers: (query: IGeneralQuery) => void;
	editUser: (query: IPatchUsersQuery, body: IPatchUsersBody) => any;
	columns: string[];
	rows: ITableRows | [];
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
}

export const userStoreZustand = create<Store>((set) => ({
	users: [],
	hoveredId: undefined,
	columns: userColumns,
	sortColumnName: undefined,
	sortDirection: 'asc',
	rows: [],
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	fetchUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/users',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
			set({
				rows: res.payload.map((usr: IUser) => transformToSummaryRow(usr)),
			});
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const sortColumnIndex = state.columns.indexOf(colName);

			// Assuming 'table' is a key in your state, which isn't in the provided example
			// Also assuming sortColumnName and sortDirection are meant to be arguments dir and colName
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[sortColumnIndex].value;
				const bVal = b[sortColumnIndex].value;
				if (aVal > bVal) {
					return dir === 'asc' ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === 'asc' ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editUser: async (query: IPatchUsersQuery, body: IPatchUsersBody) => {
		snack.info('Updating...');
		const keys = Object.keys(body.patch) as Array<keyof IUserPatch>;
		keys.forEach((key) => {
			if (body.patch[key] === '') {
				delete body.patch[key];
			}
		});
		const res = await api.fetch({
			path: '/users',
			method: 'PATCH',
			query,
			body,
		});
		if (res.ok) {
			snack.success('Update completed 👍');
			return res.payload;
		} else {
		}
	},
}));
