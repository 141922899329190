import React from "react";
import lottie from "lottie-web";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  container: {
    maxHeight: "80vh",
    flex: 1,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
  },
}));

export default function NotFoundView() {
  const classes = useStyles();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("emptyPage")!,
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "emptyPage.json",
    });
  }, []);
  return (
    <Box className={classes.container}>
      <Box id="emptyPage" />
      <Typography variant="h4" className={classes.text}>
        There is no one here
      </Typography>
    </Box>
  );
}
