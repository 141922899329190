import React from "react";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SendIcon from "@material-ui/icons/Send";
import Email from "@material-ui/icons/Email";
import FormHelperText from "@material-ui/core/FormHelperText";
import { emailPattern } from "../../utils/isEmail";
import Button from "@material-ui/core/Button";
import { postRequestPasswordReset } from "../session/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(2),
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignSelf: "stretch",
    },
  })
);

interface IProps {
  onBack: () => void;
  onSubmit: () => void;
}

export default function ForgotPasswordView(props: IProps) {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const submit = handleSubmit(({ email }) => {
    dispatch(postRequestPasswordReset(email));
    props.onSubmit();
  });
  const isLoading = useSelector(
    (state: RootState) => state.session.status === "loading"
  );

  return (
    <Box flex={1} alignItems="flex-end" display="flex" flexDirection="column">
      <FormControl variant="outlined" fullWidth className={classes.textField}>
        <InputLabel htmlFor="emailInput">Email</InputLabel>
        <OutlinedInput
          label="Email"
          name="email"
          inputRef={register({
            required: { value: true, message: "Email is required" },
            pattern: {
              value: emailPattern,
              message: "This doesnt look like an email",
            },
          })}
          error={!!errors.email}
          id="emailInput"
          type="email"
          endAdornment={
            <InputAdornment position="end">
              <Email />
            </InputAdornment>
          }
        />
        <FormHelperText>
          {errors.email?.message || "email@example.com"}
        </FormHelperText>
      </FormControl>

      <Box className={classes.buttonsWrapper}>
        <Button onClick={props.onBack} variant="text">
          Back to Logging in
        </Button>

        <Button
          onClick={submit}
          variant="outlined"
          endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
