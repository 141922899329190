import { create } from "zustand";
import { IGeneralQuery } from "../../../hooks/useQuery";
import api from "../../../utils/api";
import snack from "../../../utils/snack";

export interface Podcast {
	_id: string;
	name: string;
	url: string;
}

interface Store {
	podcasts: Podcast[];
	loading: boolean;
	setLoading: (loading: boolean) => void;
	fetchPodcasts: (query: IGeneralQuery, callback?: () => void) => void;
	getPodcastById: (qyery: IGeneralQuery, id: string) => any;
	addPodcast: (
		query: IGeneralQuery,
		newPodcast: Omit<Podcast, "_id">
	) => Promise<Podcast | undefined>;
	editPodcast: (query: IGeneralQuery, podcast: Partial<Podcast>) => any;
	deletePodcast: (query: IGeneralQuery, podcastId: string) => void;
}

export const podcastsStoreZustand = create<Store>((set, get) => ({
	podcasts: [],
	loading: false,
	setLoading: (loading: boolean) => set({ loading }),
	fetchPodcasts: async (query: IGeneralQuery, callback?: () => void) => {
		const res = await api.fetch({
			path: "/api/v2/podcasts",
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({ podcasts: res.payload.podcasts });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log("error fetching podcasts");
			set({ podcasts: [] });
			set({ loading: false });
		}
	},
	getPodcastById: async (query: IGeneralQuery, id: string) => {
		const res = await api.fetch({
			path: `/api/v2/podcasts/${id}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			return res.payload;
		} else {
			console.log("error fetching podcast by ID");
			return null;
		}
	},
	addPodcast: async (
		query: IGeneralQuery,
		newPodcast: Omit<Podcast, "_id">
	) => {
		const res = await api.fetch({
			path: "/api/v2/podcasts",
			method: "POST",
			query,
			body: newPodcast,
		});

		if (res.ok) {
			set((state) => ({
				podcasts: [...state.podcasts, res.payload.podcast],
			}));
			return res.payload.podcast;
		} else {
			console.log("error adding podcast");
		}
	},
	editPodcast: async (query: IGeneralQuery, podcast: Partial<Podcast>) => {
		snack.info("Updating...");

		const res = await api.fetch({
			path: `/api/v2/podcasts/${podcast._id}`,
			method: "PATCH",
			query,
			body: podcast,
		});
		if (res.ok) {
			snack.success("Update completed 👍");
			console.log("Update completed 👍", res.payload.podcast);
			set((state) => {
				const podcastIndex = state.podcasts.findIndex(
					(o) => o._id === podcast._id
				);

				const updatedPodcast =
					podcastIndex !== -1
						? {
								...state.podcasts[podcastIndex],
								...res.payload.podcasts,
						  }
						: res.payload.podcasts;

				const updatedPodcasts =
					podcastIndex !== -1 ? [...state.podcasts] : state.podcasts;
				if (podcastIndex !== -1) {
					updatedPodcast[podcastIndex] = updatedPodcast;
				}

				const newPodcasts = res.payload.podcasts
					? updatedPodcasts.filter((o) => o._id !== podcast._id)
					: updatedPodcasts;

				return {
					podcasts: newPodcasts,
				};
			});

			return res.payload.podcast;
		} else {
			console.log("error updating podcast", res);
		}
	},
	deletePodcast: async (query: IGeneralQuery, podcastId: string) => {
		const res = await api.fetch({
			path: `/api/v2/podcasts/${podcastId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			set((state) => ({
				podcasts: [
					...state.podcasts.filter(
						(podcast) => podcast._id !== podcastId
					),
				],
			}));
		} else {
			console.log("error deleting podcast link");
		}
	},
}));
