import React from "react";
import lottie from "lottie-web";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  container: {
    height: "50vh",
  },
}));

export default function FilesEmpty() {
  const classes = useStyles();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("emptyBox")!, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "no-files.json", // the path to the animation json
    });
  }, []);
  return <Box id="emptyBox" className={classes.container}></Box>;
}
