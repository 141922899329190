import React from "react";
import SalesRepsControls, { sortingOptions } from "./SalesRepsControls";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../warehouse/usersWarehouse";
import { getWholesaler } from "../../warehouse/wholesalerWarehouse";
import { RootState } from "../../app/store";
import get from "lodash/get";
import SalesRepsSkeleton from "./SalesRepsSkeleton";
import UsersView from "./UsersView";

export default function SalesRepsView() {
  const { query } = useQuery<ISalesRepsQuery>();
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }, { silent: true }));
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const usersLoaded = useSelector(
    (state: RootState) => state.usersWarehouse.status === "ready"
  );
  const usersIds = useSelector((state: RootState) => {
    const sortBy = query.sortBy || sortingOptions[0].value;
    const sortDesc = sortBy.startsWith("-");
    const sortingProp = sortBy.replace("-", "");
    return Object.keys(state.usersWarehouse.usersById)
      .map((userId) => state.usersWarehouse.usersById[userId])
      .filter((user) => {
        if (user.role !== "salesRep") {
          return false;
        }
        let good = true;
        if (good && query.team) {
          good = !!user.teamLead.filter((tl) => tl.customId === query.team)
            .length;
        }
        if (good && query.searchPhrase) {
          good = user.name.toLowerCase().includes(query.searchPhrase);
        }
        return good;
      })
      .sort((a, b) => {
        const aVal = get(a, sortingProp);
        const bVal = get(b, sortingProp);
        if (aVal > bVal) {
          return sortDesc ? -1 : 1;
        }
        if (aVal < bVal) {
          return sortDesc ? 1 : -1;
        }
        return 0;
      })
      .map((user) => user.customId);
  });

  if (!usersLoaded) {
    return <SalesRepsSkeleton />;
  }

  return (
    <>
      <SalesRepsControls />
      <UsersView usersIds={usersIds} />
    </>
  );
}
