import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import api from "../../utils/api";
import { IRole } from "../../utils/roles";
import snack from "../../utils/snack";
import moment from "moment";
import { IGeneralQuery } from "../../hooks/useQuery";

type IFileStatus = "uploading" | "deleting" | "downloading";
interface IFile {
  name: string;
  access: IRole[];
  size: number;
  date?: string;
  month?: string;
  isNew?: boolean;
  status?: IFileStatus;
}

interface INamesByMonth {
  [month: string]: string[];
}

interface IFilesState {
  byId: {
    [fileName: string]: IFile;
  };
  namesByMonth: INamesByMonth;
  selectedMonth: string;
  status: "loading" | "idle" | "ready";
}

export const momentFormat = "MMM YYYY";
export const currentMonth = moment().format(momentFormat);

const initialState: IFilesState = {
  byId: {},
  namesByMonth: {},
  selectedMonth: currentMonth,
  status: "idle",
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    filesRefreshed: (state, action: PayloadAction<IFile[]>) => {
      state.status = "ready";
      state.byId = {};
      action.payload.forEach((file) => {
        state.byId[file.name] = file;
      });
      state.namesByMonth = sortByMonth(state);
    },
    filesAdded: (state, action: PayloadAction<IFile[]>) => {
      state.status = "ready";
      action.payload.forEach((file) => {
        state.byId[file.name] = file;
      });
      state.namesByMonth = sortByMonth(state);
    },
    fileUploaded: (state, action: PayloadAction<string>) => {
      delete state.byId[action.payload].isNew;
      delete state.byId[action.payload].status;
    },
    filePatched: (
      state,
      action: PayloadAction<{ key: string; updatedFile: IFile }>
    ) => {
      const { updatedFile, key } = action.payload;
      state.byId[updatedFile.name] = updatedFile;
      delete state.byId[updatedFile.name].status;
      if (key !== updatedFile.name) {
        delete state.byId[key];
      }
      state.namesByMonth = sortByMonth(state);
    },
    filesDeleted: (state, action: PayloadAction<string[]>) => {
      const fileIds = action.payload;
      fileIds.forEach((fileId) => {
        delete state.byId[fileId];
      });
      state.namesByMonth = sortByMonth(state);
    },
    setFileStatus: (
      state,
      action: PayloadAction<{ fileName: string; status?: IFileStatus }>
    ) => {
      state.byId[action.payload.fileName].status = action.payload.status;
    },
    setMonth: (state, action: PayloadAction<string>) => {
      state.selectedMonth = action.payload;
    },
    sortByMonth: (state) => {
      const filesNames = Object.keys(state.byId).filter((fileName) =>
        fileName.includes("archive/")
      );
      const namesByMonth: INamesByMonth = {};
      filesNames.forEach((fileName) => {
        const month = state.byId[fileName].month || currentMonth;
        if (!namesByMonth[month]) {
          namesByMonth[month] = [];
        }
        namesByMonth[month].push(fileName);
      });
      state.namesByMonth = namesByMonth;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  filesRefreshed,
  filesAdded,
  filePatched,
  filesDeleted,
  fileUploaded,
  setFileStatus,
  setMonth,
} = filesSlice.actions;

function sortByMonth(state: IFilesState) {
  const filesNames = Object.keys(state.byId).filter((fileName) =>
    fileName.includes("archive/")
  );
  const currentMonth = moment().format(momentFormat);
  const namesByMonth: INamesByMonth = {};
  filesNames.forEach((fileName) => {
    const month = state.byId[fileName].month || currentMonth;
    if (!namesByMonth[month]) {
      namesByMonth[month] = [];
    }
    namesByMonth[month].push(fileName);
  });
  return namesByMonth;
}

export const getFiles = (wholesalerId?: string): AppThunk => async (
  dispatch
) => {
  const res = await api.fetch({
    path: "/files",
    method: "GET",
    query: { wholesalerId },
  });
  if (res.ok) {
    dispatch(filesRefreshed(res.payload));
  }
};

interface IFilePatch {
  key: string;
  name?: string;
  access?: IRole[];
}
export const patchFile = (
  query: IGeneralQuery,
  patch: IFilePatch
): AppThunk => async (dispatch) => {
  snack.info("Saving...");
  const res = await api.fetch({
    path: "/files",
    method: "PATCH",
    query,
    body: { patches: [patch] },
  });
  if (res.ok) {
    dispatch(filePatched({ key: patch.key, updatedFile: res.payload[0] }));
    snack.success("Changes saved 😊");
  }
};

interface IUploadResponsePayload {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export const uploadFile = (
  query: IGeneralQuery,
  file: IFile
): AppThunk => async (dispatch) => {
  dispatch(setFileStatus({ fileName: file.name, status: "uploading" }));
  const res = await api.fetch({
    path: "/file",
    method: "POST",
    query,
    body: {
      file: {
        name: file.name,
        access: file.access,
        month: file.month,
      },
    },
  });
  const payload: IUploadResponsePayload = res.payload;

  const formdata = new FormData();
  Object.keys(payload.fields).forEach((fieldName) => {
    formdata.append(fieldName, payload.fields[fieldName]);
  });
  var fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.click();
  dispatch(setFileStatus({ fileName: file.name }));
  await new Promise((resolve) => {
    fileInput.onchange = resolve;
  });

  if (!fileInput.files?.[0]) {
    snack.error("You have to select a file to upload a file 🤔");
    return;
  }
  formdata.append("file", fileInput.files[0]);
  snack.info("Saving...");
  dispatch(setFileStatus({ fileName: file.name, status: "uploading" }));
  await fetch(payload.url, {
    method: "POST",
    body: formdata,
  })
    .then((response) => {
      return response.text();
    })
    .then(() => {
      snack.success("File uploaded! 🚀");
      dispatch(fileUploaded(file.name));
    })
    .catch((error) => {
      snack.error("File not uploaded, something went wrong 😱");
      console.error("failed to upload file", error);
      dispatch(setFileStatus({ fileName: file.name }));
    });
};

export const downloadFile = (
  query: IGeneralQuery,
  fileName: string
): AppThunk => async (dispatch) => {
  dispatch(setFileStatus({ fileName, status: "downloading" }));
  const res = await api.fetch({
    path: `/downloadLink/${fileName}`,
    method: "GET",
    query,
  });
  if (res.ok) {
    window.open(res.payload.signedUrl, "_blank");
  }
  dispatch(setFileStatus({ fileName }));
};

interface IDeletePayload {
  fileName: string;
  isNew?: boolean;
}
export const deleteFile = (
  query: IGeneralQuery,
  { fileName, isNew }: IDeletePayload
): AppThunk => async (dispatch) => {
  if (isNew) {
    dispatch(filesDeleted([fileName]));
  } else if (
    window.confirm(`🪣 You are deleting file "${fileName}". Continue?`)
  ) {
    dispatch(setFileStatus({ fileName, status: "deleting" }));
    const res = await api.fetch({
      path: `/files`,
      method: "DELETE",
      query,
      body: {
        fileNames: [fileName],
      },
    });
    if (res.ok) {
      dispatch(filesDeleted([fileName]));
    } else {
      dispatch(setFileStatus({ fileName }));
    }
  }
};

export default filesSlice.reducer;
