import React, { useState } from "react";
import useIncentives from "../../hooks/useIncentives"; // Your custom hook for incentives
import { Item } from "../../types/commonObjIncTypes";
import ItemComponent from "../../common/ItemComponent";
import { Incentive } from "../../types/incentivesTypes";
import useHandleIncentiveUpdate from "../../hooks/useHandleIncentiveUpdate";

export const IncentivesComponent: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState<string>("");

	const { incentives, setCurrentPage, currentPage, totalPages, loading } =
		useIncentives({ searchPhrase: searchTerm !== "" ? searchTerm : undefined });

	const {
		addIncentive,
		deleteIncentive,
		editIncentive,
		runQueryForIncentive,
		resetValuesForIncentive,
	} = useHandleIncentiveUpdate();

	const handleAddIncentive = async (newIncentiveData: Omit<Item, "_id">, parentId?: string) => {
		return await addIncentive(newIncentiveData as Omit<Incentive, "_id">, parentId);
	};

	const handleEditIncentive = (id: string, updatedIncentive: Partial<Item>) => {
		editIncentive(id, updatedIncentive as Partial<Incentive>);
	};

	const handleDeleteIncentive = (id: string) => {
		deleteIncentive(id);
	};

	const handleFavoriteIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		if (incentive) {
			editIncentive(id, {
				...incentive,
				favorite: !incentive.favorite,
			});
		}
	};

	const handleDuplicateIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);

		if (incentive) {
			const newName = `${incentive.name} copy`;
			const {
				_id: id,
				customId,
				users: originalUsers,
				...restOfObject
			} = incentive;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));

			handleAddIncentive({
				...restOfObject,
				name: newName,
				users: initializedUsers,
			}, id);
		}
	};

	const handleArchiveIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		if (incentive) {
			editIncentive(id, {
				...incentive,
				archived: !incentive.archived,
			});
		}
	};
	const showArchive: boolean = false;
	const handleAction = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		if (incentive) {
			runQueryForIncentive(incentive._id);
		}
	};

	const handleReset = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		if (incentive) {
			resetValuesForIncentive(incentive._id);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			items={incentives}
			loading={loading}
			itemType="incentive"
			onAdd={handleAddIncentive}
			onEdit={handleEditIncentive}
			onDelete={handleDeleteIncentive}
			onFavorite={handleFavoriteIncentive}
			onDuplicate={handleDuplicateIncentive}
			onArchive={handleArchiveIncentive}
			showArchive={showArchive}
			onAction={handleAction}
			onReset={handleReset}
			searchTerm={searchTerm}
			onSearchChange={handleSearchChange}
		/>
	);
};
