import isNumber from "lodash/isNumber";
export const usd = (num = 0, decimals = 0) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);

export const volume = (num = 0, decimals = 0) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);

export const gpCe = (num = 0) => (isNumber(num) ? num.toFixed(2) : "");

export const delta = (num = 0, decimals?: number) =>
  isNumber(num) ? `${num.toFixed(decimals === undefined ? 1 : decimals)}%` : "";
