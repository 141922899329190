import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	dialogContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '55vw',
		height: '80vh',
		overflow: 'auto',
	},
	listWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	objList: {
		width: '50%',
	},
	buttonContainer: {
		width: '50%',
	},
	button: {
		marginBottom: '1rem',
	},
	tableWrapper: {
		marginTop: '20px',
	},
}));
