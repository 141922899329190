import React from "react";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup, {
  ButtonGroupMultiselect,
} from "../buttonGroup/ButtonGroupView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { downloadCsv, ITableNames } from "./suppliersSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ReactToPrint from "react-to-print";
import isSafari from "../../utils/isSafari";
import debounce from "lodash/debounce";
import useQuery from "../../hooks/useQuery";
import {
  ISuppliersQuery,
  suppliersSchema,
  periods,
  columnGroups,
  IColumnGroup,
} from "./suppliersQuery";
import { getWholesaler } from "../../warehouse/wholesalerWarehouse";
import { MultiSelect } from "../select/Select";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: -theme.spacing(3),
      marginLeft: -theme.spacing(3),
      justifyContent: "flex-start",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
      overflowX: "auto",
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: -theme.spacing(),
      marginLeft: -theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
  buttonWrapper: {
    paddingLeft: theme.spacing(),
  },
  iconButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
  },
  searchInputWrapper: {
    minWidth: 100,
  },
  searchInput: {
    paddingTop: 5.5,
    paddingBottom: 5.5,
  },
  lastButton: {
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  printRef: React.MutableRefObject<null>;
  tableName: ITableNames;
}

export default function SuppliersControls(props: IProps) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    if (props.tableName === "suppliersTable") {
      return state.suppliersWarehouse.status !== "ready";
    } else {
      return state.brandsWarehouse.status !== "ready";
    }
  });
  const { query, setQuery } = useQuery<ISuppliersQuery>(suppliersSchema);
  const onSearch = debounce(
    (event) => setQuery({ searchPhrase: event.target.value }),
    500
  );

  const { wholesalerId, db } = query;

  React.useEffect(() => {
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);

  const tagsOptions = useSelector(
    (state: RootState) => state.wholesalerWarehouse.details?.productTags || []
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.buttonWrapper}>
        <IconButton size="small" className={classes.iconButton}>
          {isLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            <CheckIcon />
          )}
        </IconButton>
      </Box>
      <Box className={classes.buttonWrapper}>
        <ButtonGroup
          value={query.period || "MTD"}
          onClick={(val) => {
            setQuery({ period: val });
          }}
          buttons={periods.map((period) => ({
            label: period.toUpperCase(),
            value: period,
          }))}
        />
      </Box>
      <Box className={classes.buttonWrapper}>
        <ButtonGroupMultiselect
          values={query.hiddenColumns || (["revenue"] as IColumnGroup[])}
          onClick={(val) => {
            const hiddenColumns = query.hiddenColumns || [];
            if (hiddenColumns.includes(val)) {
              setQuery({
                hiddenColumns: hiddenColumns.filter((item) => item !== val),
              });
            } else {
              setQuery({
                hiddenColumns: hiddenColumns.concat([val]),
              });
            }
          }}
          revert
          buttons={columnGroups.map((columnGroup) => ({
            label: columnGroup.toUpperCase(),
            value: columnGroup,
          }))}
        />
      </Box>
      <Box className={classes.buttonWrapper}>
        <TextField
          defaultValue={query.searchPhrase}
          className={classes.searchInputWrapper}
          InputProps={{
            classes: {
              input: classes.searchInput,
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          label="Search"
          onChange={onSearch}
        />
      </Box>
      <Box className={classes.buttonWrapper}>
        <MultiSelect
          label="Tags"
          small
          options={tagsOptions}
          selected={query.tags}
          onSelect={(val) => setQuery({ tags: val })}
        />
      </Box>
      <Box className={classes.buttonWrapper}>
        <ReactToPrint
          removeAfterPrint
          onBeforePrint={() => {
            isSafari() &&
              alert(
                'We recommend changing printing orientation to "Landscape" OR paper size to "A3" to get a good-looking table printed.'
              );
          }}
          pageStyle={`
            @page {
              size: A4;
              margin: 2mm;
            }
            @media print {
              * {
                box-shadow: none !important;
                text-shadow: none !important;
                background: transparent !important;
                -webkit-print-color-adjust: exact;
                border: none !important;
              }
              #supplierTable {
                height: auto;
                max-height: none;
              }
              tbody {
                page-break-after: always !important;
              }
              body {
                -webkit-print-color-adjust: exact;
                zoom: 45%;
              }
              tbody tr:nth-child(odd) {
                background: #e6e6e6 !important;
              }
              .showOnPrint {
                display: block !important;
              }
              thead, th, tr, td {
                white-space: nowrap;
              }
              ${
                isSafari() &&
                `
                thead, th, tr, td {
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;
                }
                .MuiTableCell-sizeSmall {
                  padding: 2px 8px 2px 8px;
                }
              `
              }
            }
          `}
          trigger={() => (
            <Button
              size="small"
              fullWidth={false}
              color="primary"
              variant="outlined"
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          )}
          content={() => props.printRef.current}
        />
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.lastButton}
          size="small"
          fullWidth={false}
          color="primary"
          variant="outlined"
          startIcon={<GetAppIcon />}
          onClick={() => dispatch(downloadCsv(props.tableName, query.period))}
        >
          CSV
        </Button>
      </Box>
    </Box>
  );
}
