import envConfig from '../config';
import qs from 'qs';
import snack from './snack';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

interface IFetchOptions {
	path: string;
	method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
	body?: { [key: string]: any };
	query?: { [key: string]: any };
	headers?: { [key: string]: string };
	silent?: boolean;
}
export interface IFetchResponse {
	ok: boolean;
	serverIsOk: boolean;
	token?: string;
	payload?: any;
	statusCode: number;
	meta?: {
		total?: number;
		page?: number;
		limit?: number;
		totalPages?: number;
	};
}
const api = () => {
	return {
		config: {
			token: window.localStorage.getItem('token') || undefined,
			setToken: function (token?: string) {
				this.token = token;
				if (token) {
					window.localStorage.setItem('token', token);
				} else {
					window.localStorage.removeItem('token');
				}
			},
		},
		fetch: async function (options: IFetchOptions): Promise<IFetchResponse> {
			try {
				let url = `${envConfig.apiUrl}${options.path}`;
				let query = '';
				if (options.query) {
					const cleanQuery = pickBy(options.query, identity);
					query = `?${qs.stringify(cleanQuery)}`;
					url += query;
				}
				if (!['/tracker', '/wholesalerUpdate'].includes(options.path)) {
					console.debug(options.path + query);
				}
				const res = await fetch(url, {
					method: options.method,
					...(['PATCH', 'POST', 'DELETE'].includes(options.method) && {
						body: JSON.stringify(options.body) || '{}',
					}),
					headers: {
						'Content-Type': 'application/json',
						...(!!this.config.token
							? { Authorization: `Bearer ${this.config.token}` }
							: {}),
						...options.headers,
					},
				});
				const jsonRes = await res.json();
				if (!jsonRes.ok) {
					throw jsonRes;
				}
				return { ...jsonRes, serverIsOk: true, statusCode: res.status };
			} catch (e) {
				console.error(e);
				if (e.statusCode === 401 && this.config.token) {
					window.localStorage.clear();
					window.location.reload();
				}
				const serverIsOk = await this.isServerOk();
				if (!options.silent) {
					if (!serverIsOk) {
						snack.error('Server is unavailable 😱');
					} else {
						snack.error(e.message || e);
					}
				}
				return { ok: false, serverIsOk, statusCode: e.statusCode };
			}
		},
		isServerOk: async function () {
			try {
				const res = await fetch(`${envConfig.apiUrl}/readiness_check`);
				const jsonRes = (await res.json()) || {};
				return !!jsonRes.ok;
			} catch (e) {
				console.error(e);
				return false;
			}
		},
	};
};

export default api();
