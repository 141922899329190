import React from "react";

import Box from "@material-ui/core/Box";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { deleteTransactions } from "./wholesalerSlice";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import moment from "moment";
import Button from "@material-ui/core/Button";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  contentScroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    height: "100%",
  },
  wholesalerUpdateList: {
    minWidth: 375,
    width: 375,
  },
  avatar: {
    backgroundColor: "white",
  },
  container: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  datePickersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface IProps {
  isReady: boolean;
}

export default function WholesalerDataDeletionForm({ isReady }: IProps) {
  const classes = useStyles();
  const { query } = useQuery<IGeneralQuery>();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  return (
    <Box className={classes.container}>
      <Box className={classes.datePickersWrapper}>
        <TextField
          disabled={!isReady}
          fullWidth
          name="start"
          inputRef={register()}
          label="Start"
          type="date"
          defaultValue={moment().startOf("month").format("YYYY-MM-DD")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <SpacingBoxView />
        <TextField
          disabled={!isReady}
          fullWidth
          name="end"
          inputRef={register()}
          label="End"
          type="date"
          defaultValue={moment().endOf("month").format("YYYY-MM-DD")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <SpacingBoxView />
      <Button
        disabled={!isReady}
        onClick={handleSubmit((vals) => {
          dispatch(
            deleteTransactions(
              { wholesalerId: query.wholesalerId },
              { start: vals.start, end: vals.end }
            )
          );
          console.log(vals);
        })}
        variant="outlined"
        fullWidth
      >
        Delete Transactions
      </Button>
    </Box>
  );
}
