import React from "react";
import ExecutiveReportSummary from "./ExecutiveReportSummary";
import ExecutiveReportControls from "./ExecutiveReportControls";
import ExecutiveReportInfoBlock from "./ExecutiveReportInfoBlock";
import ExecutiveReportTime from "./ExecutiveReportTime";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { getUsers } from "./executiveReportSlice";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getWholesaler } from "../../warehouse/wholesalerWarehouse";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  container: {
    maxWidth: 1800,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function ExecutiveReportView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const group = useSelector((state: RootState) => state.executiveReport.group);
  const { query } = useQuery<IGeneralQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }));
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, group, wholesalerId, db]);
  const theme = useTheme();
  const xsOrSmaller = useMediaQuery(theme.breakpoints.down("xs"));
  const ref = React.useRef(null);
  const isLoading = useSelector(
    (state: RootState) => state.executiveReport.status !== "ready"
  );
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <ExecutiveReportControls printRef={ref} />
      <div
        className={classes.container}
        id="executiveReportPrintRoot"
        ref={ref}
      >
        <Box className={classes.summaryContainer}>
          <ExecutiveReportTime />
          <SpacingBoxView />
          <ExecutiveReportSummary />
        </Box>
        <SpacingBoxView />
        <Grid container spacing={xsOrSmaller ? 1 : 2}>
          <ExecutiveReportInfoBlock tableName="mtdGp" />
          <ExecutiveReportInfoBlock tableName="mtdVolume" />
          <ExecutiveReportInfoBlock tableName="ytdGp" />
          <ExecutiveReportInfoBlock tableName="ytdVolume" />
        </Grid>
      </div>
    </>
  );
}
