import React from "react";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "../buttonGroup/ButtonGroupView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  setGroup,
  groups,
  downloadCsv,
  targetOptions,
  setTarget,
} from "./executiveReportSlice";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ReactToPrint from "react-to-print";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  iconButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
  },
}));

interface IProps {
  printRef: React.MutableRefObject<null>;
}

export default function ExecutiveReportControls(props: IProps) {
  const classes = useStyles();
  const selectedGroup = useSelector(
    (state: RootState) => state.executiveReport.group
  );
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.executiveReport.status === "loading"
  );
  const selectedTarget = useSelector(
    (state: RootState) => state.executiveReport.target
  );

  return (
    <Box className={classes.container}>
      <Box>
        <IconButton size="small" className={classes.iconButton}>
          {isLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            <CheckIcon />
          )}
        </IconButton>
        <ButtonGroup
          value={selectedGroup}
          onClick={(val) => {
            dispatch(setGroup(val));
          }}
          buttons={groups.map((group) => ({
            label: group,
            value: group,
          }))}
        />
      </Box>
      <SpacingBoxView />
      <Box flexDirection="row" display="flex">
        <ButtonGroup
          value={selectedTarget}
          onClick={(val) => {
            dispatch(setTarget(val));
          }}
          buttons={targetOptions.map((item) => ({
            label: item,
            value: item,
          }))}
        />
        <SpacingBoxView />
        <ReactToPrint
          removeAfterPrint
          pageStyle={`
            @page {
              size: A4;
              margin: 5mm;
            }
            @media print {
              * {
                box-shadow: none !important;
                text-shadow: none !important;
                background: transparent !important;
                -webkit-print-color-adjust: exact;
                border: none !important;
              }
              body {
                -webkit-print-color-adjust: exact;
                zoom: 70%;
              }
              div[class*=" makeStyles-summaryContainer-"] {
                display: flex;
              }
              tbody tr:nth-child(odd) {
                background: #e6e6e6 !important;
              }
              .MuiGrid-grid-md-6 {
                flex-grow: 0 !important;
                max-width: 50% !important;
                flex-basis: 50% !important;
              }

              thead, th, tr, td {
                page-break-inside: avoid !important;
                white-space: nowrap;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
              }
              table tbody tr td:before,
              table tbody tr td:after {
                  content : "" ;
                  height : 6px ;
                  display : block ;
              }
              table{
                border-collapse:collapse;
              }
            }
          `}
          trigger={() => (
            <Button
              size="small"
              fullWidth={false}
              color="primary"
              variant="outlined"
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          )}
          content={() => props.printRef.current}
        />
        <SpacingBoxView />
        <Button
          size="small"
          fullWidth={false}
          color="primary"
          variant="outlined"
          startIcon={<GetAppIcon />}
          onClick={() => dispatch(downloadCsv())}
        >
          CSV
        </Button>
      </Box>
    </Box>
  );
}
