import { useEffect, useState } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery from "../../../hooks/useQuery";

const useFilteredObjectives = ({ parentId }: { parentId: string }) => {
	const { query } = useQuery<IQueryFiltered>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(10);

	const loading = objectivesStoreZustand((state) => state.loadingFiltered);

	const currentPage = objectivesStoreZustand(
		(state) => state.currentFilteredPage
	);
	const setCurrentPage = objectivesStoreZustand(
		(state) => state.setCurrentFilteredPage
	);

	const totalPages = objectivesStoreZustand(
		(state) => state.totalFilteredPages
	);

	const filteredObjectives = objectivesStoreZustand(
		(state) => state.filteredObjectives
	);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchFilteredObjectives({
			wholesalerId,
			page: currentPage,
			limit: pageSize,
			parentId,
		});
	}, [wholesalerId, currentPage, pageSize, parentId]);

	return {
		filteredObjectives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
	};
};

export default useFilteredObjectives;
