import * as colors from '@material-ui/core/colors';

const colorsArray = [
	colors.red,
	colors.purple,
	colors.indigo,
	colors.lightBlue,
	colors.teal,
	colors.lightGreen,
	colors.yellow,
	colors.orange,
	colors.brown,
	colors.blueGrey,
	colors.pink,
	colors.deepPurple,
	colors.blue,
	colors.cyan,
	colors.green,
	colors.lime,
	colors.amber,
	colors.deepOrange,
	'#F0C146',
];

export default colorsArray
	.concat(colorsArray)
	.concat(colorsArray)
	.concat(colorsArray);
