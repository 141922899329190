import React from "react";
import lottie from "lottie-web";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    flex: 1,
    alignSelf: "center",
    position: "relative",
  },
  text: {
    // position: 'absolute',
    // top: '70%',
    // right: 0,
    // left: 0,
    textAlign: "center",
  },
}));

export default function NotFoundView() {
  const classes = useStyles();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("emptyPage")!,
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "emptyPage.json", // the path to the animation json
    });
  }, []);
  return (
    <Box className={classes.container}>
      <div id="emptyPage" />
      <Typography variant="h4" className={classes.text}>
        No data
      </Typography>
    </Box>
  );
}
