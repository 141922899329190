import React from "react";
import Typography from "@material-ui/core/Typography";
import { usd, volume } from "../../utils/format";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { IRecommendationSell } from "../../interfaces";

type IData = Pick<
  IRecommendationSell,
  "gp" | "volume" | "gpCe" | "customersCount" | "sameTypeCustomersCount"
>;
interface IProps extends IData {}

export default function BuyerSell(props: IProps) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center">
            <Typography variant="body2">SAME TYPE</Typography>
            <Typography variant="caption">
              ({props.sameTypeCustomersCount} customer
              {props.sameTypeCustomersCount === 1 ? "" : "s"})
            </Typography>
          </TableCell>
          <TableCell align="center" colSpan={2}>
            <Typography variant="body2">ALL TYPES</Typography>
            <Typography variant="caption">
              ({props.customersCount} customer
              {props.customersCount === 1 ? "" : "s"})
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell variant="head">GP:</TableCell>
          <TableCell align="center">{usd(props.gp.total, 2)}</TableCell>
          <TableCell align="center">{usd(props.gp.average, 2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">CE:</TableCell>
          <TableCell align="center">{volume(props.volume.total, 2)}</TableCell>
          <TableCell align="center">
            {volume(props.volume.average, 2)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">GP/CE:</TableCell>
          <TableCell align="center">{usd(props.gpCe.total, 2)}</TableCell>
          <TableCell align="center">{usd(props.gpCe.average, 2)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
