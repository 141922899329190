import { useEffect } from 'react';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

const useAccountTypes = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const accountTypes = objectivesStoreZustand((state) => state.accountTypes);
	useEffect(() => {
		objectivesStoreZustand.getState().fetchAccountTypes({ wholesalerId });
	}, [wholesalerId]);

	return {
		accountTypes,
	};
};

export default useAccountTypes;
