import { makeStyles } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles((_) => ({
	tables: {
		width: "93%",
		display: "flex",
		flexDirection: "row",
		gap: "3rem",
	},
	tableContainer: {
		width: "100%",
		maxWidth: "350px",
		height: "300px",
		overflowY: "auto",
		border: "1px solid #ccc",
		borderRadius: "5px",
	},
	container: {
		width: "100%",
		maxWidth: "350px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	tableHead: {
		backgroundColor: indigo[300],
	},
	tableHeadCell: {
		padding: "0.5rem",
		color: "white",
	},
	tableCell: {
		padding: 0,
	},
	arrowContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
		justifyContent: "center",
	},
	arrow: {
		cursor: "pointer",
		transition: "color 0.3s ease, background-color 0.3s ease",
		borderRadius: "50%",
		"&:hover": {
			color: indigo[300],
		},
		"&:active": {
			backgroundColor: "rgba(0, 0, 0, 0.1)",
		},
	},
	progress: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
	},
	notFound: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
	},
	flex: {
		flex: 1,
	},
	weightField: {
		maxWidth: "60%",
	},
	dialog: {
		width: "100%",
		height: "100%",
	},
}));