import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import UserListView from "../usersList/UsersListView";

interface IProps {
  userId: string;
}
interface IUsersList {
  title: string;
  usersIds: string[];
}

const useStyles = makeStyles((theme) => ({
  teamLeadsHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export default function UserCollegues(props: IProps) {
  const classes = useStyles();
  const usersLists = useSelector((state: RootState) => {
    const lists: IUsersList[] = [];
    const user = state.usersWarehouse.usersById[props.userId];
    const allUsersIds = Object.keys(state.usersWarehouse.usersById);
    if (user.role === "salesRep") {
      if (user.teamLead?.length) {
        // Doing this, because users are sorted by rank in the UsersWarehouse
        const teamleadsIds = user.teamLead.map((item) => item.customId);
        lists.push({
          title: "Teamleads",
          usersIds: allUsersIds.filter((id) => teamleadsIds.includes(id)),
        });
      }
    }
    if (user.role === "teamLead") {
      if (user.teamLead?.length && !user.leadership) {
        const teamleadsIds = user.teamLead.map((item) => item.customId);
        lists.push({
          title: "Leadership",
          usersIds: allUsersIds.filter((id) => teamleadsIds.includes(id)),
        });
      }
      const salesReps: string[] = [];
      const teamLeads: string[] = [];
      Object.keys(state.usersWarehouse.usersById).forEach((aUserId) => {
        const aUser = state.usersWarehouse.usersById[aUserId];
        const aUserTeamleads = aUser.teamLead?.map((item) => item.customId);
        if (aUserTeamleads?.includes(props.userId)) {
          if (aUser.role === "teamLead") {
            teamLeads.push(aUserId);
          } else if (aUser.role === "salesRep") {
            salesReps.push(aUserId);
          }
        }
      });
      if (teamLeads.length) {
        lists.push({
          title: "Teamleads",
          usersIds: teamLeads,
        });
      }
      if (salesReps.length) {
        lists.push({
          title: "Sales reps",
          usersIds: salesReps,
        });
      }
    }
    return lists;
  });

  return (
    <>
      {usersLists.map((list) => (
        <Box key={props.userId + list.title}>
          <Typography variant="h5" className={classes.teamLeadsHeader}>
            {list.title}:
          </Typography>
          <UserListView usersIds={list.usersIds} onItemClick="compare" />
        </Box>
      ))}
    </>
  );
}
