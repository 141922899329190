import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { ISortDirection } from "../../interfaces";
import { usd, volume, gpCe, delta } from "../../utils/format";
import exportCsv from "../../utils/exportCsv";
import reduce from "lodash/reduce";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import api from "../../utils/api";
import { IPeriod, IColumnGroup, IColumnNames } from "./suppliersQuery";
import {
  fetched as suppliersWarehouseFetched,
  statusChanged as suppliersWarehouseStatusChanged,
} from "../../warehouse/suppliersWarehouse";
import {
  fetched as brandsWarehouseFetched,
  statusChanged as brandsWarehouseStatusChanged,
} from "../../warehouse/brandsWarehouse";
import { IGeneralQuery } from "../../hooks/useQuery";

interface ICell {
  content: string;
  value?: string;
  color?: "success" | "error";
}

interface ITableData {
  name?: string;
  customId?: string;
  tags?: string[];
  gp: {
    thisYear: number;
    lastYear: number;
    lastYearMTD: number;
    delta: number;
    deltaAbs: number;
    margin: number;

    thisYearYTD: number;
    lastYearYTD: number;
    deltaYTD: number;
    deltaAbsYTD: number;
    marginYTD: number;
  };
  volume: {
    thisYear: number;
    lastYear: number;
    lastYearMTD: number;
    delta: number;
    deltaAbs: number;

    thisYearYTD: number;
    lastYearYTD: number;
    deltaYTD: number;
    deltaAbsYTD: number;
  };
  gpCe: {
    thisYear: number;
    lastYear: number;
    lastYearMTD: number;
    delta: number;
    deltaAbs: number;

    thisYearYTD: number;
    lastYearYTD: number;
    deltaYTD: number;
    deltaAbsYTD: number;
  };
  revenue: {
    thisYear: number;
    lastYear: number;
    lastYearMTD: number;
    delta: number;
    deltaAbs: number;

    thisYearYTD: number;
    lastYearYTD: number;
    deltaYTD: number;
    deltaAbsYTD: number;
  };
}

interface ITableDict {
  [columnName: string]: (item: ITableData, priod: IPeriod) => ICell;
}

export type ITableNames = "brandsTable" | "suppliersTable";

const tableDict: ITableDict = {
  "CE TY": (item, period) => ({
    content:
      period === "MTD"
        ? volume(item.volume.thisYear)
        : volume(item.volume.thisYearYTD),
  }),
  "CE LY": (item, period) => ({
    content:
      period === "MTD"
        ? volume(item.volume.lastYearMTD)
        : volume(item.volume.lastYearYTD),
  }),
  "CE Δ": (item, period) => ({
    content:
      period === "MTD"
        ? item.volume.delta
          ? `${delta(item.volume.delta)} (${volume(item.volume.deltaAbs)})`
          : ""
        : item.volume.deltaYTD
        ? `${delta(item.volume.deltaYTD)} (${volume(item.volume.deltaAbsYTD)})`
        : "",
    color:
      (period === "MTD" ? item.volume.delta : item.volume.deltaYTD) >= 0
        ? "success"
        : "error",
  }),
  "GP TY": (item, period) => ({
    content:
      period === "MTD" ? usd(item.gp.thisYear) : usd(item.gp.thisYearYTD),
  }),
  "GP LY": (item, period) => ({
    content:
      period === "MTD" ? usd(item.gp.lastYearMTD) : usd(item.gp.lastYearYTD),
  }),
  "GP Δ": (item, period) => ({
    content:
      period === "MTD"
        ? item.gp.delta
          ? `${delta(item.gp.delta)} (${usd(item.gp.deltaAbs)})`
          : ""
        : item.gp.deltaYTD
        ? `${delta(item.gp.deltaYTD)} (${usd(item.gp.deltaAbsYTD)})`
        : "",
    color:
      (period === "MTD" ? item.gp.delta : item.gp.deltaYTD) >= 0
        ? "success"
        : "error",
  }),
  "GP Margin": (item, period) => ({
    content:
      period === "MTD" ? delta(item.gp.margin) : delta(item.gp.marginYTD),
  }),
  "GP/CE TY": (item, period) => ({
    content:
      period === "MTD" ? gpCe(item.gpCe.thisYear) : gpCe(item.gpCe.thisYearYTD),
  }),
  "GP/CE LY": (item, period) => ({
    content:
      period === "MTD"
        ? gpCe(item.gpCe.lastYearMTD)
        : gpCe(item.gpCe.lastYearYTD),
  }),
  "GP/CE Δ": (item, period) => ({
    content:
      period === "MTD"
        ? item.gpCe.delta
          ? `${delta(item.gpCe.delta)} (${gpCe(item.gpCe.deltaAbs)})`
          : ""
        : item.gpCe.deltaYTD
        ? `${delta(item.gpCe.deltaYTD)} (${gpCe(item.gpCe.deltaAbsYTD)})`
        : "",
    color:
      (period === "MTD" ? item.gpCe.delta : item.gpCe.deltaYTD) >= 0
        ? "success"
        : "error",
  }),
  "Revenue TY": (item, period) => ({
    content:
      period === "MTD"
        ? usd(item.revenue.thisYear)
        : usd(item.revenue.thisYearYTD),
  }),
  "Revenue LY": (item, period) => ({
    content:
      period === "MTD"
        ? usd(item.revenue.lastYearMTD)
        : usd(item.revenue.lastYearYTD),
  }),
  "Revenue Δ": (item, period) => ({
    content:
      period === "MTD"
        ? item.revenue.delta
          ? `${delta(item.revenue.delta)} (${usd(item.revenue.deltaAbs)})`
          : ""
        : item.revenue.deltaYTD
        ? `${delta(item.revenue.deltaYTD)} (${usd(item.revenue.deltaAbsYTD)})`
        : "",
    color: item.revenue.delta >= 0 ? "success" : "error",
  }),
  Tags: (item) => ({
    content: item.tags?.join(", ") || "",
  }),
};

const sortingMap = {
  "CE TY": { MTD: "volume.thisYear", YTD: "volume.thisYearYTD" },
  "CE LY": { MTD: "volume.lastYearMTD", YTD: "volume.lastYearYTD" },
  "CE Δ": { MTD: "volume.delta", YTD: "volume.deltaYTD" },
  "GP TY": { MTD: "gp.thisYear", YTD: "gp.thisYearYTD" },
  "GP LY": { MTD: "gp.lastYearMTD", YTD: "gp.lastYearYTD" },
  "GP Δ": { MTD: "gp.delta", YTD: "gp.deltaYTD" },
  "GP Margin": { MTD: "gp.margin", YTD: "gp.marginYTD" },
  "GP/CE TY": { MTD: "gpCe.thisYear", YTD: "gpCe.thisYearYTD" },
  "GP/CE LY": { MTD: "gpCe.lastYearMTD", YTD: "gpCe.lastYearYTD" },
  "GP/CE Δ": { MTD: "gpCe.delta", YTD: "gpCe.deltaYTD" },
  "Revenue TY": { MTD: "revenue.thisYear", YTD: "revenue.thisYearYTD" },
  "Revenue LY": { MTD: "revenue.lastYearMTD", YTD: "revenue.lastYearYTD" },
  "Revenue Δ": { MTD: "revenue.delta", YTD: "revenue.deltaYTD" },
};

const sumRows = (rows: ITableData[]) => {
  const clonedRows = cloneDeep(rows);
  const reduced = reduce(clonedRows, (sum, row) => {
    sum.volume.thisYear += row.volume.thisYear;
    sum.volume.thisYearYTD += row.volume.thisYearYTD;
    sum.volume.lastYearMTD += row.volume.lastYearMTD;
    sum.volume.lastYearYTD += row.volume.lastYearYTD;
    sum.gp.thisYear += row.gp.thisYear;
    sum.gp.thisYearYTD += row.gp.thisYearYTD;
    sum.gp.lastYearMTD += row.gp.lastYearMTD;
    sum.gp.lastYearYTD += row.gp.lastYearYTD;
    sum.gpCe.thisYear += row.gpCe.thisYear;
    sum.gpCe.thisYearYTD += row.gpCe.thisYearYTD;
    sum.gpCe.lastYear += row.gpCe.lastYear;
    sum.gpCe.lastYearMTD += row.gpCe.lastYearMTD;
    sum.gpCe.lastYearYTD += row.gpCe.lastYearYTD;
    sum.revenue.thisYear += row.revenue.thisYear;
    sum.revenue.thisYearYTD += row.revenue.thisYearYTD;
    sum.revenue.lastYearMTD += row.revenue.lastYearMTD;
    sum.revenue.lastYearYTD += row.revenue.lastYearYTD;
    return sum;
  });
  if (reduced) {
    reduced.volume.delta =
      reduced.volume.thisYear / (reduced.volume.lastYearMTD / 100) - 100;
    reduced.volume.deltaAbs =
      reduced.volume.thisYear - reduced.volume.lastYearMTD;
    reduced.volume.deltaYTD =
      reduced.volume.thisYearYTD / (reduced.volume.lastYearYTD / 100) - 100;
    reduced.volume.deltaAbsYTD =
      reduced.volume.thisYearYTD - reduced.volume.lastYearYTD;

    reduced.gp.margin = reduced.gp.thisYear / reduced.revenue.thisYear;
    reduced.gp.margin = reduced.gp.thisYearYTD / reduced.revenue.thisYearYTD;
    reduced.gp.delta =
      reduced.gp.thisYear / (reduced.gp.lastYearMTD / 100) - 100;
    reduced.gp.deltaYTD =
      reduced.gp.thisYearYTD / (reduced.gp.lastYearYTD / 100) - 100;
    reduced.gp.deltaAbs = reduced.gp.thisYear - reduced.gp.lastYearMTD;
    reduced.gp.deltaAbsYTD = reduced.gp.thisYearYTD - reduced.gp.lastYearYTD;

    reduced.gpCe.thisYear = reduced.gp.thisYear / reduced.volume.thisYear;
    reduced.gpCe.thisYearYTD =
      reduced.gpCe.thisYearYTD / reduced.volume.thisYearYTD;
    reduced.gpCe.lastYear = reduced.gp.lastYear / reduced.volume.lastYear;
    reduced.gpCe.lastYearMTD =
      reduced.gp.lastYearMTD / reduced.volume.lastYearMTD;
    reduced.gpCe.lastYearYTD =
      reduced.gp.lastYearYTD / reduced.volume.lastYearYTD;

    reduced.gpCe.delta =
      reduced.gpCe.thisYear / (reduced.gpCe.lastYearMTD / 100) - 100;
    reduced.gpCe.deltaAbs = reduced.gpCe.thisYear - reduced.gpCe.lastYearMTD;
    reduced.gpCe.deltaYTD =
      reduced.gpCe.thisYearYTD / (reduced.gpCe.lastYearYTD / 100) - 100;
    reduced.gpCe.deltaAbsYTD =
      reduced.gpCe.thisYearYTD - reduced.gpCe.lastYearYTD;

    reduced.revenue.delta =
      reduced.revenue.thisYear / (reduced.revenue.lastYearMTD / 100) - 100;
    reduced.revenue.deltaAbs =
      reduced.revenue.thisYear - reduced.revenue.lastYearMTD;
    reduced.revenue.deltaYTD =
      reduced.revenue.thisYearYTD / (reduced.revenue.lastYearYTD / 100) - 100;
    reduced.revenue.deltaAbsYTD =
      reduced.revenue.thisYearYTD - reduced.revenue.lastYearYTD;
  }
  return reduced;
};

interface ISuppliersState {
  suppliersTable: {
    sortDirection: ISortDirection;
    sortColumnIndex: number;
    headers: ICell[];
    rows: ICell[][];
    searchPhrase: string;
    totalsRow: ICell[];
  };
  brandsTable: {
    sortDirection: ISortDirection;
    sortColumnIndex: number;
    headers: ICell[];
    rows: ICell[][];
    searchPhrase: string;
    totalsRow: ICell[];
  };
}

const initialState: ISuppliersState = {
  suppliersTable: {
    sortDirection: "desc",
    sortColumnIndex: 1,
    headers: [{ content: "Supplier" }].concat(
      Object.keys(tableDict).map((item) => ({ content: item }))
    ),
    rows: [],
    totalsRow: [],
    searchPhrase: "",
  },
  brandsTable: {
    sortDirection: "desc",
    sortColumnIndex: 1,
    headers: [{ content: "Core Brands & Families" }].concat(
      Object.keys(tableDict).map((item) => ({ content: item }))
    ),
    rows: [],
    totalsRow: [],
    searchPhrase: "",
  },
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    suppliersFetched: (
      state,
      action: PayloadAction<{
        rows: ICell[][];
        totalsRow: ICell[];
        headers: ICell[];
      }>
    ) => {
      state.suppliersTable.rows = action.payload.rows;
      state.suppliersTable.totalsRow = action.payload.totalsRow;
      state.suppliersTable.headers = action.payload.headers;
    },
    brandsFetched: (
      state,
      action: PayloadAction<{
        rows: ICell[][];
        totalsRow: ICell[];
        headers: ICell[];
      }>
    ) => {
      state.brandsTable.rows = action.payload.rows;
      state.brandsTable.totalsRow = action.payload.totalsRow;
      state.brandsTable.headers = action.payload.headers;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { suppliersFetched, brandsFetched } = suppliersSlice.actions;

interface IFilterByNamePayload extends IGeneralQuery {
  tableName: ITableNames;
  searchPhrase?: string;
  period: IPeriod;
  hiddenColumns: IColumnGroup[];
  orderBy: IColumnNames;
  descending: boolean;
  tags: string[];
}

const groupToIndexMap = {
  ce: [0, 1, 2],
  gp: [3, 4, 5, 6],
  "gp/ce": [7, 8, 9],
  revenue: [10, 11, 12],
};

export const getSuppliersOrBrands = (
  props: IFilterByNamePayload
): AppThunk => async (dispatch, getState) => {
  const { wholesalerId, db } = props;
  let items: ITableData[];
  const { suppliersWarehouse, brandsWarehouse } = getState();
  if (props.tableName === "suppliersTable") {
    if (
      suppliersWarehouse.status === "ready" &&
      suppliersWarehouse.db === db &&
      suppliersWarehouse.wholesalerId === wholesalerId
    ) {
      const suppliersById = suppliersWarehouse.suppliersById;
      items = Object.keys(suppliersById).map(
        (supplierId) => suppliersById[supplierId]
      );
    } else {
      dispatch(suppliersWarehouseStatusChanged("loading"));
      const res = await api.fetch({
        path: "/suppliers",
        method: "GET",
        query: { wholesalerId, db },
      });
      dispatch(
        suppliersWarehouseFetched({ items: res.payload, wholesalerId, db })
      );
      items = res.payload;
    }
  } else {
    if (
      brandsWarehouse.status === "ready" &&
      brandsWarehouse.db === db &&
      brandsWarehouse.wholesalerId === wholesalerId
    ) {
      const brandsById = brandsWarehouse.brandsById;
      items = Object.keys(brandsById).map((brandId) => brandsById[brandId]);
    } else {
      dispatch(brandsWarehouseStatusChanged("loading"));
      const res = await api.fetch({
        path: "/brands",
        method: "GET",
        query: { wholesalerId: props.wholesalerId, db: props.db },
      });
      dispatch(
        brandsWarehouseFetched({ items: res.payload, wholesalerId, db })
      );
      items = res.payload;
    }
  }

  let filteredItems: ITableData[] = props.searchPhrase ? [] : items;
  if (props.searchPhrase) {
    const lowerSearchPhrase = props.searchPhrase.toLowerCase();
    for (let index = 0; index < items.length; index++) {
      if (items[index].name?.toLowerCase().includes(lowerSearchPhrase)) {
        filteredItems.push(items[index]);
      }
    }
  }
  if (props.tags?.length) {
    filteredItems = filteredItems.filter(
      (item) => props.tags.filter((tag) => item.tags?.includes(tag)).length
    );
  }

  const hiddenColumns = props.hiddenColumns
    .map((key) => groupToIndexMap[key])
    .flat();
  const visibleColumns = Object.keys(tableDict).filter(
    (_, i) => !hiddenColumns.includes(i)
  );

  const sortingProperty = sortingMap[props.orderBy][props.period];

  const sortedItems = orderBy(
    filteredItems,
    [sortingProperty],
    [props.descending ? "asc" : "desc"]
  );

  const headers: ICell[] = visibleColumns.map((item) => ({ content: item }));
  const rows: ICell[][] = sortedItems.map((item) => {
    const firstCell: ICell = {
      value: item.customId,
      content: item.name!,
    };
    return [firstCell].concat(
      visibleColumns.map((columnName) =>
        tableDict[columnName](item, props.period)
      )
    );
  });
  const totalsRow: ICell[] = (() => {
    const totals = sumRows(sortedItems);
    if (!totals) {
      return [];
    }
    const firstCell: ICell = {
      content: "Total",
    };
    return [firstCell].concat(
      visibleColumns.map((columnName) =>
        tableDict[columnName](totals, props.period)
      )
    );
  })();

  if (props.tableName === "suppliersTable") {
    dispatch(
      suppliersFetched({
        rows,
        totalsRow,
        headers: [{ content: "Supplier" }].concat(headers),
      })
    );
  } else {
    dispatch(
      brandsFetched({
        rows,
        totalsRow,
        headers: [{ content: "Core Brands & Families" }].concat(headers),
      })
    );
  }
};

export const downloadCsv = (
  tableName: ITableNames,
  period: string
): AppThunk => async (_, getState) => {
  const { suppliersTable, brandsTable } = getState().suppliers;

  const table = tableName === "suppliersTable" ? suppliersTable : brandsTable;
  const fileName =
    tableName === "suppliersTable" ? `Suppliers ${period}` : `Brands ${period}`;

  const headers = table.headers.map((cell) => cell.content);
  const rows = table.rows.map((row) => row.map((cell) => cell.content));
  const totals = table.totalsRow.map((cell) => cell.content);
  exportCsv(fileName, [headers, ...rows, totals]);
};

export default suppliersSlice.reducer;
