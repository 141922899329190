import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { useEffect, useState } from "react";
import { Objective } from "../../usersManagement/types/objectiveTypes";
import useUsers from "../../usersManagement/hooks/useUsers";
import useReportObjectives from "./useReportObjectives";
import useArchivedReportObjectives from "./useArchivedReportObjectives";
import { ItemsList } from "../Item/ItemsList";
import ItemsManagementReportTable, {
	IRow,
} from "../Item/ItemManagementReportTable";
import { Dialog } from "@material-ui/core";
import { EditUserTargetPayout } from "../utils/EditUserTargetPayout";
import useUserUpdate from "../Item/useUserUpdate";
import {
	getPayoutType,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { initialPayoutDetails } from "../utils/utils";

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		overflow: "hidden",
		maxHeight: "87vh",
	},
}));

export const ObjectivesManagementSummary = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filterType, setFilterType] = useState<string>("");

	const [activeSearch, setActiveSearch] = useState<{ term: string; type: string }>({
		term: "",
		type: "",
	});
	const [archivedSearch, setArchivedSearch] = useState<{ term: string; type: string }>({
		term: "",
		type: "",
	});

	const { rows, columns, setRows } = objectivesStoreZustand();

	const [innerLoading, setInnerLoading] = useState(false);
	const [showArchived, setShowArchived] = useState(false);

	const {
		objectives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		totalPages,
		loading,
	} = useReportObjectives({
		searchPhrase: activeSearch.term !== "" ? activeSearch.term : undefined,
		type: activeSearch.type !== "" ? activeSearch.type : undefined
	});

	const {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedTotalPages,
		loadingArchived,
	} = useArchivedReportObjectives({
		searchPhrase: archivedSearch.term !== "" ? archivedSearch.term : undefined,
		type: archivedSearch.type !== "" ? archivedSearch.type : undefined
	});

	const { users } = useUsers(); 
	const { updateUserDetails } = useUserUpdate({ isGrouped: false });

	const [selectedObjectives, setSelectedObjectives] = useState<Objective[]>([]);

	const [shouldResetFilters, setShouldResetFilters] = useState(false);

	const [editRow, setEditRow] = useState<IRow>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [payoutDetails, setPayoutDetails] =
		useState<PayoutDetails>(initialPayoutDetails);
	const [username, setUsername] = useState<string | undefined>("");
	const [minQuantityValue, setMinQuantityValue] = useState<number | undefined>(0)

	const resetFilters = () => {
		setShouldResetFilters(true);
	};

	const handleObjectiveSelect = (selectedObjectives: Objective[]) => {
		setInnerLoading(true);
		setSelectedObjectives(selectedObjectives);
		let currentRows: any[] = [];

		const processObjective = (objective: Objective, callback: any) => {
			const allObjectives = objective.subObjectives
				? [...objective.subObjectives]
				: [objective];

			allObjectives.forEach((currentObjective) => {
				if (currentObjective.users) {
					currentObjective.users.forEach((user) => {
						const currUser = users.find((u) => u.customId === user.user);
						currentRows.push({
							...user,
							userName: currUser?.name,
							route: currUser?.routeNumber.join(", "),
							userType: currUser?.role,
							objective: currentObjective.name,
							subObjectives:
								currentObjective === objective ? "-" : currentObjective.name,
							startDate: currentObjective.customPeriodStart,
							endDate: currentObjective.customPeriodEnd,
							type: currentObjective.type,
							quantity: user.totalItems,
							status: user.percentageAchieved || 0,
							target: user.isOpenEnded
								? "∞"
								: user.target === 0
								? currentObjective.isOpenEnded
									? "∞"
									: currentObjective.target
								: user.target,
							minQuantityValue: user.minQuantityValue,
							payout: user.isOpenEnded ? "∞" : user.totalPayout,
							payoutMTD: user.totalAchieved,
							image: user.images,
							products: currentObjective.products,
							//Validation
							valueInput: currentObjective.valueInput,
							valueInputType: currentObjective.valueInputType,
							itemId: currentObjective._id,
							userId: user.user,
							isObjective: true,
						});
					});
				}
			});
			if (callback) callback();
		};

		const processObjectivesChunk = (objectives: Objective[], index = 0) => {
			if (index >= objectives.length) {
				setRows(currentRows);
				setInnerLoading(false);
				return;
			}
			processObjective(objectives[index], () => {
				setTimeout(() => processObjectivesChunk(objectives, index + 1), 0);
			});
		};

		processObjectivesChunk(selectedObjectives);
	};

	const handleEditClick = (row: IRow) => {
		setEditRow(row);
		setUsername(row.userName);
		let selectedObjective = objectives.find((obj) => obj._id === row.itemId);

		if (!selectedObjective) {
			objectives.forEach((obj) => {
				if (obj.type === "multi") {
					const subObjective = obj.subObjectives?.find(
						(sub) => sub._id === row.itemId
					);
					if (subObjective) {
						selectedObjective = subObjective;
					}
				}
			});
		}
		if (selectedObjective && selectedObjective.users) {
			const selectedUser = selectedObjective.users.find(
				(user) => user.user === row.userId
			);
			if (selectedUser) {
				setPayoutDetails({
					target: selectedUser.target,
					payoutType: selectedUser.payoutType,
					threshold: selectedUser.threshold,
					cap: selectedUser.cap,
					isOpenEnded: selectedUser.isOpenEnded,
					allOrNothingAmount: selectedUser.payout,
					perActionAmount: selectedUser.payout,
					atRiskAmount: selectedUser.payout,
					advancedBudget: selectedUser.advancedBudget,
					advancedPayments: selectedUser.advancedPayments,
				});
				setMinQuantityValue(selectedUser.minQuantityValue)
			}
			setIsModalOpen(true);
		}
	};

	const handleSave = (updatedPayoutDetails: PayoutDetails, minQuantityValue: number) => {
		const row = rows.find(
			(row) => row.itemId === editRow?.itemId && row.userId === editRow?.userId
		);

		updateUserDetails(
			true,
			updatedPayoutDetails.target,
			getPayoutType(updatedPayoutDetails),
			updatedPayoutDetails.payoutType,
			row.itemId,
			row.userId,
			updatedPayoutDetails.isOpenEnded,
			updatedPayoutDetails.threshold,
			updatedPayoutDetails.cap,
			minQuantityValue,
			updatedPayoutDetails.advancedBudget,
			updatedPayoutDetails.advancedPayments,
		);
		setIsModalOpen(false);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = event.target.value;
		setSearchTerm(newSearchTerm);

		if (showArchived) {
			setArchivedSearch((prev) => ({ ...prev, term: newSearchTerm }));
		} else {
			setActiveSearch((prev) => ({ ...prev, term: newSearchTerm }));
		}
	};

	const handleFilterTypeChange = (type: string) => {
		const normalizedType = type === "imagevalidation" ? "imageValidation" : type;
		setFilterType(normalizedType);

		if (showArchived) {
			setArchivedSearch((prev) => ({ ...prev, type: normalizedType }));
		} else {
			setActiveSearch((prev) => ({ ...prev, type: normalizedType }));
		}
		setSelectedObjectives([]);
		resetFilters();
	};

	useEffect(() => {
		if (selectedObjectives.length === 0) {
			setRows([]);
		}
	}, [selectedObjectives, setRows]);

	useEffect(() => {
		if (showArchived) {
			setArchivedSearch({ term: searchTerm, type: filterType });
		} else {
			setActiveSearch({ term: searchTerm, type: filterType });
		}
	}, [showArchived, searchTerm, filterType]);

	return (
		<Paper className={classes.container}>
			<ItemsList<Objective>
				itemType="Objectives"
				loading={showArchived ? loadingArchived : loading}
				items={showArchived ? archivedObjectives : objectives}
				currentPage={
					showArchived ? archivedSecondaryCurrentPage : secondaryCurrentPage
				}
				setCurrentPage={
					showArchived
						? setArchivedSecondaryCurrentPage
						: setSecondaryCurrentPage
				}
				totalPages={showArchived ? archivedTotalPages : totalPages}
				onItemSelect={handleObjectiveSelect}
				selectedItems={selectedObjectives}
				onFilterChange={resetFilters}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
				searchTerm={searchTerm}
				onSearchChange={handleSearchChange}
				filterType={filterType}
				onFilterTypeChange={handleFilterTypeChange}
			/>
			<ItemsManagementReportTable
				itemType="objective"
				loading={innerLoading}
				columns={columns}
				rows={rows}
				selectedItem={selectedObjectives}
				shouldResetFilters={shouldResetFilters}
				setShouldResetFilters={setShouldResetFilters}
				onEdit={handleEditClick}
			/>
			<Dialog
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				fullWidth
				disableEnforceFocus
				PaperProps={{
					style: {
						position: "absolute",
						right: 0,
						minWidth: "50vw",
						maxHeight: "100vh",
						height: "100%",
						margin: 0,
						borderRadius: 0,
					},
				}}
			>
				<EditUserTargetPayout
					username={username}
					initialPayoutDetails={payoutDetails}
					onSave={handleSave}
					editRow={editRow}
					initialMinQuantityValue={minQuantityValue}
				/>
			</Dialog>
		</Paper>
	);
};
