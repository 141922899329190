import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import useLocalStorage from "../../hooks/useLocalStorage";
import Routes from "./Routes";
import DrawerContent from "./DrawerContent";
import Container from "@material-ui/core/Container";
import AppBar from "./AppBar";
import Box from "@material-ui/core/Box";
import useTracker from "../../hooks/useTracker";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import SelectWholesalersView from "./SelectWholesalerView";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      minHeight: "100vh",
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(8),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: theme.spacing(3),
      maxWidth: "unset",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0.5),
      },
    },
  });
});

export default function LoggedInWrapperView() {
  useTracker();
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = useLocalStorage("drawerOpen", false);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const { query } = useQuery<IGeneralQuery>();

  const isAdmin = useSelector(
    (state: RootState) => state.session.role === "admin"
  );

  if (isAdmin && !query.wholesalerId) {
    return <SelectWholesalersView />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Box displayPrint="none">
        <AppBar
          smallScreen={smallScreen}
          drawerIsOpen={open}
          openDrawer={openDrawer}
        />
        {smallScreen ? (
          <SwipeableDrawer
            disableBackdropTransition
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <DrawerContent
              closeOnClick
              hideTooltips
              closeDrawer={closeDrawer}
            />
          </SwipeableDrawer>
        ) : (
          <Drawer
            variant={smallScreen ? "temporary" : "permanent"}
            anchor="left"
            open={open}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <DrawerContent hideTooltips={open} closeDrawer={closeDrawer} />
          </Drawer>
        )}
      </Box>

      <Container className={classes.content} maxWidth={false}>
        <Box className={classes.toolbar} displayPrint="none" />
        <Routes />
      </Container>
    </div>
  );
}
