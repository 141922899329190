import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	center: {
		textAlign: "center",
	},
	list: {
		width: "100%",
	},
	listItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		paddingBottom: 10,
		marginBottom: 10,
	},
	pagination: {
		display: "flex",
		justifyContent: "center",
		marginTop: 20,
	},
});
