import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Scroller from "../scroller/Scroller";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Button from "@material-ui/core/Button";
import get from "lodash/get";
import InputAdornment from "@material-ui/core/InputAdornment";
import { patchUsers } from "../../warehouse/usersWarehouse";
import { MultiSelect } from "../select/Select";
import removeUndefined from "../../utils/removeUndefined";

interface IProps {
  usersIds: string[];
}

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    width: 375,
    minWidth: 375,
    display: "flex",
    flexDirection: "column",
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    flex: 1,
    paddingBottom: theme.spacing(),
  },
  inputBlock: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  bulkEditButtonWrapper: {
    padding: theme.spacing(2),
  },
}));

export default function UsersBulkEdit(props: IProps) {
  const classes = useStyles();
  const { query, setQuery } = useQuery<ISalesRepsQuery>();
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const teamLeads = useSelector((state: RootState) => {
    return Object.keys(state.usersWarehouse.usersById)
      .filter(
        (userId) => state.usersWarehouse.usersById[userId].role === "teamLead"
      )
      .map((userId) => ({
        label: state.usersWarehouse.usersById[userId].name,
        key: state.usersWarehouse.usersById[userId].customId,
      }));
  });
  const { inputs, userPropsList } = useSelector((state: RootState) => {
    const _inputs = {
      "gp.goal": {
        adornment: "$",
        label: "GP goal",
        min: 0,
        max: 0,
        helper: "",
      },
      "gp.payout": {
        adornment: "$",
        label: "GP payout",
        min: 0,
        max: 0,
        helper: "",
      },
      "gp.threshold": {
        adornment: "%",
        label: "GP threshold",
        min: 0,
        max: 0,
        helper: "",
      },
      "gp.bonus": {
        adornment: "$",
        label: "GP bonus",
        min: 0,
        max: 0,
        helper: "",
      },
      "volume.goal": {
        adornment: "%",
        label: "CE goal",
        min: 0,
        max: 0,
        helper: "",
      },
      "volume.payout": {
        adornment: "$",
        label: "CE payout",
        min: 0,
        max: 0,
        helper: "",
      },
      "volume.threshold": {
        adornment: "%",
        label: "CE threshold",
        min: 0,
        max: 0,
        helper: "",
      },
      "volume.bonus": {
        adornment: "$",
        label: "CE bonus",
        min: 0,
        max: 0,
        helper: "",
      },
      "fpl.goal": {
        adornment: "%",
        label: "FPL goal",
        min: 0,
        max: 0,
        helper: "",
      },
      "fpl.payout": {
        adornment: "$",
        label: "FPL payout",
        min: 0,
        max: 0,
        helper: "",
      },
      "wholesaler.maxFPL": {
        label: "Max FPL",
        min: 0,
        max: 0,
        helper: "",
        adornment: "",
      },
    };
    const _userPropsList = Object.keys(_inputs) as Array<keyof typeof _inputs>;
    props.usersIds.forEach((userId) => {
      _userPropsList.forEach((path) => {
        const value = get(state.usersWarehouse.usersById[userId], path);
        const { min, max } = get(_inputs, path);
        if (!min || min > value) {
          _inputs[path].min = value;
        }
        if (!max || max < value) {
          _inputs[path].max = value;
        }
      });
    });
    _userPropsList.forEach((key) => {
      if (_inputs[key].min === _inputs[key].max) {
        _inputs[key].helper = `${_inputs[key].min}`;
      } else {
        _inputs[key].helper = `${_inputs[key].min} - ${_inputs[key].max}`;
      }
    });
    return { inputs: _inputs, userPropsList: _userPropsList };
  });
  const [teamLead, setTeamLead] = useState<string[]>([]);
  // @ts-ignore
  register("teamLead");

  return (
    <Scroller>
      <Card className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h5">Bulk Edit Users</Typography>
          <IconButton
            size="small"
            onClick={() => setQuery({ bulkEdit: undefined })}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box className={classes.scroll}>
          <Box className={classes.inputBlock}>
            <MultiSelect
              options={teamLeads}
              selected={teamLead}
              onSelect={(val) => {
                setTeamLead(val || []);
                setValue("teamLead", val);
              }}
              label="Team"
            />
          </Box>
          {userPropsList.map((key) => (
            <Box key={key} className={classes.inputBlock}>
              <TextField
                size="small"
                error={!!get(errors, key)}
                placeholder={`${inputs[key].helper} (Unchanged)`}
                name={key}
                inputRef={register({
                  valueAsNumber: true,
                  pattern: {
                    value: /[0-9.]/,
                    message: "🤔 Doesn't look like a numeric value",
                  },
                })}
                label={inputs[key].label}
                helperText={get(errors, key)?.message}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {inputs[key].adornment}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ))}
        </Box>
        <Divider />
        <Box className={classes.bulkEditButtonWrapper}>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            fullWidth
            disabled={props.usersIds.length > 100}
            onClick={handleSubmit((values) => {
              if (true) {
                const { wholesalerId, db } = query;
                dispatch(
                  patchUsers(
                    {
                      customIds: props.usersIds,
                      wholesalerId,
                      db,
                    },
                    {
                      patch: removeUndefined(values),
                    }
                  )
                );
              }
            })}
          >
            {props.usersIds.length > 100 ? "Too many users (>100)" : "Save"}
          </Button>
        </Box>
      </Card>
    </Scroller>
  );
}
