import {
	ObjIncentiveImages,
	ObjIncProducts,
	MeasureState,
	CustomObjIncProductFilters,
	CustomObjIncAccountFilters,
} from "./commonObjIncTypes";

export type IncentiveUser = {
	user: string;
	images: ObjIncentiveImages | [];
	quantity: number | null;
	validation: boolean | null;
	completed: boolean;
	goal: number | null;
	progress?: number;
	payout: number;
	target: number;
	isOpenEnded: boolean;
	payoutType: string;
	threshold: { units: string; value: number };
	cap: { units: string; value: number };
	totalPods?: number;
	totalAchieved?: number;
	percentageAchieved?: number;
	totalItems?: number;
	totalPayout?: number;
	isEdited: boolean;
	minQuantityValue?: number;
	advancedBudget?: number;
	advancedPayments?: AdvancedPayment[];
};

export type RankingPrize = {
	prizeName: string;
	prizeDescription?: string;
	prizeImage?: string;
	prizeRank: number;
};

export type AdvancedPayment = {
	completed: number;
	ofBudget: number;
};

export type Incentive = {
	_id: string;
	name: string;
	type: string;
	description?: string;
	period?: string;
	customPeriodStart?: string;
	customPeriodEnd?: string;
	payout: number;
	supplierContribution: number;
	status?: number;
	accountsFilters?: CustomObjIncAccountFilters;
	selectAllAccounts?: boolean;
	buyerIds?: string[];
	productsFilters?: CustomObjIncProductFilters;
	products?: ObjIncProducts;
	users?: IncentiveUser[];
	favorite?: boolean;
	archived?: boolean;
	customId?: string;
	price?: number;
	earned?: number;
	progress?: number;
	available?: string;
	quantity?: {
		achieved: number;
		target: number;
	};
	rankingPrizes: RankingPrize[];
	isSubIncentive?: boolean;
	parentIncentiveId?: string;
	measure?: MeasureState;
	isOpenEnded: boolean;
	threshold?: { units: string; value: number };
	cap?: { units: string; value: number };
	payoutType?: string;
	quantityPayout?: number;
	images?: ObjIncentiveImages;
	hasCheck?: boolean;
	multi?: boolean;
	subIncentives?: Incentive[];
	expanded: boolean;
	isGrouped?: boolean;
	groupedIncentives?: any[];
	weight?: number;
	valueInputType?: string;
	valueInput?: number;
	isValid: boolean;
	target: number;
	minQuantityValue?: number;
	advancedPayments?: AdvancedPayment[];
	advancedBudget?: number;
};

export enum IncentiveTypeEnum {
	Image = "image",
	Binary = "binary",
	Validation = "validation",
	Quantity = "quantity",
	Multi = "multi",
	Grouped = "grouped",
	ImageValidation = "imageValidation",
}

export const incentiveTypes: { value: IncentiveTypeEnum; label: string }[] = [
	{ value: IncentiveTypeEnum.Image, label: "Image Incentive" },
	{ value: IncentiveTypeEnum.Binary, label: "Binary Incentive" },
	{ value: IncentiveTypeEnum.Quantity, label: "Quantity Incentive" },
	{ value: IncentiveTypeEnum.Multi, label: "Multi Incentive" },
	{ value: IncentiveTypeEnum.Grouped, label: "Grouped Incentive" },
	{ value: IncentiveTypeEnum.Validation, label: "Validation Incentive" },
	{
		value: IncentiveTypeEnum.ImageValidation,
		label: "Image Validation Incentive",
	},
];

export const subIncentiveTypes: { value: IncentiveTypeEnum; label: string }[] = [
	{ value: IncentiveTypeEnum.Image, label: "Image Incentive" },
	{ value: IncentiveTypeEnum.Binary, label: "Binary Incentive" },
	{ value: IncentiveTypeEnum.Quantity, label: "Quantity Incentive" },
	{
		value: IncentiveTypeEnum.ImageValidation,
		label: "Image Validation Incentive",
	},
];
