import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import WholesalerUpdateView from "./WholesalerUpdateView";
import WholesalerEditForm from "./WholesalerEditForm";
import WholesalerManagers from "./WholesalerManagers";

const useStyles = makeStyles((theme) => ({
  container: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
  },
  secondContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  column: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default function WholesalerView() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.secondContainer}>
        <WholesalerUpdateView />
        <Box className={classes.column}>
          <SpacingBoxView />
          <WholesalerEditForm />
          <SpacingBoxView />
          <WholesalerManagers />
          <SpacingBoxView />
        </Box>
      </Box>
    </Box>
  );
}
