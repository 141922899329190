import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { AppThunk } from '../app/store';
import { IUser, ISliceStatus } from '../interfaces';
import { IGeneralQuery } from '../hooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import snack from '../utils/snack';
import { IRole } from '../utils/roles';

interface IUsersById {
	[customId: string]: IUser;
}
interface IState {
	wholesalerId?: string;
	db?: string;
	usersById: IUsersById;
	status: ISliceStatus;
}

const initialState: IState = {
	usersById: {},
	status: 'idle',
};

export const usersSlice = createSlice({
	name: 'usersWarehouse',
	initialState,
	reducers: {
		statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
			state.status = action.payload;
		},
		fetchedClean: (
			state,
			action: PayloadAction<{
				items: IUser[];
				wholesalerId?: string;
				db?: string;
			}>
		) => {
			if (!action.payload.items.length) {
				state.status = 'empty';
				state.wholesalerId = action.payload.wholesalerId;
				state.db = action.payload.db;
			} else {
				const users: IUsersById = {};
				action.payload.items.forEach((item) => {
					users[item.customId] = item;
				});
				state.usersById = users;
				state.status = 'ready';
				state.wholesalerId = action.payload.wholesalerId;
				state.db = action.payload.db;
			}
		},
		fetched: (
			state,
			action: PayloadAction<{
				items: IUser[];
				wholesalerId?: string;
				db?: string;
			}>
		) => {
			if (!action.payload.items.length && isEmpty(state.usersById)) {
				state.status = 'empty';
				state.wholesalerId = action.payload.wholesalerId;
				state.db = action.payload.db;
			} else {
				action.payload.items.forEach((item) => {
					state.usersById[item.customId] = item;
				});
				state.status = 'ready';
				state.wholesalerId = action.payload.wholesalerId;
				state.db = action.payload.db;
			}
		},
		deleted: (state, action: PayloadAction<string>) => {
			delete state.usersById[action.payload];
		},
	},
	extraReducers: {
		'session/logOut': () => initialState,
	},
});

export const { statusChanged, fetched, fetchedClean, deleted } =
	usersSlice.actions;

export const getUsers =
	(query: IGeneralQuery, options?: { silent?: true }): AppThunk =>
	async (dispatch) => {
		if (!options?.silent) {
			dispatch(statusChanged('loading'));
		}
		const res = await api.fetch({
			path: '/users',
			method: 'GET',
			query,
		});
		if (res.ok) {
			dispatch(
				fetchedClean({
					items: res.payload,
					wholesalerId: query.wholesalerId,
					db: query.db,
				})
			);
		}
	};

export interface IPatchUsersQuery extends IGeneralQuery {
	customIds: string[];
}
export interface IUserPatch {
	name?: string;
	email?: string;
	routeNumber?: number[];
	totalPayout?: number;
	hidden?: boolean;
	viewOnly?: boolean;
	leadership?: boolean;
	gp?: {
		lastYear?: number;
		thisYear?: number;
		achieved?: number;
		achievedSellingDays?: number;
		totalPayout?: number;
		goal?: number;
		payout?: number;
		threshold?: number;
		bonus?: number;
	};
	volume?: {
		lastYear?: number;
		thisYear?: number;
		achieved?: number;
		achievedSellingDays?: number;
		totalPayout?: number;
		goal?: number;
		payout?: number;
		threshold?: number;
		bonus?: number;
	};
	fpl?: {
		individual?: number;
		team?: number;
		totalPayout?: number;
		goal?: number;
		payout?: number;
	};
	wholesaler?: {
		maxFPL?: number;
	};
	teamLead?: { customId: string; name: string }[];
	password?: string;
}
export interface IPatchUsersBody {
	patch: IUserPatch;
}

export const patchUsers =
	(query: IPatchUsersQuery, body: IPatchUsersBody): AppThunk =>
	async (dispatch) => {
		snack.info('Updating...');
		const keys = Object.keys(body.patch) as Array<keyof IUserPatch>;
		keys.forEach((key) => {
			if (body.patch[key] === '') {
				delete body.patch[key];
			}
		});
		const res = await api.fetch({
			path: '/users',
			method: 'PATCH',
			query,
			body,
		});
		if (res.ok) {
			snack.success('Update completed 👍');
			dispatch(
				fetched({
					items: res.payload,
					wholesalerId: query.wholesalerId,
					db: query.db,
				})
			);
		} else {
			snack.error('Update failed 😨');
		}
	};

interface IPostUser {
	email: string;
	name: string;
	password: string;
	role: IRole;
	customId?: string;
}

export const postUser =
	(
		query: IGeneralQuery,
		body: IPostUser,
		cb?: Function // bad practice
	): AppThunk =>
	async (dispatch) => {
		snack.info('Creating...');
		const res = await api.fetch({
			path: '/user',
			method: 'POST',
			query,
			body,
		});
		if (res.ok) {
			snack.success('User created 👍');
			cb && cb();
			dispatch(
				fetched({
					items: res.payload,
					wholesalerId: query.wholesalerId,
					db: query.db,
				})
			);
		} else {
			snack.error('Failure creating user 😵');
		}
	};

interface IDeleteUser extends IGeneralQuery {
	customId: string;
}
export const deleteUser =
	(query: IDeleteUser): AppThunk =>
	async (dispatch) => {
		snack.info('Deleting...');
		const res = await api.fetch({
			path: '/user',
			method: 'DELETE',
			query,
		});
		if (res.ok) {
			snack.success('User deleted 👍');
			dispatch(deleted(query.customId));
		} else {
			snack.error('Failure deleting user 😨');
		}
	};

export default usersSlice.reducer;
