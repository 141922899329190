import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import filesReducer from "../features/files/filesSlice";
import sessionSlice from "../features/session/sessionSlice";
import executiveReportSlice from "../features/executiveReport/executiveReportSlice";
import suppliersSlice from "../features/suppliers/suppliersSlice";
import brandsWarehouse from "../warehouse/brandsWarehouse";
import suppliersWarehouse from "../warehouse/suppliersWarehouse";
import wholesalerWarehouse from "../warehouse/wholesalerWarehouse";
import usersWarehouse from "../warehouse/usersWarehouse";
import productsSlice from "../features/products/productsSlice";
import buyersSlice from "../features/buyers/buyersSlice";
import analyticsSlice from "../features/analytics/analyticsSlice";
import wholesalerSlice from "../features/wholesaler/wholesalerSlice";
import diffSlice from "../features/diff/diffSlice";

const rootReducer = combineReducers({
  session: sessionSlice,
  files: filesReducer,
  executiveReport: executiveReportSlice,
  suppliers: suppliersSlice,
  products: productsSlice,
  buyers: buyersSlice,
  brandsWarehouse,
  suppliersWarehouse,
  wholesalerWarehouse,
  usersWarehouse,
  analytics: analyticsSlice,
  wholesaler: wholesalerSlice,
  diff: diffSlice,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage,
    whitelist: ["session"],
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
