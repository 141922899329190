import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoggedInWrapperView from "../loggedInWrapper/LoggedInWrapperView";
import {
  ThemeProvider,
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import green from "@material-ui/core/colors/green";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import LoginView from "../login/LoginView";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "../../utils/snack";

const createTheme =
  process.env.NODE_ENV === "development"
    ? unstable_createMuiStrictModeTheme
    : createMuiTheme;
const theme = createTheme({
  palette: {
    primary: {
      main: indigo[300],
    },
    secondary: {
      main: green[400],
    },
  },
});

function App() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <SnackbarUtilsConfigurator />
        <Router>
          <Switch>
            <Route
              path="/"
              render={() =>
                isLoggedIn ? <LoggedInWrapperView /> : <LoginView />
              }
            />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
