import { useEffect, useState } from "react";
import { objectivesStoreZustand } from "../store/objectivesStore";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";

const useArchivedReportObjectives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [archivedSecondaryPageSize, setArchivedSecondaryPageSize] =
		useState(10);
	const {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
	} = objectivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchArchivedObjectives({
			wholesalerId,
			page: archivedSecondaryCurrentPage,
			limit: archivedSecondaryPageSize,
			searchPhrase,
			type,
		});
	}, [
		wholesalerId,
		archivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		searchPhrase,
		type,
	]);

	return {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		setArchivedSecondaryPageSize,
		archivedTotalPages: objectivesStoreZustand(
			(state) => state.archivedTotalPages
		),
		loadingArchived: objectivesStoreZustand(
			(state) => state.loadingArchived
		),
		setLoadingArchived: objectivesStoreZustand(
			(state) => state.setLoadingArchived
		),
	};
};

export default useArchivedReportObjectives;