import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ProductsControls from "./ProductsControls";
import ProductsSummary from "./ProductsSummary";
import ProductsList from "./ProductsList";
import ProductDetails from "./ProductDetails";
import useQuery, { IProductsQuery } from "../../hooks/useQuery";
import SpacingBox from "../spacingBox/SpacingBoxView";

const useStyles = makeStyles((theme) => ({
  container: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(),
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listAndSummary: {
    marginRight: theme.spacing(2),
    minWidth: 375,
    width: 375,
    maxWidth: 375,
  },
}));

export default function ProductsView() {
  const { query } = useQuery<IProductsQuery>();
  const selectedProducts = query.selectedProducts;

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <ProductsControls />
        <Paper className={classes.listAndSummary}>
          <ProductsSummary />
          <ProductsList />
        </Paper>
        {selectedProducts?.map((selectedProduct) => (
          <ProductDetails key={selectedProduct} productId={selectedProduct} />
        ))}
        <SpacingBox />
      </Box>
    </Box>
  );
}
