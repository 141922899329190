import React from "react";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Email from "@material-ui/icons/Email";
import FormHelperText from "@material-ui/core/FormHelperText";
import { emailPattern } from "../../utils/isEmail";
import Button from "@material-ui/core/Button";
import { postPasswordReset } from "../session/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(2),
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignSelf: "stretch",
    },
  })
);

export default function ResetPasswordView(props: { goBack: () => void }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const submit = handleSubmit(({ email, password, otp }) =>
    dispatch(postPasswordReset({ email, password, otp }, props.goBack))
  );
  const isLoading = useSelector(
    (state: RootState) => state.session.status === "loading"
  );

  return (
    <Box flex={1} alignItems="flex-end" display="flex" flexDirection="column">
      <FormControl variant="outlined" fullWidth className={classes.textField}>
        <InputLabel htmlFor="emailInput">Email</InputLabel>
        <OutlinedInput
          label="Email"
          name="email"
          inputRef={register({
            required: { value: true, message: "Email is required" },
            pattern: {
              value: emailPattern,
              message: "This doesnt look like an email",
            },
          })}
          error={!!errors.email}
          id="emailInput"
          type="email"
          endAdornment={
            <InputAdornment position="end">
              <Email />
            </InputAdornment>
          }
        />
        <FormHelperText>
          {errors.email?.message || "email@example.com"}
        </FormHelperText>
      </FormControl>
      <FormControl variant="outlined" fullWidth className={classes.textField}>
        <InputLabel htmlFor="emailInput">One-time password</InputLabel>
        <OutlinedInput
          label="One-time password"
          name="otp"
          inputRef={register({
            required: {
              value: true,
              message: "One-time password is required",
            },
          })}
          error={!!errors.email}
          id="otpInput"
          type="text"
        />
        <FormHelperText>
          {errors.email?.message || "email@example.com"}
        </FormHelperText>
      </FormControl>
      <FormControl variant="outlined" fullWidth className={classes.textField}>
        <InputLabel htmlFor="passwordInput">Password</InputLabel>
        <OutlinedInput
          label="password"
          name="password"
          inputRef={register({
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 6,
              message: "Password must be longer than 6 characters",
            },
            maxLength: {
              value: 32,
              message: "Password must be shorter than 32 characters",
            },
          })}
          error={!!errors.password}
          onKeyPress={(key) => key.charCode === 13 && submit()}
          id="passwordInput"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          {errors.password?.message || "6 characters or more"}
        </FormHelperText>
      </FormControl>

      <Box className={classes.buttonsWrapper}>
        <Button onClick={props.goBack} variant="text">
          Cancel
        </Button>

        <Button
          onClick={submit}
          variant="outlined"
          endIcon={
            isLoading ? <CircularProgress size={20} /> : <AccountCircleIcon />
          }
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
