import { create } from 'zustand';
import { IGeneralQuery } from '../../hooks/useQuery';
import api from '../../utils/api';
import snack from '../../utils/snack';

interface Store {
	status: any;
	error: string | null;
	fetchStatus: (query: IGeneralQuery) => Promise<void>;
	updateObjectivesIncentives: (query: IGeneralQuery) => Promise<void>;
}
const useObjectivesIncentivesStore = create<Store>((set) => ({
	status: null,
	error: null,
	fetchStatus: async (query: IGeneralQuery) => {
		try {
			const response = await fetch(
				`/objectivesIncentivesStatus?wholesalerId=${query.wholesalerId}`
			);
			if (!response.ok) {
				throw new Error('Failed to fetch objectives and incentives status');
			}
			const data = await response.json();
			set({ status: data.data, error: null });
		} catch (error) {
			set({
				status: null,
				error:
					error?.message || 'Failed to fetch objectives and incentives status',
			});
		}
	},
	updateObjectivesIncentives: async (query: IGeneralQuery) => {
		try {
			snack.info(
				"The payout for objectives and incentives it's in progress! 💡"
			);
			const response = await api.fetch({
				path: '/objectivesIncentivesUpdate',
				method: 'POST',
				query,
			});
			if (!response.ok) {
				throw new Error('Failed to update objectives and incentives');
			}

			set({ status: response.payload, error: null });
			snack.success('The objectives and incentives were updated! 🚀', 10000);
		} catch (error) {
			snack.error(
				'Something went wrong with the objectives and incentives update! 😱'
			);
			set({
				error: error?.message || 'Failed to update objectives and incentives',
			});
		}
	},
}));

export default useObjectivesIncentivesStore;
