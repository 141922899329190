import React from "react";
import { getProductsSummary } from "./productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import useQuery, { IProductsQuery } from "../../hooks/useQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { usd, delta, volume } from "../../utils/format";

const useStyles = makeStyles((_) => ({
  container: {},
  table: {
    "& td, th": {
      paddingRight: 8,
      paddingLeft: 0,
    },
    "& tbody td:nth-child(1)": {
      paddingLeft: 16,
    },
  },
}));

export default function ProductsSummary() {
  const classes = useStyles();
  const summary = useSelector((state: RootState) => state.products.summary);
  const dispatch = useDispatch();
  const { query } = useQuery<IProductsQuery>();
  React.useEffect(() => {
    dispatch(
      getProductsSummary({
        brand: query.brand,
        gpRank: query.gpRank,
        packageType: query.packageType,
        premise: query.premise,
        size: query.size,
        supplier: query.supplier,
        tags: query.tags,
        trend: query.trend,
        volumeRank: query.volumeRank,
        searchPhrase: query.searchPhrase,
        wholesalerId: query.wholesalerId,
        db: query.db,
        sponsored: query.sponsored,
        excluded: query.excluded,
      })
    );
  }, [
    dispatch,
    JSON.stringify([
      query.brand,
      query.gpRank,
      query.packageType,
      query.premise,
      query.size,
      query.supplier,
      query.tags,
      query.trend,
      query.volumeRank,
    ]),
    query.searchPhrase,
    query.sponsored,
    query.excluded,
    query.wholesalerId,
    query.db,
  ]);
  if (!summary) {
    return null;
  }
  return (
    <TableContainer className={classes.container}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">This Year</TableCell>
            <TableCell align="center">Last Year</TableCell>
            <TableCell align="center">Delta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head">GP:</TableCell>
            <TableCell align="center">{usd(summary.gp.thisYear)}</TableCell>
            <TableCell align="center">{usd(summary.gp.lastYear)}</TableCell>
            <TableCell align="center"> {delta(summary.gp.delta, 0)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Vol:</TableCell>
            <TableCell align="center">
              {volume(summary.volume.thisYear)}
            </TableCell>
            <TableCell align="center">
              {volume(summary.volume.lastYear)}
            </TableCell>
            <TableCell align="center">
              {" "}
              {delta(summary.volume.delta, 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">GP/CE:</TableCell>
            <TableCell align="center">{usd(summary.gpCe.thisYear)}</TableCell>
            <TableCell align="center">{usd(summary.gpCe.lastYear)}</TableCell>
            <TableCell align="center">
              {" "}
              {delta(summary.gpCe.delta, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
