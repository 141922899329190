import {
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles } from "./PayoutComponent.styles";
import { PayoutDetails } from "../utils";
import { useFormContext } from "react-hook-form";
import { AdvancedPayment } from "../../../types/objectiveTypes";

interface PayoutComponentProps {
	itemType?: string;
	payoutDetails: PayoutDetails;
	setPayoutDetails: React.Dispatch<React.SetStateAction<PayoutDetails>>;
}
export const PayoutComponent = ({
	itemType,
	payoutDetails,
	setPayoutDetails,
}: PayoutComponentProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPayoutType = event.target.value;
		setPayoutDetails((prev) => ({
			...prev,
			payoutType: newPayoutType,
		}));
	};
	const handleInputChange =
		(field: keyof PayoutDetails | "thresholdValue" | "capValue") =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(event.target.value) || 0;

			if (field === "thresholdValue") {
				setPayoutDetails((prev) => ({
					...prev,
					threshold: { ...prev.threshold!, value },
				}));
			} else if (field === "capValue") {
				setPayoutDetails((prev) => ({
					...prev,
					cap: { ...prev.cap!, value },
				}));
			} else {
				setPayoutDetails((prev) => ({
					...prev,
					[field]: value,
				}));
			}
		};

	const handleSelectChange =
		(field: "threshold" | "cap", subField: "units" | "value") =>
		(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
			const value = event.target.value as string;
			setPayoutDetails((prev) => ({
				...prev,
				[field]: { ...prev[field]!, [subField]: value },
			}));
		};

	const handleAddRow = () => {
		const newPayment: AdvancedPayment = { completed: 0, ofBudget: 0 };
		setPayoutDetails((prev) => ({
			...prev,
			advancedPayments: [...(prev.advancedPayments || []), newPayment],
		}));
	};

	const handleDeleteRow = (index: number) => {
		setPayoutDetails((prev) => {
			const updatedPayments = [...(prev.advancedPayments || [])];
			updatedPayments.splice(index, 1);
			return { ...prev, advancedPayments: updatedPayments };
		});
	};

	const handleAdvancedPaymentChange =
		(index: number, field: keyof AdvancedPayment) =>
			(event: React.ChangeEvent<HTMLInputElement>) => {
				const value = parseInt(event.target.value) || 0;
				setPayoutDetails((prev) => {
					const updatedPayments = [...(prev.advancedPayments || [])];
					updatedPayments[index] = { ...updatedPayments[index], [field]: value };
					return { ...prev, advancedPayments: updatedPayments };
				});
			};

	const validateRowOrder = (value: number, index: number, field: keyof AdvancedPayment) => {
		const advancedPayments = payoutDetails.advancedPayments;
		if (!advancedPayments) return true;

		const previousValue = index > 0 ? advancedPayments[index - 1][field] : null;
		const nextValue = index < advancedPayments.length - 1 ? advancedPayments[index + 1][field] : null;

		if (previousValue !== null && value < previousValue) {
			return `Value must be greater or equal to the previous ${field}`;
		}

		if (nextValue !== null && value > nextValue) {
			return `Value must be less or equal to the next ${field}`;
		}

		return true;
	};

	return (
		<div>
			<FormLabel component="legend" style={{ marginRight: 10, marginTop: 10 }}>
				Payout type:
			</FormLabel>
			<div>
			<div className={classes.payoutContainer}>
				<div className={classes.left}>
					<RadioGroup
						value={payoutDetails.payoutType}
						onChange={handleRadioChange}
						className={classes.radioGroup}
					>
						{!payoutDetails.isOpenEnded && (
							<Grid item>
								<FormControlLabel
									value="allOrNothing"
									control={<Radio />}
									label="All Or Nothing"
								/>
							</Grid>
						)}
						<Grid item>
							<FormControlLabel
								value="perAction"
								control={<Radio />}
								label="Per Action Amount"
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								value="atRisk"
								control={<Radio />}
								label="At Risk Amount"
							/>
						</Grid>
						{itemType === "grouped" && (
							<Grid item>
								<FormControlLabel
									value="advanced"
									control={<Radio />}
									label="Advanced"
								/>
							</Grid>
						)}
					</RadioGroup>
					<div className={classes.textFields}>
						{!payoutDetails.isOpenEnded && (
							<TextField
								margin="dense"
								variant="outlined"
								id="allOrNothingAmount"
								name="allOrNothingAmount"
								type="number"
								defaultValue={payoutDetails.allOrNothingAmount}
								error={!!errors.allOrNothingAmount}
								helperText={
									errors.allOrNothingAmount && errors.allOrNothingAmount.message
								}
								disabled={
									payoutDetails.payoutType !== "allOrNothing" ||
									payoutDetails.isOpenEnded
								}
								onChange={handleInputChange("allOrNothingAmount")}
								inputRef={register({
									validate: {
										positiveNumber: (value) => {
											if (payoutDetails.payoutType === "allOrNothing") {
												return (
													parseInt(value) >= 0 ||
													"Value must be greater or equal to 0"
												);
											}
											return true;
										},
									},
								})}
							/>
						)}
						<TextField
							id="perActionAmount"
							name="perActionAmount"
							margin="dense"
							variant="outlined"
							type="number"
							defaultValue={payoutDetails.perActionAmount}
							error={!!errors.perActionAmount}
							helperText={
								errors.perActionAmount && errors.perActionAmount.message
							}
							onChange={handleInputChange("perActionAmount")}
							disabled={payoutDetails.payoutType !== "perAction"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType === "perAction") {
											return (
												parseInt(value) >= 0 ||
												"Value must be greater or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<TextField
							id="atRiskAmount"
							name="atRiskAmount"
							margin="dense"
							variant="outlined"
							type="number"
							defaultValue={payoutDetails.atRiskAmount}
							error={!!errors.atRiskAmount}
							helperText={errors.atRiskAmount && errors.atRiskAmount.message}
							onChange={handleInputChange("atRiskAmount")}
							disabled={payoutDetails.payoutType !== "atRisk"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType === "atRisk") {
											return (
												parseInt(value) >= 0 ||
												"Value must be greater or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
					</div>
				</div>
				<div className={classes.right}>
					<div className={classes.threshold}>
						<FormLabel component="legend">Threshold:</FormLabel>
						<TextField
							margin="dense"
							id="thresholdValue"
							name="thresholdValue"
							error={!!errors.thresholdValue}
							helperText={
								errors.thresholdValue && errors.thresholdValue.message
							}
							className={classes.inputThreshold}
							variant="outlined"
							type="number"
							disabled={payoutDetails.payoutType === "allOrNothing"}
							defaultValue={payoutDetails.threshold?.value}
							onChange={handleInputChange("thresholdValue")}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType !== "allOrNothing") {
											if (payoutDetails.threshold.units === "percentage") {
												return parseInt(value) >= 0 || "Value must be grater 0";
											}
											return (
												parseInt(value) >= 0 ||
												"Value must be greater than or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<Select
							value={payoutDetails.threshold?.units}
							onChange={handleSelectChange("threshold", "units")}
							disabled={payoutDetails.payoutType === "allOrNothing"}
							className={classes.select}
						>
							<MenuItem value="raw">Raw</MenuItem>
							<MenuItem value="percentage">%</MenuItem>
						</Select>
					</div>

					<div className={classes.cap}>
						<FormLabel component="legend">Cap:</FormLabel>
						<TextField
							margin="dense"
							id="capValue"
							variant="outlined"
							type="number"
							name="capValue"
							error={!!errors.capValue}
							helperText={errors.capValue && errors.capValue.message}
							className={classes.inputCap}
							defaultValue={payoutDetails.cap?.value}
							onChange={handleInputChange("capValue")}
							disabled={payoutDetails.payoutType === "allOrNothing"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType !== "allOrNothing") {
											if (payoutDetails.cap.units === "percentage") {
												return (
													parseInt(value) >= 0 || "Value must be grater than 0"
												);
											}
											return (
												parseInt(value) >= 0 ||
												"Value must be greater than or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
					</div>
				</div>
			</div>
				<div>
					{payoutDetails.payoutType === "advanced" && (
						<div className={classes.advancedContainer}>
							<div className={classes.budgetContainer}>
								<FormLabel style={{ marginRight: 10, marginTop: 10 }}>
									Budget:
								</FormLabel>
								<TextField
									margin="dense"
									variant="outlined"
									id="advancedBudget"
									name="advancedBudget"
									type="number"
									defaultValue={payoutDetails.advancedBudget}
									error={!!errors.advancedBudget}
									helperText={
										errors.advancedBudget && errors.advancedBudget.message
									}
									onChange={handleInputChange("advancedBudget")}
									inputRef={register({
										validate: {
											positiveNumber: (value) => {
												if (payoutDetails.payoutType === "advanced") {
													return (
														parseInt(value) >= 0 ||
														"Value must be greater or equal to 0"
													);
												}
												return true;
											},
										},
									})}
								/>
							</div>
							<div>
								<Paper variant="outlined" className={classes.paper}>
									{payoutDetails.advancedPayments?.map((payment, index) => (
										<div key={index} className={classes.advancedTextFields}>
											<TextField
												label="% Completed"
												name={`payoutDetails.advancedPayments[${index}].completed`}
												margin="dense"
												variant="outlined"
												type="number"
												className={classes.advancedText}
												defaultValue={payment.completed}
												error={!!errors.payoutDetails?.advancedPayments?.[index]?.completed}
												helperText={
													errors.payoutDetails?.advancedPayments?.[index]?.completed &&
													errors.payoutDetails?.advancedPayments?.[index]?.completed.message
												}
												onChange={handleAdvancedPaymentChange(index, "completed")}
												inputRef={register({
													required: true,
													validate: {
														positiveNumber: (value) => {
															if (payoutDetails.payoutType === "advanced") {
																return (
																	parseInt(value) >= 0 ||
																	"Value must be greater or equal to 0"
																);
															}
															return true;
														},
														rowOrder: (value) => validateRowOrder(value, index, "completed"),
													},
												})}
											/>
											<TextField
												label="% of Budget"
												name={`payoutDetails.advancedPayments[${index}].ofBudget`}
												margin="dense"
												variant="outlined"
												type="number"
												className={classes.advancedText}
												defaultValue={payment.ofBudget}
												error={!!errors.payoutDetails?.advancedPayments?.[index]?.ofBudget}
												helperText={
													errors.payoutDetails?.advancedPayments?.[index]?.ofBudget &&
													errors.payoutDetails?.advancedPayments?.[index]?.ofBudget.message
												}
												onChange={handleAdvancedPaymentChange(index, "ofBudget")}
												inputRef={register({
													required: true,
													validate: {
														positiveNumber: (value) => {
															if (payoutDetails.payoutType === "advanced") {
																return (
																	parseInt(value) >= 0 ||
																	"Value must be greater or equal to 0"
																);
															}
															return true;
														},
														rowOrder: (value) => validateRowOrder(value, index, "ofBudget"),
													},
												})}
											/>
											<div className={classes.buttonContainer}>
												<DeleteIcon onClick={() => handleDeleteRow(index)} className={classes.button} />
											</div>
										</div>
									))}
									<IconButton onClick={handleAddRow}>
										<AddIcon />
									</IconButton>
								</Paper>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
