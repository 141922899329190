import { useCallback, useEffect, useState } from 'react';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';
import { commonObjIncStoreZustand } from '../store/commonObjIncStore';

const useAccountsItem = (itemId: string, userId: string, isObjective:boolean) => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const { accounts, currentPage, setCurrentPage } =
		commonObjIncStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const fetchAccounts = useCallback(() => {
		commonObjIncStoreZustand.getState().fetchAccounts(
			{
				wholesalerId,
				page: currentPage,
				limit: secondaryPageSize,
			},
			itemId,
			userId,
			isObjective,
		);
	}, [wholesalerId, currentPage, secondaryPageSize, itemId, userId, isObjective]);

	return {
		accounts,
		currentPage,
		setCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		secondaryTotalPages: commonObjIncStoreZustand((state) => state.secondaryTotalPages),
		secondaryLoading: commonObjIncStoreZustand((state) => state.secondaryLoading),
		setSecondaryLoading: commonObjIncStoreZustand((state) => state.setSecondaryLoading),
		fetchAccounts,
	};
};

export default useAccountsItem;
