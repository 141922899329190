import Joi from "joi";

export type IColumnGroup = "ce" | "gp" | "gp/ce" | "revenue";
export const columnGroups: IColumnGroup[] = ["ce", "gp", "gp/ce", "revenue"];
export type IPeriod = "MTD" | "YTD";
export const periods: IPeriod[] = ["MTD", "YTD"];
export type IColumnNames =
  | "CE TY"
  | "CE LY"
  | "CE Δ"
  | "GP TY"
  | "GP LY"
  | "GP Δ"
  | "GP Margin"
  | "GP/CE TY"
  | "GP/CE LY"
  | "GP/CE Δ"
  | "Revenue TY"
  | "Revenue LY"
  | "Revenue Δ";
export const columnNames: IColumnNames[] = [
  "CE TY",
  "CE LY",
  "CE Δ",
  "GP TY",
  "GP LY",
  "GP Δ",
  "GP Margin",
  "GP/CE TY",
  "GP/CE LY",
  "GP/CE Δ",
  "Revenue TY",
  "Revenue LY",
  "Revenue Δ",
];
export interface ISuppliersQuery {
  wholesalerId?: string;
  db?: string;
  searchPhrase?: string;
  orderBy: IColumnNames;
  descending: boolean;
  period: IPeriod;
  hiddenColumns: IColumnGroup[];
  tags: string[];
}

const defaultQuery: ISuppliersQuery = {
  searchPhrase: "",
  orderBy: columnNames[0],
  descending: false,
  period: periods[0],
  hiddenColumns: [],
  tags: [],
};
export const suppliersSchema = Joi.object({
  wholesalerId: Joi.string(),
  db: Joi.string(),
  searchPhrase: Joi.string().failover(defaultQuery.searchPhrase),
  orderBy: Joi.string()
    .valid(...columnNames)
    .failover(defaultQuery.orderBy)
    .default(defaultQuery.orderBy),
  descending: Joi.bool()
    .failover(defaultQuery.descending)
    .default(defaultQuery.descending),
  period: Joi.string()
    .valid(...periods)
    .failover(defaultQuery.period)
    .default(defaultQuery.period),
  hiddenColumns: Joi.array()
    .items(Joi.string().valid(...columnGroups))
    .failover(defaultQuery.hiddenColumns)
    .default(defaultQuery.hiddenColumns),
  tags: Joi.array().items(Joi.string()),
});
