import React from "react";
import List from "@material-ui/core/List";
import UserListItem, { IOnItemClick } from "./UserListItem";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  container: {
    minWidth: 375,
    width: 375,
  },
  contentScroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    height: "100%",
  },
}));

interface IProps {
  usersIds: string[];
  selectedUser?: string;
  onItemClick?: IOnItemClick;
}

export default function UsersListView(props: IProps) {
  const classes = useStyles();
  if (!props.usersIds.length) {
    return (
      <Box padding={2} className={classes.container}>
        <Typography>There are no matching users</Typography>
      </Box>
    );
  }
  return (
    <Box className={classes.contentScroll}>
      <List className={classes.container} dense>
        {props.usersIds.map((userId) => (
          <UserListItem
            key={userId}
            onItemClick={props.onItemClick || "navigate"}
            userId={userId}
            selected={userId === props.selectedUser}
          />
        ))}
      </List>
    </Box>
  );
}
