import { makeStyles } from '@material-ui/core';
import colorsArray from '../../../../../utils/colorsArray';

export const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: '8px 0',
	},
	dialog: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		width: '500px',
	},
	dialogTitle: {
		textAlign: 'center',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		margin: theme.spacing(1),
		borderRadius: '50px',
		textTransform: 'none',
		fontWeight: 'lighter',
		width: '90px',
		height: '45px',
	},
	addButton: {
		backgroundColor: colorsArray[2][300],
		color: 'white',
		'&:hover': {
			backgroundColor: colorsArray[2][400],
		},
	},
	cancelButton: {
		backgroundColor: '#9e9e9e', // a grey color for the cancel button
		color: 'white',
		'&:hover': {
			backgroundColor: '#757575', // a slightly darker shade for hover effect
		},
	},
	addFromFavoritesButton: {
		width: '110px',
		fontSize: '11px',
	},
}));
