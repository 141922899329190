import {
	FormControl,
	FormLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { useStyles } from './ValueInput.styles';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface ValueInputProps {
	valueInputType?: string;
	valueInput?: number;
	handleChange: (name: string, value: string | number) => void;
}

export const ValueInput = ({
	valueInputType,
	valueInput,
	handleChange,
}: ValueInputProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	const [selectedType, setSelectedType] = useState<string>(
		valueInputType || 'gt'
	);
	const [inputValue, setInputValue] = useState<number | ''>(valueInput || '');

	const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newValue = event.target.value as string;
		setSelectedType(newValue);
		handleChange('valueInputType', newValue);
	};

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newValue = event.target.value as number | '';
		if (newValue < 0) {
			newValue = 0;
		}
		setInputValue(newValue);
		handleChange('valueInput', newValue);
	};
	return (
		<div className={classes.container}>
			<FormLabel component="legend" className={classes.label}>
				Value input:
			</FormLabel>
			<FormControl className={classes.formControl}>
				<InputLabel>Select value type</InputLabel>
				<Select value={selectedType} onChange={handleTypeChange}>
					<MenuItem value="gt">Greater than</MenuItem>
					<MenuItem value="lt">Less than</MenuItem>
					<MenuItem value="gte">Greater than or equal to</MenuItem>
					<MenuItem value="lte">Less than or equal</MenuItem>
				</Select>
			</FormControl>
			<FormControl>
				<TextField
					className={classes.textField}
					error={!!errors.value}
					helperText={errors.value && errors.value.message}
					name="value"
					margin="dense"
					variant="outlined"
					type="number"
					label="Value"
					defaultValue={inputValue}
					onChange={handleValueChange}
					inputRef={register({
						validate: {
							positiveNumber: (value) =>
								parseInt(value) >= 0 || 'Value must be greater or equal to 0',
						},
					})}
				/>
			</FormControl>
		</div>
	);
};
