import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../store/incentivesStore";

const useReportGroupedIncentives = ({
	searchPhrase,
}: {
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);
	const [loadingChildren, setLoadingChildren] = useState(true);

	const { incentives, reportingGroupedPage, setReportingGroupedPage } =
		incentivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllChildrenByUser = async () => {
			setLoadingChildren(true);

			const incentivesStore = incentivesStoreZustand.getState();
			const incentives = incentivesStore.incentives;

			const childrenFetchPromises = incentives.map(async (incentive) => {
				if (incentive.users) {
					const userFetchPromises = incentive.users.map((user) =>
						incentivesStore.fetchChildrenByUser(
							{ wholesalerId },
							incentive._id,
							user.user
						)
					);
					await Promise.all(userFetchPromises);
				}
			});

			await Promise.all(childrenFetchPromises);

			setLoadingChildren(false);
		};
		incentivesStoreZustand.getState().fetchIncentivesReportTable(
			{
				wholesalerId,
				page: reportingGroupedPage,
				limit: secondaryPageSize,
				searchPhrase,
				type: "grouped",
			},
			fetchAllChildrenByUser
		);
	}, [wholesalerId, reportingGroupedPage, secondaryPageSize, searchPhrase]);

	return {
		incentives,
		reportingGroupedPage,
		setReportingGroupedPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: incentivesStoreZustand((state) => state.totalPages),
		loading: incentivesStoreZustand((state) => state.loading),
		setLoading: incentivesStoreZustand((state) => state.setLoading),
		loadingChildren,
	};
};

export default useReportGroupedIncentives;
