import { ButtonGroup, Button, Paper } from "@material-ui/core";
import { useState } from "react";
import { GroupedObjectivesManagementSummary } from "./groupedObjectives/GroupedObjectivesManagementSummary";
import { GroupedIncentivesManagementSummary } from "./groupedIncentives/GroupedIncentivesManagementSummary";
import { useStyles } from "./GroupedManagementSummary.styles";

export const GroupedManagementSummary = () => {
    const classes = useStyles();

    const [showIncentives, setShowIncentives] = useState<boolean>(false);




    return (
        <Paper className={classes.container}>
                {!showIncentives ? (
                <GroupedObjectivesManagementSummary
                    showIncentives={showIncentives}
                    setShowIncentives={setShowIncentives}
                />
                ) : (
                <GroupedIncentivesManagementSummary
                    showIncentives={showIncentives}
                    setShowIncentives={setShowIncentives}
                />
            )}
        </Paper>
    )
}
