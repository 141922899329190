import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getWholesalers } from "../../warehouse/wholesalerWarehouse";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      alignItems: "center",
      display: "flex",
      backgroundColor: theme.palette.grey[100],
    },
    secondContainer: {
      overflow: "hidden",
      padding: theme.spacing(3),
      height: "100vh",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0.5),
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "100%",
    },
    list: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
      overflowY: "auto",
      maxHeight: "100%",
    },
    title: {
      padding: theme.spacing(2),
      flexDirection: "row",
      display: "flex",
    },
  })
);

export default function SelectWholesalersView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { query, setQuery } = useQuery<IGeneralQuery>();
  useEffect(() => {
    dispatch(getWholesalers({ db: query.db }));
  }, [query.db, dispatch]);
  const wholesalers = useSelector(
    (state: RootState) => state.wholesalerWarehouse.otherWholesalers
  );
  return (
    <Box className={classes.container}>
      <Container maxWidth="xs" className={classes.secondContainer}>
        <Card className={classes.card}>
          <Box className={classes.title}>
            <Typography variant="h5">Select wholesaler</Typography>
          </Box>
          <Divider />
          <List className={classes.list}>
            {wholesalers.map((wholesaler) => (
              <ListItem
                key={wholesaler.customId}
                button
                onClick={() => setQuery({ wholesalerId: wholesaler.customId })}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`${config.bucketUrl}/images/${wholesaler.customId}/self/${wholesaler.customId}.jpg`}
                  >
                    {wholesaler.title
                      .match(/\b(\w)/g)
                      ?.slice(0, 2)
                      .join("")}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={wholesaler.title}
                  secondary={wholesaler.customId}
                />
              </ListItem>
            )) ||
              Array(10)
                .fill(undefined)
                .map((_, index) => (
                  <ListItem key={`select_wholesaler_skeleton_${index}`}>
                    <ListItemAvatar>
                      <Skeleton height={40} width={40} variant="circle" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton />}
                      secondary={<Skeleton />}
                    />
                    <Box marginLeft={2}>
                      <Skeleton height={36} width={38} />
                    </Box>
                  </ListItem>
                ))}
          </List>
        </Card>
      </Container>
    </Box>
  );
}
