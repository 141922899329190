import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Objective } from "../../types/objectiveTypes";
import { SubItem } from "./SubItem";
import { Incentive } from "../../types/incentivesTypes";
import { useStyles } from "./ItemsList.styles";
import { isIncentive } from "../../types/commonObjIncTypes";

interface SubItemListProps {
	subItems?: Objective[] | Incentive[] | null;
	onDelete: (id: string, parentId: string) => void;
	onEdit: (id: string) => void;
	onDuplicate: (id: string, parentObjectiveId: string) => void;
}

export const SubItemList: React.FC<SubItemListProps> = ({
	subItems,
	onDelete,
	onDuplicate,
	onEdit,
}) => {
	const classes = useStyles();

	if (!subItems) {
		return null;
	}

	return (
		<Table className={classes.customTable}>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Type</TableCell>
					<TableCell
						style={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "flex-end",
						}}
					>
						Actions
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{subItems &&
					subItems.map((subItem) => {
						const parentId = !isIncentive(subItem)
							? subItem.parentObjectiveId
							: subItem.parentIncentiveId;

						return (
							<SubItem
								key={subItem._id}
								item={subItem}
								onDelete={() => parentId && onDelete(subItem?._id, parentId)}
								onEdit={() => onEdit(subItem._id)}
								onDuplicate={() =>
									parentId && onDuplicate(subItem._id, parentId)
								}
							/>
						);
					})}
			</TableBody>
		</Table>
	);
};
