import { Divider, FormControl } from "@material-ui/core"
import { ReactNode } from "react"
import { useStyles } from "../objectivesIncentivesComponent/editObjIncComponents/EditComponent.styles";

interface DividerFormControlProps {
    children: ReactNode;
}

export const DividerFormControl = ({ children }: DividerFormControlProps) => {
    const classes = useStyles();
    return (
        <>
            <Divider className={classes.divider} />
            <FormControl component="fieldset" className={classes.formControl}>
                {children}
            </FormControl>
        </>
    )
}