import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { useEffect } from "react";

const useGroupedObjective = ({ itemId }: { itemId: string }) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const loadingGrouped = objectivesStoreZustand(
		(state) => state.loadingGrouped
	);

	const currentGroupedPage = objectivesStoreZustand(
		(state) => state.currentGroupedPage
	);
	const setCurrentGroupedPage = objectivesStoreZustand(
		(state) => state.setCurrentGroupedPage
	);

	const totalGroupedPages = objectivesStoreZustand(
		(state) => state.totalGroupedPages
	);

	const addGroupedObjectives = (
		children: { id: string; weight: number }[],
		parentObjectiveId: string
	) => {
		const obj = objectivesStoreZustand
			.getState()
			.addGroupedObjectives({ wholesalerId }, children, parentObjectiveId);
		objectivesStoreZustand
			.getState()
			.fetchGroupedObjectives({ wholesalerId }, parentObjectiveId);

		return obj;
	};

	useEffect(() => {
		objectivesStoreZustand
			.getState()
			.fetchGroupedObjectives({ wholesalerId }, itemId);
	}, [itemId, wholesalerId]);

	const groupedObjectives = objectivesStoreZustand(
		(state) => state.groupedObjectives
	);
	return {
		currentGroupedPage,
		totalGroupedPages,
		setCurrentGroupedPage,
		addGroupedObjectives,
		groupedObjectives,
		groupedTotalPages: objectivesStoreZustand.getState().totalGroupedPages,
		loadingGrouped,
	};
};

export default useGroupedObjective;
