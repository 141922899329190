import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { Podcast, podcastsStoreZustand } from "./podcastLinksStore";

const usePodcasts = () => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const podcasts = podcastsStoreZustand((state) => state.podcasts);
	const loading = podcastsStoreZustand((state) => state.loading);
	const addPodcast = async (newPodcast: Omit<Podcast, "_id">) => {
		const pod = await podcastsStoreZustand
			.getState()
			.addPodcast({ wholesalerId }, { ...newPodcast });
		return pod;
	};

	const editPodcast = (
		podcastId: string,
		updatedFields: Partial<Podcast>
	) => {
		podcastsStoreZustand
			.getState()
			.editPodcast(query, { _id: podcastId, ...updatedFields });
	};

	const getPodcastById = async (id: string) => {
		const podcast = await podcastsStoreZustand
			.getState()
			.getPodcastById(query, id);
		return podcast;
	};

	const deletePodcast = (podcastId: string) => {
		podcastsStoreZustand.getState().deletePodcast(query, podcastId);
	};

	useEffect(() => {
		podcastsStoreZustand.getState().fetchPodcasts({ wholesalerId });
	}, [wholesalerId]);

	return {
		loading,
		podcasts,
		getPodcastById,
		addPodcast,
		editPodcast,
		deletePodcast,
	};
};

export default usePodcasts;
