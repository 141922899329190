import Joi from "joi";
import { IGeneralQuery } from "../../hooks/useQuery";
import { IPeriod, periods } from "./analyticsSlice";

export type IClient = "app" | "dashboard";
export const clients: IClient[] = ["app", "dashboard"];
export type IGroup = "teams" | "salesReps" | "teamLeads";
export const groups: IGroup[] = ["salesReps", "teamLeads"];

export interface IAnalyticsQuery extends IGeneralQuery {
  users: string[];
  period: IPeriod;
  client: IClient;
  group: IGroup;
}

const defaultQuery: IAnalyticsQuery = {
  users: [],
  period: periods[0],
  client: clients[1],
  group: groups[0],
};
export const analyticsSchema = Joi.object({
  wholesalerId: Joi.string(),
  db: Joi.string(),
  period: Joi.string()
    .valid(...periods)
    .failover(defaultQuery.period)
    .default(defaultQuery.period),
  group: Joi.string()
    .valid(...groups)
    .failover(defaultQuery.group)
    .default(defaultQuery.group),
  client: Joi.string()
    .valid(...clients)
    .failover(defaultQuery.client)
    .default(defaultQuery.client),
  users: Joi.array()
    .items(Joi.string())
    .failover(defaultQuery.users)
    .default(defaultQuery.users),
});
