import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	quantityContainer: {
		marginTop: '1rem',
	},
	formControl: {
		display: 'flex',
	},
	measureContainer: {
		maxHeight: '23rem',
		overflowY: 'auto',
		marginBottom: '20px',
		alignSelf: 'left',
		width: '46%',
		minWidth: '20.688rem',
		padding: '1rem',
		backgroundColor: theme.palette.background.paper,
	},
	measureContainerCollapsed: {
		maxHeight: '15rem',
		overflowY: 'auto',
		marginBottom: '20px',
		alignSelf: 'left',
		width: '46%',
		minWidth: '20.688rem',
		padding: '1rem',
		backgroundColor: theme.palette.background.paper,
	},
	radioGroup: {
		marginBottom: '1rem',
	},
	textField: {
		width: '70px',
		marginRight: theme.spacing(1),
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '65px',
		maxWidth: '45rem',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	period: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.3rem'
	},
	formLabel: {
		marginTop: '1rem',
		marginBottom: '1rem',
	},
	excludeSelect: {
		maxWidth: '25rem',
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: "1rem",
	},
	options: {
		marginLeft: '65px',
		maxWidth: '45rem',
		display: "flex",
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	option: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.5rem',
		width: '20rem'
	},
	
}));
