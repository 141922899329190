import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles({
	container: {
		maxHeight: "100px",
		overflowY: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	dialogImage: {
		maxWidth: "100%",
		height: "auto",
	},
	notes: {
		padding: "0 2rem",
	},
});