import React from "react";
import Box from "@material-ui/core/Box";
import UsersListView from "../usersList/UsersListView";
import UsersDetailsView from "../usersDetails/UsersDetailsView";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Scroller from "../scroller/Scroller";
import NoUsers from "./NoUsers";
import UsersBulkEdit from "./UsersBulkEdit";

const useStyles = makeStyles((theme) => ({
  container: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
  },
  secondContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  column: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface IProps {
  usersIds: string[];
}

export default function UsersView(props: IProps) {
  const classes = useStyles();
  const { query, setQuery } = useQuery<ISalesRepsQuery>();

  const usersLoaded = useSelector(
    (state: RootState) => state.usersWarehouse.status === "ready"
  );
  const detailsUserId = query.selectedUser || props.usersIds[0];

  React.useEffect(() => {
    if (usersLoaded && !query.selectedUser && detailsUserId) {
      setQuery({ selectedUser: detailsUserId });
    }
  }, [query, detailsUserId, setQuery, usersLoaded]);

  if (usersLoaded && !props.usersIds?.length) {
    return <NoUsers />;
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.secondContainer}>
        <Paper>
          <Scroller>
            <UsersListView
              usersIds={props.usersIds}
              selectedUser={query.selectedUser}
            />
          </Scroller>
        </Paper>
        {query.bulkEdit === "true" && (
          <Box className={classes.column}>
            <SpacingBoxView />
            <UsersBulkEdit usersIds={props.usersIds} />
          </Box>
        )}
        <Box className={classes.column}>
          <SpacingBoxView />
          <UsersDetailsView userId={detailsUserId} />
        </Box>
        {query.compareWith?.map((userId) => (
          <Box className={classes.column} key={userId}>
            <SpacingBoxView />
            <UsersDetailsView isCompared userId={userId} />
          </Box>
        ))}
        <SpacingBoxView />
      </Box>
    </Box>
  );
}
