import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../app/store";
import api from "../utils/api";
import snack from "../utils/snack";
import { IWholesaler } from "../interfaces";
import { IGeneralQuery } from "../hooks/useQuery";

interface IOtherWholesaler {
  customId: string;
  title: string;
}

interface IWholesalerState {
  details?: IWholesaler;
  status: "idle" | "loading" | "ready" | "error";
  otherWholesalers: IOtherWholesaler[];
}

const initialState: IWholesalerState = {
  details: undefined,
  status: "loading",
  otherWholesalers: [],
};

export const wholesalerWarehouse = createSlice({
  name: "wholesalerWarehouse",
  initialState,
  reducers: {
    detailsLoaded: (state, action: PayloadAction<IWholesaler>) => {
      state.details = action.payload;
      state.status = "ready";
    },
    wholesalersLoaded: (state, action: PayloadAction<IOtherWholesaler[]>) => {
      state.otherWholesalers = action.payload || [];
      state.status = "ready";
    },
    startedLoading: (state) => {
      if (state.status === "idle") {
        state.status = "loading";
      }
    },
    failedToLoad: (state) => {
      state.status = "error";
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  detailsLoaded,
  wholesalersLoaded,
  startedLoading,
  failedToLoad,
} = wholesalerWarehouse.actions;

export const getWholesalers = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  dispatch(startedLoading());
  const res = await api.fetch({
    path: "/wholesalers",
    method: "GET",
    query,
  });

  if (res.ok) {
    dispatch(wholesalersLoaded(res.payload));
  } else {
    dispatch(failedToLoad());
  }
};

export const getWholesaler = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  dispatch(startedLoading());
  const res = await api.fetch({
    path: "/wholesaler",
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(detailsLoaded(res.payload));
  } else {
    dispatch(failedToLoad());
  }
};

export interface IWholesalerPatch {
  title: string;
  maxFPL: number;
  nonSellingDays: string[];

  visibility: "all" | "teammatesOnly";
  fplMode: "companyWide" | "byTeam" | "bySuperTeamlead";
  fplBySuperTeamLead: boolean;
  fplCodes: string;
  gpSlope: number;
  volumeSlope: number;
  fplSlope: number;
  teamleadGpSlope: number;
  teamleadVolumeSlope: number;
  teamleadFplSlope: number;
}

export const patchWholesaler = (
  query: IGeneralQuery,
  body: IWholesalerPatch
): AppThunk => async (_) => {
  snack.info("Saving...");
  const res = await api.fetch({
    path: "/wholesaler",
    method: "PATCH",
    query,
    body,
  });
  if (res.ok) {
    // dispatch(settingsLoaded(res.payload))
    snack.success("Changes saved 😊");
  }
};

export const postWholesaler = (
  query: IGeneralQuery,
  body: { customId: string },
  cb: Function
): AppThunk => async (dispatch) => {
  dispatch(startedLoading());
  snack.info("Creating...");
  const res = await api.fetch({
    path: "/wholesaler",
    method: "POST",
    body,
    query,
  });
  if (res.ok) {
    dispatch(wholesalersLoaded(res.payload));
    snack.success("Wholesaler created 🚀");
    cb();
  }
};

export const deleteWholesaler = (
  query: IGeneralQuery,
  body: { deleteEverything: boolean },
  cb: Function
): AppThunk => async (dispatch) => {
  dispatch(startedLoading());
  snack.info("Deleting...");
  const res = await api.fetch({
    path: "/wholesaler",
    method: "DELETE",
    query,
    body,
  });
  if (res.ok) {
    dispatch(wholesalersLoaded(res.payload));
    snack.success("Wholesaler deleted 🪦💀😭");
    cb();
  }
};

export default wholesalerWarehouse.reducer;
