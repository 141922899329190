import { useEffect, useState } from 'react';
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../store/incentivesStore";

const useArchivedReportIncentives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [archivedSecondaryPageSize, setArchivedSecondaryPageSize] =
		useState(10);

	const {
		archivedIncentives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
	} = incentivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		incentivesStoreZustand.getState().fetchArchivedIncentives({
			wholesalerId,
			page: archivedSecondaryCurrentPage,
			limit: archivedSecondaryPageSize,
			searchPhrase,
			type,
		});
	}, [
		wholesalerId,
		archivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		searchPhrase,
		type,
	]);

	return {
		archivedIncentives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		setArchivedSecondaryPageSize,
		archivedTotalPages: incentivesStoreZustand(
			(state) => state.archivedTotalPages
		),
		loadingArchived: incentivesStoreZustand(
			(state) => state.loadingArchived
		),
		setLoadingArchived: incentivesStoreZustand(
			(state) => state.setLoadingArchived
		),
	};
};

export default useArchivedReportIncentives;
