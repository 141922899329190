import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	modal: {
		position: 'absolute',
		border: '0px solid #FFF',
		left: '30%',
		top: '40%',
		width: '30%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	modalContent: {
		backgroundColor: '#fff',
		padding: '20px',
		borderRadius: '4px',
		boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
		width: '100%',
		maxWidth: '500px',
		maxHeight: '400px',
		overflow: 'scroll',
	},
	modalHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '20px',
	},
	closeButton: {
		cursor: 'pointer',
		border: 'none',
		background: 'none',
		fontSize: '1.25rem',
		fontWeight: 'bold',
	},
	prizeInput: {
		marginBottom: '10px',
	},
	input: {
		width: '100%',
		padding: '10px',
		border: '1px solid #ccc',
		borderRadius: '4px',
	},
	addButton: {
		backgroundColor: '#4CAF50',
		color: '#fff',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#45a049',
		},
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});
