import isEmail from "./isEmail";
import snack from "./snack";

interface ICreds {
  email: string;
  name: string;
  password: string;
  customId: string;
}

const createUserPrompt = (): ICreds | undefined => {
  try {
    const email = prompt("Enter new user email");
    if (!email) {
      throw "";
    }
    if (!isEmail(email)) {
      throw `This email doesn't look valid`;
    }
    const name = prompt("Enter new user name");
    if (!name) {
      throw "";
    }
    if (!/^[a-zA-Z ']{1,30}$/.test(name)) {
      throw `The name should include 1 to 30 characters and spaces`;
    }

    const password = prompt("Enter new user password.", makePassword());
    if (!password) {
      throw "";
    }
    if (!validatePassword(password)) {
      throw `The password should be 6 to 32 characters long`;
    }

    return {
      customId: String(Date.now()),
      email,
      password,
      name,
    };
  } catch (e) {
    if (e) {
      snack.error(String(e));
    }
    return;
  }
};

export default createUserPrompt;

export function makePassword() {
  const makeRandomString = () => Math.random().toString(36).substring(2, 8);
  const password = [
    makeRandomString(),
    makeRandomString(),
    makeRandomString(),
  ].join("-");
  return password;
}

export function validatePassword(str: string) {
  return /^.{6,30}$/.test(str);
}
