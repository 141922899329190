import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	formGroup: {
		display: 'flex',
		flexDirection: 'row',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	label: {
		fontWeight: 'bold',
	},
}));
