import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import api from '../utils/api';

let currentPathname: string | undefined;
let interval: NodeJS.Timeout;
const tickTime = 10000;

const validPaths = [
	'/executiveReport',
	'/leadership',
	'/teamleads',
	'/salesreps',
	'/suppliers',
	'/brands',
	'/userManagement',
	'/products',
	'/customers',
	'/files',
	'/archive',
];

const trackPath = (pathname: string) => {
	if (interval) {
		clearInterval(interval);
	}
	if (!validPaths.includes(pathname)) {
		currentPathname = undefined;
	} else {
		currentPathname = pathname;
		tick();
		interval = setInterval(tick, tickTime);
	}
};

const debounced = debounce(trackPath, tickTime);

async function tick() {
	if (!document.hidden && currentPathname) {
		await api.fetch({
			method: 'POST',
			path: '/tracker',
			body: { page: currentPathname.slice(1, 99), client: 'dashboard' },
			silent: true,
		});
	}
}

export default function useTracker() {
	const { pathname } = useLocation();
	trackPath(pathname);
	if (pathname !== currentPathname) {
		debounced(pathname);
	}
}
