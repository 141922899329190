import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spacingBox: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      minWidth: theme.spacing(1),
      minHeight: theme.spacing(1),
    },
  },
}));

export default function SpacingBoxView() {
  const classes = useStyles();
  return <Box className={classes.spacingBox} />;
}
