import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	spacedFormLabel: {
		marginRight: 10,
	},
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	validationError: {
		color: "red",
		fontSize: "1rem",
	},
	validationObjContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
	},
});
