import { useEffect, useState } from 'react';
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../store/incentivesStore";

const useReportIncentives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const {
		incentives,
		archivedIncentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
	} = incentivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllSubObjectives = () => {
			const { incentives } = incentivesStoreZustand.getState();
			incentives.forEach((incentive) => {
				if (incentive.type === "multi") {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives({ wholesalerId }, incentive._id);
				}
			});
		};

		incentivesStoreZustand.getState().fetchIncentivesReportTable(
			{
				wholesalerId,
				page: secondaryCurrentPage,
				limit: secondaryPageSize,
				searchPhrase,
				type,
			},
			fetchAllSubObjectives
		);
	}, [
		wholesalerId,
		secondaryCurrentPage,
		secondaryPageSize,
		searchPhrase,
		type,
	]);

	return {
		incentives,
		archivedIncentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: incentivesStoreZustand((state) => state.totalPages),
		loading: incentivesStoreZustand((state) => state.loading),
		setLoading: incentivesStoreZustand((state) => state.setLoading),
	};
};

export default useReportIncentives;
