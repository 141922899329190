import React, { useState } from 'react';
import { useStyles } from './AddPrizesModal.styles';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { RankingPrize } from '../../../types/incentivesTypes';

interface AddPrizesModalProps {
	handleClose: () => void;
	customPrizes: RankingPrize[];
	handlePrizesChange: (prizes: RankingPrize[]) => void;
}

export const AddPrizesModal: React.FC<AddPrizesModalProps> = ({
	handleClose,
	customPrizes,
	handlePrizesChange,
}) => {
	const classes = useStyles();

	const [prizes, setPrizes] = useState<RankingPrize[]>(customPrizes || []);

	const addPrize = () => {
		const newRank = `${prizes.length + 1}${getOrdinalSuffix(
			prizes.length + 1
		)}`;
		setPrizes([
			...prizes,
			{
				prizeRank: prizes.length + 1,
				prizeDescription: '',
				prizeName: newRank,
				prizeImage: '',
			},
		]);
	};

	const handleSave = () => {
		handlePrizesChange(prizes); // Call the function passed through props with the current state
		handleClose(); // Optionally close the modal after saving
	};

	const updatePrize = (index: number, description: string) => {
		const updatedPrizes = prizes.map((prize, i) =>
			i === index ? { ...prize, prizeDescription: description } : prize
		);
		setPrizes(updatedPrizes);
	};

	const getOrdinalSuffix = (i: number) => {
		var j = i % 10,
			k = i % 100;
		if (j === 1 && k !== 11) {
			return 'st';
		}
		if (j === 2 && k !== 12) {
			return 'nd';
		}
		if (j === 3 && k !== 13) {
			return 'rd';
		}
		return 'th';
	};

	if (!prizes) return null;
	return (
		<div className={classes.modal}>
			<div className={classes.modalContent}>
				<div className={classes.modalHeader}>
					<h2>Ranking Prizes</h2>
					<button onClick={handleClose} className={classes.closeButton}>
						&times;{' '}
						{/* This is the unicode multiplication sign which looks like 'X' */}
					</button>
				</div>

				{prizes.map((prize, index) => (
					<div key={index} className={classes.prizeInput}>
						<label>Prize {prize.prizeName}</label>
						<input
							type="text"
							value={prize.prizeDescription}
							onChange={(e) => updatePrize(index, e.target.value)}
							className={classes.input}
						/>
					</div>
				))}

				<div className={classes.buttonsWrapper}>
					<Button
						color="primary"
						startIcon={<AddCircleOutlineIcon />}
						onClick={addPrize}
					>
						Add Prize
					</Button>

					<Button color="primary" onClick={handleSave}>
						Save Prizes
					</Button>
				</div>
			</div>
		</div>
	);
};
