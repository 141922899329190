import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import useQuery, { IProductsQuery } from "../../hooks/useQuery";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownIconward from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { usd } from "../../utils/format";

interface IProps {
  productId: string;
  selected?: boolean;
}

const useStyles = makeStyles((theme) => ({
  trendWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginLeft: theme.spacing(),
  },
  rankChangeWrapper: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.text.hint,
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0.5),
  },
  trendUp: {
    backgroundColor: theme.palette.success.light,
  },
  trendDown: {
    backgroundColor: theme.palette.error.light,
  },
  rankChangeText: {
    color: "white",
  },
  rankChangeIcon: {
    color: "white",
  },
  secondaryText: {
    whiteSpace: "break-spaces",
  },
}));

export default function UserListItem(props: IProps) {
  const product = useSelector(
    (state: RootState) => state.products.productsById[props.productId]
  );
  const { query } = useQuery<IProductsQuery>();
  const classes = useStyles();
  const history = useHistory();
  const rankChangeWrapper = [classes.rankChangeWrapper];
  let Icon: typeof ArrowUpwardIcon | undefined;
  switch (product.trend) {
    case "up":
      rankChangeWrapper.push(classes.trendUp);
      Icon = ArrowUpwardIcon;
      break;
    case "down":
      rankChangeWrapper.push(classes.trendDown);
      Icon = ArrowDownIconward;
      break;
  }
  let newSelectedProducts = query.selectedProducts || [];
  if (newSelectedProducts.includes(props.productId)) {
    newSelectedProducts = newSelectedProducts.filter(
      (item) => item !== props.productId
    );
  } else {
    newSelectedProducts.push(props.productId);
  }

  return (
    <ListItem
      selected={props.selected}
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey) {
          history.push({
            pathname: "/products",
            search: `?${qs.stringify({
              ...query,
              selectedProducts: newSelectedProducts,
            })}`,
          });
        } else {
          history.push({
            pathname: "/products",
            search: `?${qs.stringify({
              ...query,
              selectedProducts: [props.productId],
            })}`,
          });
        }
      }}
    >
      <ListItemText
        classes={{
          secondary: classes.secondaryText,
        }}
        primary={`${product.name} (GP/CE: ${usd(product.gpCe.thisYear, 2)})`}
        secondary={`Vol.rank: ${product.volumeRank}; GP rank: ${product.gpRank}; Size: ${product.size}\n${product.supplier} / ${product.brand}`}
      />
      {!!Icon && (
        <Box className={classes.trendWrapper}>
          <Box className={rankChangeWrapper.join(" ")}>
            <Icon fontSize="small" className={classes.rankChangeIcon} />
          </Box>
        </Box>
      )}
    </ListItem>
  );
}
