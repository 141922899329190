import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	ListItemText,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@material-ui/core';
import { MeasureComponent } from './MeasureComponent';
import { MeasureState } from '../../types/objectiveTypes';
import { MeasureSymbol } from '../../types/commonObjIncTypes';
import { useStyles } from './QuantityForm.styles';
import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';

interface QuantityFormProps {
	measures: MeasureState[];
	handleMeasureChange: (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriod?: number,
		excludeTrackingDays?: string[],
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string,
	) => void;
}

export const QuantityForm: React.FC<QuantityFormProps> = ({
	measures,
	handleMeasureChange,
}) => {
	const classes = useStyles();

	const [currentMeasure, setCurrentMeasure] = useState<MeasureState | null>(
		measures.find((measure) => measure.checked) || null
	);
	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.startDate
				? new Date(currentMeasure.startDate)
				: new Date(),
			endDate: currentMeasure?.endDate
				? new Date(currentMeasure.endDate)
				: new Date(),
			key: 'selection',
		},
	]);
	const [trackingPeriodDates, setTrackingPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.trackingPeriodStart
				? new Date(currentMeasure.trackingPeriodStart)
				: new Date(),
			endDate: currentMeasure?.trackingPeriodEnd
				? new Date(currentMeasure.trackingPeriodEnd)
				: new Date(),
			key: 'selection',
		},
	]);
	const [selectedTrackingPeriod, setselectedTrackingPeriod] = useState<number>(
		currentMeasure?.trackingPeriod || 30
	);
	const [datesList, setDatesList] = useState<string[]>([]);
	const [excludedDates, setExcludedDates] = useState<string[]>(
		currentMeasure?.excludeTrackingDays || []
	);
	const [trackingOption, setTrackingOption] = useState<string>(
		currentMeasure?.trackingOption || 'default'
	);
	const [refTrackingPeriod, setRefTrackingPeriod] = useState<string>(
		currentMeasure?.referenceTrackingPeriod
			? currentMeasure.referenceTrackingPeriod
			: moment().format('YYYY-MM-DDTHH:mm:ss')
	);
	useEffect(() => {
		if (periodDates[0].endDate) {
			generateDatesList(periodDates[0].endDate, selectedTrackingPeriod);
		}
	}, [periodDates, selectedTrackingPeriod]);

	const generateDatesList = (endDate: Date, trackingPeriod: number) => {
		const end = new Date(endDate);
		const startDate = new Date(endDate);
		startDate.setDate(end.getDate() - trackingPeriod + 1);

		const dates: string[] = [];
		let currentDate = startDate;
		while (currentDate <= end) {
			dates.push(moment(currentDate).format('YYYY-MM-DDTHH:mm:ss'));
			currentDate.setDate(currentDate.getDate() + 1);
		}

		setDatesList(dates);
	};

	const updateCurrentMeasure = (updatedFields: Partial<MeasureState>) => {
		if (currentMeasure) {
			const updatedMeasure: MeasureState = {
				...currentMeasure,
				...updatedFields,
			};

			setCurrentMeasure(updatedMeasure);

			handleMeasureChange(
				updatedMeasure.label,
				updatedMeasure.startDate,
				updatedMeasure.endDate,
				updatedMeasure.checked,
				updatedMeasure.symbol,
				updatedMeasure.trackingOption,
				updatedMeasure.trackingPeriod,
				updatedMeasure.excludeTrackingDays,
				updatedMeasure.trackingPeriodStart,
				updatedMeasure.trackingPeriodEnd,
				updatedMeasure.referenceTrackingPeriod
			);
		}
	};

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
		const endInGMT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

		updateCurrentMeasure({
			startDate: startInGMT,
			endDate: endInGMT,
			trackingPeriod: selectedTrackingPeriod,
			excludeTrackingDays: excludedDates,
		});
	};

	const handleTrackingPeriodChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		const newTrackingPeriod = event.target.value as number;
		setselectedTrackingPeriod(newTrackingPeriod);

		updateCurrentMeasure({
			trackingPeriod: newTrackingPeriod,
			excludeTrackingDays: excludedDates,
		});
	};

	const handleTrackingPeriodCalendarChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setTrackingPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
		const endInGMT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

		updateCurrentMeasure({
			trackingPeriodStart: startInGMT,
			trackingPeriodEnd: endInGMT,
		});
	};

	const handleMeasureComponentChange = (
		label?: string,
		checked?: boolean,
		symbol?: MeasureSymbol
	) => {
		const measure = measures.find((m) => m.label === label);
		if (measure) {
			measure.checked = checked;
			measure.symbol = symbol;
			updateCurrentMeasure({
				label,
				checked,
				symbol,
			});
		}
	};

	const handleExcludedDatesChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		const value = event.target.value as string[];
		setExcludedDates(value);

		updateCurrentMeasure({
			excludeTrackingDays: value,
		});
	};

	const formatForDisplay = (date: string) => {
		return moment(date).format('YYYY-MM-DD');
	};

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newTrackingOption = event.target.value;
		setTrackingOption(newTrackingOption);

		updateCurrentMeasure({
			trackingOption: newTrackingOption,
		});
	};

	const handleRefTrackingPeriodChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newDate = event.target.value;
		setRefTrackingPeriod(newDate);
		const startInGMT = moment(newDate).format('YYYY-MM-DDTHH:mm:ss');

		updateCurrentMeasure({
			referenceTrackingPeriod: startInGMT,
		});
	};

	return (
		<>
			<FormLabel className={classes.formLabel} required>
				Measure:{' '}
			</FormLabel>
			<div className={classes.mainContainer}>
				<div className={classes.container}>
					<FormControl component="fieldset" className={classes.formControl}>
						<Paper className={classes.measureContainer}>
							{measures.map((measure) => (
								<MeasureComponent
									key={measure.label}
									label={measure.label}
									checked={measure.checked}
									symbol={measure.symbol}
									onMeasureChange={handleMeasureComponentChange}
								/>
							))}
						</Paper>
					</FormControl>
					{currentMeasure?.label?.includes('Δ') && (
						<div className={classes.period}>
							<div>
								<FormLabel component="legend">Reference Period</FormLabel>
								<DateRange
									editableDateInputs={true}
									onChange={(item) => {
										handlePeriodChange(item);
									}}
									moveRangeOnFirstSelection={false}
									ranges={periodDates}
								/>
							</div>
						</div>
					)}
				</div>
				{currentMeasure?.label?.includes('Δ') && (
					<div className={classes.options}>
						<FormControl component="fieldset" className={classes.radioGroup}>
							<RadioGroup value={trackingOption} onChange={handleOptionChange}>
								<FormControlLabel
									value="default"
									control={<Radio />}
									label="Default"
								/>
								<FormControlLabel
									value="customPeriod"
									control={<Radio />}
									label="Custom Period"
								/>
								<FormControlLabel
									value="trackingFromDate"
									control={<Radio />}
									label="Tracking from Date"
								/>
							</RadioGroup>
						</FormControl>
						{trackingOption === 'default' && (
							<div className={classes.option}>
								<div>
									<FormControl className={classes.formControl}>
										<InputLabel>Select Tracking Period</InputLabel>
										<Select
											className={classes.excludeSelect}
											value={selectedTrackingPeriod}
											onChange={handleTrackingPeriodChange}
										>
											<MenuItem value={30}>30 days</MenuItem>
											<MenuItem value={60}>60 days</MenuItem>
											<MenuItem value={90}>90 days</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div>
									<FormControl className={classes.formControl}>
										<InputLabel>Select Dates to Exclude</InputLabel>
										<Select
											multiple
											value={excludedDates}
											onChange={handleExcludedDatesChange}
											renderValue={(selected) =>
												(selected as string[]).map(formatForDisplay).join(', ')
											}
											className={classes.excludeSelect}
										>
											{datesList.map((date) => (
												<MenuItem key={date} value={date}>
													<Checkbox
														checked={excludedDates.indexOf(date) > -1}
													/>
													<ListItemText primary={formatForDisplay(date)} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
						)}
						{trackingOption === 'customPeriod' && (
							<div className={classes.option}>
								<div className={classes.period}>
									<div>
										<FormLabel component="legend">Tracking Period</FormLabel>
										<DateRange
											editableDateInputs={true}
											onChange={(item) => {
												handleTrackingPeriodCalendarChange(item);
											}}
											moveRangeOnFirstSelection={false}
											ranges={trackingPeriodDates}
										/>
									</div>
								</div>
							</div>
						)}
						{trackingOption === 'trackingFromDate' && (
							<div className={classes.option}>
								<div>
									<FormControl className={classes.formControl}>
										<TextField
											id="date"
											label="Select date"
											type="date"
											value={formatForDisplay(refTrackingPeriod)}
											onChange={handleRefTrackingPeriodChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
								</div>
								<div>
									<FormControl className={classes.formControl}>
										<InputLabel>Select Tracking Period</InputLabel>
										<Select
											className={classes.excludeSelect}
											value={selectedTrackingPeriod}
											onChange={handleTrackingPeriodChange}
										>
											<MenuItem value={30}>30 days</MenuItem>
											<MenuItem value={60}>60 days</MenuItem>
											<MenuItem value={90}>90 days</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};
