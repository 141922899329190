import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { usd, volume, delta } from "../../utils/format";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getBuyer } from "./buyersSlice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import UserListItem from "../usersList/UserListItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";

interface IProps {
  buyerId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {},
  table: {
    "& td, th": {
      paddingRight: 8,
      paddingLeft: 0,
    },
    "& tbody td:nth-child(1)": {
      paddingLeft: 16,
    },
  },

  title: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  accordionDetails: {
    flexDirection: "column",
    padding: 0,
  },
  accordion: {
    boxShadow: "none",
  },
}));

export default function BuyerDetails(props: IProps) {
  const classes = useStyles();
  const buyer = useSelector(
    (state: RootState) => state.buyers.buyersById[props.buyerId]
  );
  const isReady = useSelector(
    (state: RootState) => state.buyers.status === "ready"
  );
  const dispatch = useDispatch();
  const noBuyer = !buyer;
  const { query } = useQuery<IBuyersQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    if (isReady && noBuyer) {
      dispatch(getBuyer(props.buyerId, { wholesalerId, db }));
    }
  }, [isReady, noBuyer, dispatch, props.buyerId, wholesalerId, db]);
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box className={classes.title}>
          <Typography variant="h5">{buyer?.name || <Skeleton />}</Typography>
          <Typography variant="caption">
            {buyer?.accountType ? (
              `Account type: ${buyer.accountType}`
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">90d TY</TableCell>
                <TableCell align="center">90d LY</TableCell>
                <TableCell align="center">Difference</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell variant="head">CE:</TableCell>
                <TableCell align="center">
                  {buyer?.volume ? volume(buyer.volume.thisYear) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.volume ? volume(buyer.volume.lastYear) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.volume ? delta(buyer.volume.diff, 0) : <Skeleton />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">GP:</TableCell>
                <TableCell align="center">
                  {buyer?.gp ? usd(buyer.gp.thisYear) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.gp ? usd(buyer.gp.lastYear) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.gp ? delta(buyer.gp.diff, 0) : <Skeleton />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">GP/CE:</TableCell>
                <TableCell align="center">
                  {buyer?.gp_ce ? usd(buyer.gp_ce.thisYear, 2) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.gp_ce ? usd(buyer.gp_ce.lastYear, 2) : <Skeleton />}
                </TableCell>
                <TableCell align="center">
                  {buyer?.gp_ce ? delta(buyer.gp_ce.diff, 0) : <Skeleton />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!!buyer?.salesRep?.customId && (
          <UserListItem
            userId={buyer?.salesRep?.customId}
            selected={false}
            onItemClick="navigate"
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
