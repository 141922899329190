import {
	TableRow,
	TableCell,
	IconButton,
	Link,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from "@material-ui/core";

import { capitalizeFirstLetter } from "../utils";
import { Podcast } from "./podcastLinksStore";
import { useState } from "react";
import { useStyles } from "./PodcastsComponent.styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

interface PodcastComponentProps {
	podcast: Podcast;
	onEdit: (id: string, editedPodcast: Podcast) => void;
	onDelete: (id: string) => void;
}
export const PodcastComponent: React.FC<PodcastComponentProps> = ({
	podcast,
	onEdit,
	onDelete,
}) => {
	const classes = useStyles();

	const [editing, setEditing] = useState<boolean>(false);
	const [editedPodcast, setEditedPodcast] = useState<Podcast>(podcast);
	const [name, setName] = useState<string>(editedPodcast.name);
	const [url, setUrl] = useState<string>(editedPodcast.url);

	const handleEdit = () => {
		setEditing(true);
	};
	const handleClose = () => {
		setEditing(false);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		field: string
	) => {
		const { value } = e.target;
		if (field === "name") {
			setEditedPodcast((prev) => ({
				...prev,
				name: value,
			}));
		} else if (field === "url") {
			setEditedPodcast((prev) => ({
				...prev,
				url: value,
			}));
		}
	};

	const handleSave = () => {
		setEditing(false);
		setName(editedPodcast.name);
		setUrl(editedPodcast.url);
		onEdit(podcast._id, editedPodcast);
	};

	const handleDelete = () => {
		onDelete(podcast._id);
	};

	return (
		<>
			<TableRow key={podcast._id} className={classes.customRow}>
				<TableCell
					className={`${classes.customRow} ${classes.customRowName}`}
				>
					{capitalizeFirstLetter(name)}
				</TableCell>
				<TableCell
					className={`${classes.customRow} ${classes.customRowLink}`}
				>
					<Link
						href={podcast.url}
						rel="noopener noreferrer"
						target="_blank"
					>
						{url}
					</Link>
				</TableCell>
				<TableCell
					className={`${classes.customRow} ${classes.actionsButtons}`}
				>
					<IconButton
						size="small"
						color="primary"
						onClick={handleEdit}
					>
						<EditIcon />
					</IconButton>
					<IconButton size="small" onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>

			<Dialog
				open={editing}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Podcast</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						type="text"
						fullWidth
						name="name"
						value={editedPodcast.name}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "name")}
					/>
					<TextField
						margin="dense"
						id="url"
						label="URL"
						type="text"
						fullWidth
						name="url"
						value={editedPodcast.url}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "url")}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
