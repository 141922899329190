import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../warehouse/usersWarehouse";
import { getWholesaler } from "../../warehouse/wholesalerWarehouse";
import { RootState } from "../../app/store";
import TeamLeadSkeleton from "./TeamLeadSkeleton";
import UsersView from "./UsersView";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";

export default function Leadership() {
  const dispatch = useDispatch();
  const { query } = useQuery<IGeneralQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }, { silent: true }));
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const usersLoaded = useSelector(
    (state: RootState) => state.usersWarehouse.status === "ready"
  );
  const usersIds = useSelector((state: RootState) => {
    return Object.keys(state.usersWarehouse.usersById)
      .map((userId) => state.usersWarehouse.usersById[userId])
      .filter((user) => {
        if (user.role !== "teamLead" || !user.leadership) {
          return false;
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        if (a.gp.achieved > b.gp.achieved) {
          return -1;
        }
        if (a.gp.achieved < b.gp.achieved) {
          return 1;
        }
        return 0;
      })
      .map((user) => user.customId);
  });
  if (!usersLoaded) {
    return <TeamLeadSkeleton />;
  }

  return <UsersView usersIds={usersIds} />;
}
