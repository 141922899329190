import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import { getBuyer } from "./buyersSlice";
import BuyerRecommendations from "./BuyerRecommendations";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import BuyerSummary from "./BuyerSummary";
import BuyerRecFiltersModal from "./BuyerRecFiltersModal";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import Scroller from "../scroller/Scroller";

interface IProps {
  buyerId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 375,
    width: 375,
    minWidth: 375,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tab: {
    minWidth: 100,
    flex: 1,
  },
  iconButton: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    position: "relative",
  },
  paper: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey.A100,
    borderBottomStyle: "solid",
  },
  filtersCountWrapper: {
    position: "absolute",
    right: 0,
    bottom: 4,
    borderRadius: "50%",
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  filtersCount: {
    color: "white",
  },
}));

export default function BuyerDetails(props: IProps) {
  const [isFiltersModalOpen, setFiltersModalOpen] = React.useState(false);
  const classes = useStyles();
  const buyer = useSelector(
    (state: RootState) => state.buyers.buyersById[props.buyerId]
  );
  const isReady = useSelector(
    (state: RootState) => state.buyers.status === "ready"
  );
  const dispatch = useDispatch();
  const noBuyer = !buyer;
  const { query } = useQuery<IBuyersQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    if (isReady && noBuyer) {
      dispatch(getBuyer(props.buyerId, { wholesalerId, db }));
    }
  }, [isReady, noBuyer, dispatch, props.buyerId, wholesalerId, db]);
  const [tab, setTab] = React.useState(0);
  const filtersCount = [
    query.brand,
    query.gpRank,
    query.packageType,
    query.size,
    query.supplier,
    query.tags,
    query.volumeRank,
  ].filter(Boolean).length;

  return (
    <Scroller>
      <Box className={classes.container}>
        <Paper className={classes.paper}>
          <BuyerSummary buyerId={props.buyerId} />
          <Tabs
            value={tab}
            onChange={(_, val) => setTab(val)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab className={classes.tab} label="Sold" />
            <Tab className={classes.tab} label="Sell" />
            <Tab className={classes.tab} label="Remove" />
            <IconButton
              className={classes.iconButton}
              onClick={() => setFiltersModalOpen(true)}
            >
              <FilterListIcon />
              {!!filtersCount && (
                <Paper className={classes.filtersCountWrapper}>
                  <Typography
                    variant="caption"
                    className={classes.filtersCount}
                  >
                    {filtersCount}
                  </Typography>
                </Paper>
              )}
            </IconButton>
          </Tabs>
        </Paper>
        <BuyerRecommendations tab={tab} buyerId={props.buyerId} />
      </Box>
      <BuyerRecFiltersModal
        isOpen={isFiltersModalOpen}
        onClose={() => setFiltersModalOpen(false)}
      />
    </Scroller>
  );
}
