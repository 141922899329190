import React from "react";
import Paper from "@material-ui/core/Paper";
import { getUsers } from "../../warehouse/usersWarehouse";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import List from "@material-ui/core/List";
import BuyerListItem from "./BuyerListItem";
import { makeStyles } from "@material-ui/core/styles";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import Box from "@material-ui/core/Box";
import Scroller from "../scroller/Scroller";
import Pagination from "@material-ui/lab/Pagination";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  contentScroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    flex: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  pager: {
    padding: theme.spacing(1),
    margin: "auto",
  },
  container: {
    minWidth: 375,
    width: 375,
    maxWidth: 375,
  },
}));

export default function BuyersList() {
  const classes = useStyles();
  const buyersIds = useSelector((state: RootState) => state.buyers.buyersList);
  const count = useSelector((state: RootState) => state.buyers.buyersCount);
  const isLoading = useSelector(
    (state: RootState) => state.buyers.status === "loading"
  );
  const dispatch = useDispatch();
  const { query, setQuery } = useQuery<IBuyersQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  return (
    <Paper className={classes.container}>
      <Scroller>
        <Box className={classes.contentScroll}>
          <List className={classes.list} dense>
            {buyersIds.map((buyerId) => (
              <BuyerListItem
                key={buyerId}
                buyerId={buyerId}
                selected={buyerId === query.selectedBuyer}
              />
            ))}
          </List>
        </Box>
        {isLoading ? <LinearProgress /> : <Divider />}
        <Pagination
          page={Number(query.page) || 1}
          onChange={(_, value) => setQuery({ page: String(value) })}
          className={classes.pager}
          count={count}
        />
      </Scroller>
    </Paper>
  );
}
