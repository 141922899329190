import React from "react";
import lottie from "lottie-web";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "80vh",
    flex: 1,
    alignSelf: "center",
    position: "relative",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(2),
  },
  text: {
    textAlign: "center",
  },
}));

export default function BuyersNoRecommendations() {
  const classes = useStyles();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("emptyPage")!, // the dom element that will contain the animation
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "emptyPage.json", // the path to the animation json
    });
  }, []);
  return (
    <Paper id="emptyPage" className={classes.container}>
      <Typography variant="h5" className={classes.text}>
        Nothing to recommend
      </Typography>
    </Paper>
  );
}
