import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import React, { useEffect } from "react";
import Scroller from "../scroller/Scroller";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  deleteUser,
  getUsers,
  patchUsers,
  postUser,
} from "../../warehouse/usersWarehouse";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import createUserPrompt, { makePassword } from "../../utils/createUserPrompt";
import WholesalerManagersSkeleton from "./WholesalerManagersSkeleton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 375,
    minWidth: 375,
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
  },
  title: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subTitle: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
}));

export default function WholesalerManagers() {
  const classes = useStyles();
  const { query } = useQuery<IGeneralQuery>();
  const managers = useSelector((state: RootState) => {
    return Object.keys(state.usersWarehouse.usersById)
      .filter(
        (userId) => state.usersWarehouse.usersById[userId].role === "manager"
      )
      .map((userId) => state.usersWarehouse.usersById[userId]);
  });

  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  useEffect(() => {
    dispatch(getUsers({ db, wholesalerId }));
  }, [dispatch, wholesalerId, db]);
  if (managers[0]?.wholesaler.customId !== wholesalerId) {
    return <WholesalerManagersSkeleton />;
  }
  const addManager = () => {
    const creds = createUserPrompt();
    if (creds) {
      dispatch(
        postUser(
          { db, wholesalerId },
          {
            role: "manager",
            ...creds,
          }
        )
      );
    }
  };
  return (
    <Paper className={classes.container}>
      <Box className={classes.title}>
        <Typography variant="h5">Managers</Typography>
        <Button size="small" onClick={addManager} variant="outlined">
          Add
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box className={classes.scroll}>
          <List className={classes.container} dense>
            {managers.map((manager) => (
              <ListItem key={manager.customId}>
                <ListItemText
                  primary={manager.name}
                  secondary={manager.email}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={"Delete user"}>
                    <IconButton
                      onClick={() => {
                        if (
                          window.confirm(
                            `Delete ${manager.name} (${manager.email})?`
                          )
                        ) {
                          dispatch(
                            deleteUser({
                              db,
                              wholesalerId,
                              customId: manager.customId,
                            })
                          );
                        }
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Change password"}>
                    <IconButton
                      onClick={() => {
                        const password = prompt(
                          "Enter new user password.",
                          makePassword()
                        );
                        if (password) {
                          dispatch(
                            patchUsers(
                              {
                                customIds: [manager.customId],
                                wholesalerId: query.wholesalerId,
                                db: query.db,
                              },
                              {
                                patch: { password },
                              }
                            )
                          );
                        }
                      }}
                      edge="end"
                      aria-label="change password"
                    >
                      <LockIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Scroller>
    </Paper>
  );
}
