import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Scroller from "../scroller/Scroller";
import SpacingBoxView from "../spacingBox/SpacingBoxView";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 375,
    minWidth: 375,
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
  },
  title: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subTitle: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  textFieldSkeleton: {
    height: 40,
    width: "100%",
  },
  multilineTextFieldSkeleton: {
    height: 140,
    width: "100%",
  },
}));

export default function WholesalerEditSkeleton() {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <Box className={classes.title}>
        <Typography variant="h5">Settings</Typography>
        <Button size="small" disabled variant="outlined">
          Save
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box className={classes.scroll}>
          <Box padding={2}>
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.multilineTextFieldSkeleton} />
          </Box>
          <Box className={classes.subTitle}>
            <Typography variant="h5">Sales Reps</Typography>
          </Box>
          <Box padding={2}>
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
          </Box>
          <Box className={classes.subTitle}>
            <Typography variant="h5">Team Leads</Typography>
          </Box>
          <Box padding={2}>
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
            <SpacingBoxView />
            <Skeleton className={classes.textFieldSkeleton} />
          </Box>
        </Box>
      </Scroller>
    </Paper>
  );
}
