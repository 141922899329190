import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { usd, volume } from "../utils/format";
import { useTheme } from "@material-ui/core/styles";

interface IProps {
  userId: string;
}

interface IResult {
  gp: {
    text: string;
    color?: string;
  };
  volume: {
    text: string;
    color?: string;
  };
  daysLeft: number;
}

export default function useGap(props: IProps) {
  const user = useSelector(
		(state: RootState) => state.usersWarehouse.usersById[props.userId]
	);
  const sellingDaysLeft = useSelector(
    (state: RootState) =>
      state.wholesalerWarehouse.details?.thisYearSellingDaysLeft || 0
  );
  const theme = useTheme();
  let result: IResult = {
    gp: {
      text: "",
    },
    volume: {
      text: "",
    },
    daysLeft: sellingDaysLeft,
  };
  if (!user) {
    return result;
  }

  const gpTarget = (user.gp.goal * user.gp.lastYear) / 100;
  if (gpTarget <= user.gp.thisYear) {
    result.gp.text = "Achieved";
    result.gp.color = theme.palette.success.dark;
  } else if (!sellingDaysLeft) {
    result.gp.text = "Not achieved";
    result.gp.color = theme.palette.error.dark;
  } else if (user.gp.lastYear) {
    result.gp.text = usd((gpTarget - user.gp.thisYear) / sellingDaysLeft);
  } else {
    result.gp.text = "Achieved";
    result.gp.color = theme.palette.success.dark;
  }

  const volumeTarget = (user.volume.goal * user.volume.lastYear) / 100;

  if (volumeTarget <= user.volume.thisYear) {
    result.volume.text = "Achieved";
    result.volume.color = theme.palette.success.dark;
  } else if (!sellingDaysLeft) {
    result.volume.text = "Not achieved";
    result.volume.color = theme.palette.error.dark;
  } else if (user.volume.lastYear) {
    result.volume.text = volume(
      (volumeTarget - user.volume.thisYear) / sellingDaysLeft
    );
  } else {
    result.volume.text = "Achieved";
    result.volume.color = theme.palette.success.dark;
  }

  return result;
}
