import { IUser } from '../../interfaces';
import { delta, usd } from '../../utils/format';

export const transformToSummaryRow = (user: IUser) => {
	return [
		{
			content: user.role,
			value: user.role,
		},
		{
			content: user.routeNumber.join(', '),
			value: user.routeNumber.join(', '),
		},
		{
			content: user.name,
			value: user.name,
		},
		{
			content: user.teamLead.map((lead) => lead?.name).join(', '),
			value: user.teamLead,
		},
		{
			content: user.email,
			value: user.email,
		},
		{
			content: delta(user.gp.goal),
			value: user.gp.goal,
		},
		{
			content: delta(user.volume.goal),
			value: user.volume.goal,
		},
		{
			content: usd(user.gp.totalPayout),
			value: user.gp.totalPayout,
		},
		{
			content: delta(user.gp.threshold),
			value: user.gp.threshold,
		},
		{
			content: usd(user.volume.totalPayout),
			value: user.volume.totalPayout,
		},
		{
			content: delta(user.volume.threshold),
			value: user.volume.threshold,
		},
		{
			content: usd(user.fpl.payout),
			value: user.fpl.payout,
		},
		{
			content: usd(user.fpl.goal),
			value: user.fpl.goal,
		},
		{
			content: user.wholesaler.maxFPL,
			value: user.wholesaler.maxFPL,
		},
	];
};

export const mapTransformedDataToUser = (
	transformedData: {
		content: string;
		value: string | number | { name: string; customId: string }[];
	}[],
	originalUser: IUser
) => {
	const userCopy = { ...originalUser };

	// Extract name
	userCopy.name = transformedData[2].value as string;

	// Extract teamLead
	userCopy.teamLead = transformedData[3].value as {
		name: string;
		customId: string;
	}[];

	// Extract email
	userCopy.email = transformedData[4].value as string;

	// Extract gp fields
	userCopy.gp.goal = parseInt(transformedData[5].value as string);
	userCopy.gp.totalPayout = parseInt(transformedData[7].value as string);
	userCopy.gp.threshold = parseInt(transformedData[8].value as string);

	// Extract volume fields
	userCopy.volume.goal = parseInt(transformedData[6].value as string);
	userCopy.volume.totalPayout = parseInt(transformedData[9].value as string);
	userCopy.volume.threshold = parseInt(transformedData[10].value as string);

	// Extract fpl fields
	userCopy.fpl.payout = parseInt(transformedData[11].value as string);
	userCopy.fpl.goal = parseInt(transformedData[12].value as string);

	// Extract wholesaler field
	userCopy.wholesaler.maxFPL = parseInt(transformedData[13].value as string);

	return userCopy;
};

export const transformToDTO = (original: IUser) => {
	return {
		name: original.name,
		email: original.email,
		routeNumber: original.routeNumber || [],
		totalPayout: original.totalPayout,
		gp: {
			lastYear: original.gp.lastYear,
			thisYear: original.gp.thisYear,
			achieved: original.gp.achieved,
			achievedSellingDays: original.gp.achievedSellingDays,
			goal: original.gp.goal,
			payout: original.gp.payout,
			threshold: original.gp.threshold,
			bonus: original.gp.bonus || 0,
			totalPayout: original.gp.totalPayout,
		},
		volume: {
			lastYear: original.volume.lastYear,
			thisYear: original.volume.thisYear,
			achieved: original.volume.achieved,
			achievedSellingDays: original.volume.achievedSellingDays,
			goal: original.volume.goal,
			payout: original.volume.payout,
			threshold: original.volume.threshold,
			bonus: original.volume?.bonus || 0,
			totalPayout: original.volume.totalPayout,
		},
		fpl: {
			individual: original.fpl.individual || 0,
			team: original.fpl.team || 0,
			goal: original.fpl.goal || 0,
			payout: original.fpl.payout || 0,
			totalPayout: original.fpl.totalPayout || 0,
		},
		wholesaler: {
			maxFPL: original.wholesaler.maxFPL || 0,
		},
		teamLead:
			original.teamLead && original.teamLead.length > 0
				? original.teamLead.map((lead) => ({
						customId: lead.customId,
						name: lead.name,
				  }))
				: [],
	};
};

export const userColumns = [
	'User Type',
	'Route',
	'Name',
	'Team',
	'Email',
	'GP Goal',
	'Vol Goal',
	'GP Payout',
	'GP TH',
	'Vol Payout',
	'Vol TH',
	'FPL Payout',
	'FPL target',
	'FPL max',
];

export const capitalizeFirstLetter = (str: string): string => {
	if (!str) return '';
	if (str.length === 0) return str;
	return str.charAt(0).toUpperCase() + str.slice(1);
};
