import React, { useState } from 'react';
import { ArchiveObjectiveComponent } from './ArchiveObjectiveComponent';
import { ArchiveIncentiveComponent } from './ArchiveIncentiveComponent';
import { ButtonGroup, Button } from '@material-ui/core';
import { useStyles } from './ArchiveComponent.styles';

export const ArchiveComponent: React.FC = () => {
	const classes = useStyles();
	const [activeTab, setActiveTab] = useState<'objective' | 'incentive'>(
		'objective'
	);

	return (
		<div className={classes.container}>
			<ButtonGroup color="primary" aria-label="outlined primary button group">
				<Button
					onClick={() => setActiveTab('objective')}
					variant={activeTab === 'objective' ? 'contained' : 'outlined'}
				>
					Objectives
				</Button>
				<Button
					onClick={() => setActiveTab('incentive')}
					variant={activeTab === 'incentive' ? 'contained' : 'outlined'}
				>
					Incentives
				</Button>
			</ButtonGroup>
			<div className={classes.tableWrapper}>
				{activeTab === 'objective' ? (
					<ArchiveObjectiveComponent />
				) : (
					<ArchiveIncentiveComponent />
				)}
			</div>
		</div>
	);
};
