import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

interface IProps<T> {
  value: T;
  onClick: (value: T) => void;
  buttons: {
    label: string;
    value: T;
  }[];
}
export default function ButtonGroupView<Type>(props: IProps<Type>) {
  const { value, buttons, onClick } = props;
  return (
    <ButtonGroup color="primary" disableElevation>
      {buttons.map((button, index) => (
        <Button
          size="small"
          key={index}
          onClick={() => onClick(button.value)}
          variant={value === button.value ? "contained" : "outlined"}
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

interface IMultiProps<T> {
  values: T[];
  onClick: (value: T) => void;
  buttons: {
    label: string;
    value: T;
  }[];
  revert?: boolean;
}

export function ButtonGroupMultiselect<Type>(props: IMultiProps<Type>) {
  const { values, buttons, onClick, revert } = props;
  const activeVariant = revert ? "outlined" : "contained";
  const inactiveVariant = revert ? "contained" : "outlined";
  return (
    <ButtonGroup color="primary" disableElevation>
      {buttons.map((button, index) => (
        <Button
          size="small"
          key={index}
          onClick={() => onClick(button.value)}
          variant={
            values.includes(button.value) ? activeVariant : inactiveVariant
          }
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
