import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { smallCellSize } from "./FilesRow";

interface IProps {
  isArchive?: boolean;
}

function Row(props: IProps) {
  return (
    <TableRow>
      <TableCell width={smallCellSize}>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      {!props.isArchive && (
        <TableCell>
          <Skeleton />
        </TableCell>
      )}
      <TableCell width={smallCellSize}>
        <Skeleton />
      </TableCell>
    </TableRow>
  );
}

export default function FilesSkeleton(props: IProps) {
  const { isArchive } = props;
  return (
    <>
      {Array(5)
        .fill(undefined)
        .map((_, index) => (
          <Row key={"FilesSkeleton" + index} isArchive={isArchive} />
        ))}
    </>
  );
}
