import React from 'react';
import { RootState } from '../../app/store';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Scroller from '../scroller/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import {
	getTrackers,
	IPeriod,
	makeTrackerKey,
	periods,
} from './analyticsSlice';
import { analyticsSchema, IAnalyticsQuery, clients } from './AnalyticsQuery';
import useQuery from '../../hooks/useQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import upperFirst from 'lodash/upperFirst';
import colorsArray from '../../utils/colorsArray';
import Divider from '@material-ui/core/Divider';
import AnalyticsChartEmpty from './AnalyticsChartEmpty';
import uniq from 'lodash/uniq';
import set from 'lodash/set';
import SpacingBox from '../spacingBox/SpacingBoxView';
import { SingleSelect } from '../select/Select';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts';
import { Box } from '@material-ui/core';
import moment from 'moment';
import invert from 'lodash/invert';

const defaultChartSize = 500;

const chartWidth = defaultChartSize;
const chartHeight = defaultChartSize * 0.75;

const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: 850,
	},
	tab: {
		flex: 1,
		minWidth: 100,
	},
	progressContainer: {
		height: chartHeight,
		width: chartWidth,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	period: {
		alignSelf: 'center',
	},
	header: {
		alignSelf: 'center',
		paddingTop: theme.spacing(2),
	},
	chartsWrapper: {
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		'-ms-overflow-style': 'none',
		'scrollbar-width': 'none',
		overflowY: 'auto',
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

const format = 'MMM Do';
const periodsToLabels = {
	thisWeek: `This Week (${moment().startOf('week').format(format)} - now)`,
	lastWeek: `Last Week (${moment()
		.subtract(1, 'week')
		.startOf('week')
		.format(format)} - ${moment()
		.subtract(1, 'week')
		.endOf('week')
		.format(format)})`,
	thisMonth: `This Month (${moment().startOf('month').format(format)} - now)`,
	lastMonth: `Last Month (${moment()
		.subtract(1, 'month')
		.startOf('month')
		.format(format)} - ${moment()
		.subtract(1, 'month')
		.endOf('month')
		.format(format)})`,
};
// @ts-ignore
const labelsToPeriods: { [label: string]: IPeriod } = invert(periodsToLabels);

export default function AnalyticsChart() {
	const { query, setQuery } = useQuery<IAnalyticsQuery>(analyticsSchema);
	const classes = useStyles();
	const dispatch = useDispatch();

	const trackerKey = makeTrackerKey(query);

	const isReady = useSelector(
		(state: RootState) => state.analytics.status === 'ready'
	);
	const { totalMinutes, pages, perPageData, perUserData, users } = useSelector(
		(state: RootState) => {
			const perPage = state.analytics.trackersByKey[trackerKey]?.perPage;
			const perUser = state.analytics.trackersByKey[trackerKey]?.perUser;
			const usersById = state.usersWarehouse.usersById;
			const usersReady = state.usersWarehouse.status === 'ready';
			if (!perPage || !perUser || !usersReady) {
				return {};
			}
			let totalMinutes = 0;
			const pages: string[] = perPage && uniq(perPage.map((item) => item.page));
			const perPageMap: { [day: string]: { [pageName: string]: number } } = {};
			for (let i = 0; i < perPage.length; i++) {
				const item = perPage[i];
				totalMinutes += item.minutes;
				set(perPageMap, `${item.day}.${item.page}`, item.minutes);
			}
			const perPageData = Object.keys(perPageMap).map((day) => ({
				day,
				...perPageMap[day],
			}));

			const users: string[] =
				perUser &&
				uniq(perUser.map((item) => usersById[item.userId]?.name)).filter(
					Boolean
				);
			const perUserMap: { [day: string]: { [userName: string]: number } } = {};
			for (let i = 0; i < perUser.length; i++) {
				const item = perUser[i];
				const userName = usersById[item.userId]?.name;
				if (userName) {
					set(perUserMap, `${item.day}.${userName}`, item.minutes);
				}
			}
			const perUserData = Object.keys(perUserMap).map((day) => ({
				day,
				...perUserMap[day],
			}));

			return { pages, perPageData, perUserData, users, totalMinutes };
		}
	);

	const { period, group, client, wholesalerId } = query;
	const selectedUsers = query.users;
	React.useEffect(() => {
		dispatch(
			getTrackers({
				users: selectedUsers,
				period,
				group,
				client,
				wholesalerId,
			})
		);
	}, [dispatch, selectedUsers?.length, period, group, client, wholesalerId]);

	return (
		<Paper className={classes.container}>
			<Scroller>
				<Tabs
					variant="fullWidth"
					value={query.client}
					onChange={(_, val) => setQuery({ client: val })}
					indicatorColor="primary"
					textColor="primary"
				>
					{clients.map((item) => (
						<Tab
							key={item}
							className={classes.tab}
							label={upperFirst(item)}
							value={item}
						/>
					))}
				</Tabs>
				<Divider />
				<Box
					padding={2}
					justifyContent="center"
					display="flex"
					alignItems="center"
					flexDirection="row"
				>
					<SingleSelect
						label="Period"
						options={periods.map((item) => periodsToLabels[item])}
						defaultValue={periodsToLabels[query.period]}
						onSelect={(val) => {
							setQuery({ period: labelsToPeriods[val!] });
						}}
						noDefault
					/>
					<SpacingBox />
					<Typography variant="button">
						Total minutes: {totalMinutes}
					</Typography>
					<SpacingBox />
					<Typography variant="button">
						Active users: {users?.length}
					</Typography>
				</Box>
				<Divider />
				<div className={classes.chartsWrapper}>
					<div className={classes.header}>
						<Typography variant="h5">Minutes spent per page</Typography>
					</div>
					{!perPageData && !perUserData && (
						<div className={classes.progressContainer}>
							{isReady ? <AnalyticsChartEmpty /> : <CircularProgress />}
						</div>
					)}
					{!!perPageData && (
						<BarChart
							width={850}
							height={300}
							data={perPageData}
							margin={{
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="day" />
							<YAxis />
							<Tooltip />
							<Legend />
							{pages?.map((key, index) => (
								<Bar
									key={key}
									dataKey={key}
									stackId={'minutes'}
									fill={colorsArray[index]['300']}
								/>
							))}
						</BarChart>
					)}
					<div className={classes.header}>
						<Typography variant="h5">Minutes spent per user</Typography>
					</div>
					{!!perUserData && (
						<BarChart
							width={850}
							height={500}
							data={perUserData}
							margin={{
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="day" />
							<YAxis />
							<Tooltip />
							<Legend />
							{users?.map((key, index) => (
								<Bar
									key={key}
									dataKey={key}
									stackId={'minutes'}
									fill={colorsArray[index]['300']}
								/>
							))}
						</BarChart>
					)}
				</div>
			</Scroller>
		</Paper>
	);
}
