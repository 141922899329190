import React, { useState } from "react";
import { Objective } from "../types/objectiveTypes";
import { useStyles } from "./ArchiveComponent.styles";
import { Item } from "../types/commonObjIncTypes";
import ItemComponent from "../common/ItemComponent";
import useHandleObjectiveUpdate from "../hooks/useHandleObjectiveUpdate";
import useArchivedObjectives from "../hooks/useArchivedObjectives";

export const ArchiveObjectiveComponent: React.FC = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");

	const {
		archivedObjectives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
	} = useArchivedObjectives({ searchPhrase: searchTerm !== "" ? searchTerm : undefined });

	const { addObjective, deleteObjective, editObjective } =
		useHandleObjectiveUpdate();

	const handleAdd = async (newData: Omit<Item, "_id">, parentId?: string) => {
		return await addObjective(newData as Omit<Objective, "_id">, parentId);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editObjective(id, updated as Partial<Objective>);
	};

	const handleDelete = (id: string) => {
		deleteObjective(id);
	};

	const handleFavorite = (id: string) => {
		const item = archivedObjectives.find((it) => it._id === id);
		if (item) {
			editObjective(id, {
				...item,
				favorite: !item.favorite,
			});
		}
	};

	const handleDuplicate = (id: string) => {
		const item = archivedObjectives.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
				archived: false,
			}));
			handleAdd({
				...restOfObject,
				name: newName,
				users: initializedUsers,
				archived: false,
			}, id);
		}
	};

	const handleArchived = (id: string) => {
		const item = archivedObjectives.find((it) => it._id === id);

		if (item) {
			editObjective(id, {
				...item,
				archived: !item.archived,
			});
		}
	};

	const showArchive: boolean = true;

	const handleAction = (id: string) => {
		const item = archivedObjectives.find((it) => it._id === id);

		if (item) {
			console.log(item);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					currentPage={archivedCurrentPage}
					setCurrentPage={setArchivedCurrentPage}
					totalPages={archivedTotalPages}
					loading={loadingArchived}
					items={archivedObjectives}
					itemType="objective"
					showAddButton={false}
					showDeleteButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
					onArchive={handleArchived}
					showArchive={showArchive}
					onAction={handleAction}
					onReset={() => {}}
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
				/>
			</div>
		</div>
	);
};
