import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IProps {}

export default function Scroller(props: React.PropsWithChildren<IProps>) {
  const [height, setHeight] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const offset = containerRef.current?.offsetTop || 0;
  const theme = useTheme();
  const xsOrSmaller = useMediaQuery(theme.breakpoints.down("xs"));
  React.useEffect(() => {
    setHeight(window.innerHeight - offset);
  }, [offset]);
  const blockHeight = height - theme.spacing(xsOrSmaller ? 0.5 : 3);

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        height: blockHeight,
      }}
    >
      {props.children}
    </div>
  );
}
