import { indigo } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_) => ({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
	},
	dialogContent: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "55vw",
		height: "80vh",
		overflow: "auto",
	},
	listWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	itemList: {
		flex: 1,
		maxWidth: "100%",
		fontSize: 9,
		marginBottom: "1rem",
	},
	editModal: {
		flex: 1,
	},
	button: {
		marginBottom: "1rem",
	},
	buttonAndSearchContainer: {
		display: "flex",
		width: "50%",
		gap: "1vw",
		marginTop: "0.3rem",
		marginBottom: "0.3rem",
	},
	customTable: {
		width: "100%",
	},
	customCell: {
		backgroundColor: "lightgrey",
		padding: "15px",
	},
	customRow: {
		padding: "5px 10px",
	},
	tableHead: {
		"& .MuiTableCell-head": {
			color: "white",
			backgroundColor: indigo[300],
		},
	},
	actionsButtons: {
		maxWidth: "20%",
		width: "20%",
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "wrap",
		flex: 1,
		minWidth: "100px",
	},
	customRowName: {
		maxWidth: "50%",
		width: "50%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customRowLink: {
		maxWidth: "50%",
		width: "50%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}));
