import { useEffect, useMemo } from 'react';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';
import { IUser } from '../../../interfaces';

interface UserGroups {
	Leadership: IUser[];
	TeamLead: IUser[];
	SalesRep: IUser[];
}

const useUsers = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const users = objectivesStoreZustand((state) => state.users);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchAllUsers({ wholesalerId });
	}, [wholesalerId]);

	const groupUsers = (users: IUser[]): UserGroups => {
		const groupedUsers: UserGroups = {
			Leadership: [],
			TeamLead: [],
			SalesRep: [],
		};

		users.forEach((user) => {
			if (user.leadership) {
				groupedUsers.Leadership.push(user);
			} else if (user.role === 'teamLead') {
				groupedUsers.TeamLead.push(user);
			} else if (user.role === 'salesRep') {
				groupedUsers.SalesRep.push(user);
			}
		});

		return groupedUsers;
	};

	const groupedUsers = useMemo(() => groupUsers(users), [users]);

	return {
		users,
		groupedUsers,
	};
};

export default useUsers;
