import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useStyles } from './ImageListCell.styles';

interface Image {
  title: string;
  imageUrl: string;
  notes: string;
}

interface ImageListCellProps {
  images: Image[];
}

export const ImageListCell: React.FC<ImageListCellProps> = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleClickOpen = (index: number) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <List>
        {images.map((image, index) => (
          <ListItem key={index} button onClick={() => handleClickOpen(index)}>
            <ListItemAvatar>
              <Avatar alt={image.title} src={image.imageUrl} />
            </ListItemAvatar>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedImageIndex !== null && images[selectedImageIndex].title}</DialogTitle>
        <DialogContent>
          {selectedImageIndex !== null && (
            <img
              src={images[selectedImageIndex].imageUrl}
              alt={images[selectedImageIndex].title}
              className={classes.dialogImage}
            />
          )}
        </DialogContent>
        <Typography className={classes.notes}>{selectedImageIndex !== null && images[selectedImageIndex].notes}</Typography>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
