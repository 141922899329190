import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AnalyticsChart from "./AnalyticsChart";
import AnalyticsUsers from "./AnalyticsUsers";
import SpacingBoxView from "../spacingBox/SpacingBoxView";

const useStyles = makeStyles((theme) => ({
  container: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
  },
  secondContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

export default function NotificationsView() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.secondContainer}>
        <AnalyticsUsers />
        <SpacingBoxView />
        <AnalyticsChart />
      </Box>
    </Box>
  );
}
