import React from "react";
import { RootState } from "../../app/store";
import Paper from "@material-ui/core/Paper";
import Scroller from "../scroller/Scroller";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../warehouse/usersWarehouse";
import { analyticsSchema, IAnalyticsQuery, groups } from "./AnalyticsQuery";
import useQuery from "../../hooks/useQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { IRole } from "../../utils/roles";
import capitalize from "lodash/capitalize";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import makeAvatarUrl from "../../utils/makeAvatarUrl";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((_) => ({
  tab: {
    flex: 1,
    minWidth: 100,
  },
  container: {
    minWidth: 300,
    width: 300,
  },
  list: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow: "auto",
  },
}));

export default function AnalyticsUsers() {
  const { query, setQuery } = useQuery<IAnalyticsQuery>(analyticsSchema);
  const classes = useStyles();
  const role: IRole = query.group === "salesReps" ? "salesRep" : "teamLead";
  const users = useSelector((state: RootState) =>
    Object.keys(state.usersWarehouse.usersById)
      .filter((userId) => {
        return (
          state.usersWarehouse.usersById[userId].role === role &&
          !state.usersWarehouse.usersById[userId].leadership
        );
      })
      .map((userId) => state.usersWarehouse.usersById[userId])
  );
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const handleUserClick = (userId: string) => {
    if (query.users.includes(userId)) {
      setQuery({
        users: query.users.filter((item) => item !== userId),
      });
    } else {
      setQuery({
        users: query.users.concat([userId]),
      });
    }
  };
  return (
    <Paper className={classes.container}>
      <Box padding={1}>
        <Button
          onClick={() =>
            setQuery({
              users: [],
            })
          }
          fullWidth
          variant="outlined"
        >
          All Users
        </Button>
      </Box>
      <Scroller>
        <Tabs
          value={query.group}
          onChange={(_, val) => setQuery({ group: val, users: [] })}
          indicatorColor="primary"
          textColor="primary"
        >
          {groups.map((item) => (
            <Tab
              key={item}
              className={classes.tab}
              label={capitalize(item)}
              value={item}
            />
          ))}
        </Tabs>
        <Divider />
        <List dense className={classes.list}>
          {users.map((user) => (
            <ListItem
              style={{ cursor: "pointer" }}
              key={user.customId}
              selected={query.users.includes(user.customId)}
              onClick={() => handleUserClick(user.customId)}
            >
              <ListItemAvatar>
                <Avatar
                  alt={user.name}
                  src={makeAvatarUrl(user.wholesaler.customId, user.customId)}
                >
                  {user.name
                    .match(/\b(\w)/g)
                    ?.slice(0, 2)
                    .join("")}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.name} />
            </ListItem>
          ))}
          {!users.length &&
            Array(10)
              .fill(undefined)
              .map((_, index) => (
                <ListItem key={`analyticsUserSkeleton${index}`}>
                  <ListItemAvatar>
                    <Skeleton height={40} width={40} variant="circle" />
                  </ListItemAvatar>
                  <ListItemText primary={<Skeleton />} />
                </ListItem>
              ))}
        </List>
      </Scroller>
    </Paper>
  );
}
