import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
	return createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(8),
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			padding: theme.spacing(3),
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(0.5),
			},
		},
	});
});

interface IProps {
	smallScreen: boolean;
	drawerIsOpen: boolean;
	openDrawer: () => void;
}

const routeToTitleMap: { [path: string]: string } = {
	'/files': 'Files',
	'/notifications': 'Notifications',
	'/archive': 'Month-End Summary',
	'/executiveReport': 'Executive Report',
	'/suppliers': 'Suppliers',
	'/brands': 'Brands',
	'/userManagement': 'User Management',
	'/objectives': 'Objectives',
	'/incentives': 'Incentives',
	'/salesreps': 'Sales Reps',
	'/teamleads': 'Team leads',
	'/leadership': 'Leadership',
	'/products': 'Products',
	'/customers': 'Customers',
	'/analytics': 'Analytics',
	'/wholesaler': 'Wholesaler',
	'/diff': 'Diff View',
};

const postfix = 'VxP Dashboard';

export default function AppBar(props: IProps) {
	const classes = useStyles();
	const { smallScreen, drawerIsOpen, openDrawer } = props;
	const location = useLocation();
	const title = routeToTitleMap[location.pathname];
	React.useEffect(() => {
		document.title = title ? `${title} - ${postfix}` : postfix;
	}, [location, title]);

	return (
		<MUIAppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: !smallScreen && drawerIsOpen,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={openDrawer}
					edge="start"
					className={clsx(classes.menuButton, {
						[classes.hide]: drawerIsOpen,
					})}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap>
					{title}
				</Typography>
			</Toolbar>
		</MUIAppBar>
	);
}
