import { Button } from "@material-ui/core";

interface ToggleColumnsButtonProps {
    showAllColumns: boolean;
    setShowAllColumns: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const ToggleColumnsButton: React.FC<ToggleColumnsButtonProps> = ({ showAllColumns, setShowAllColumns }) => {
    const handleToggle = () => {
      setShowAllColumns(!showAllColumns);
    };

  return (
    <Button
      variant={showAllColumns ? "contained" : "outlined"}
      color="primary"
      onClick={handleToggle}
      size="small"
    >
      {showAllColumns ? 'Show Filtered Columns' : 'Show All Columns'}
    </Button>
  );
};

export default ToggleColumnsButton;