import React from "react";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { usd, volume } from "../../utils/format";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { getProduct, patchProducts } from "./productsSlice";
import useQuery, { IProductsQuery } from "../../hooks/useQuery";
import Checkbox from "@material-ui/core/Checkbox";

interface IProps {
  productId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    width: 300,
    maxWidth: 300,
    marginRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    "& td, th": {
      paddingRight: 8,
      paddingLeft: 0,
    },
    "& tbody td:nth-child(1)": {
      paddingLeft: 16,
    },
  },
  cell: {
    minWidth: 150,
  },
  checkBoxCell: {
    padding: 0,
  },
  checkBox: {
    marginLeft: -9,
  },
}));

export default function ProductDetails(props: IProps) {
  const classes = useStyles();
  const product = useSelector(
    (state: RootState) => state.products.productsById[props.productId]
  );
  const isAdmin = useSelector(
    (state: RootState) => state.session.role === "admin"
  );
  const isReady = useSelector(
    (state: RootState) => state.products.status === "ready"
  );
  const dispatch = useDispatch();
  const { query, setQuery } = useQuery<IProductsQuery>();
  const { wholesalerId, db } = query;
  const noProduct = !product;
  React.useEffect(() => {
    if (isReady && noProduct) {
      dispatch(getProduct(props.productId, { wholesalerId, db }));
    }
  }, [isReady, noProduct, dispatch, props.productId, wholesalerId, db]);
  const tableData = [
    ["Supplier", product?.supplier],
    ["Brand", product?.brand],
    ["Size", product?.size],
    ["Volume Rank", product?.volumeRank],
    ["GP Rank", product?.gpRank],
    ["Package Type", product?.packageType],
    ["Total Volume", volume(product?.volume.thisYear)],
    ["Total GP", usd(product?.gp.thisYear)],
    ["GP/CE", usd(product?.gpCe.thisYear, 2)],
    ["Tags", product?.tags.join(", ")],
  ];
  const onClose = () => {
    if (query.selectedProducts?.includes(props.productId)) {
      const selectedProducts = query.selectedProducts.filter(
        (item) => item !== props.productId
      );
      setQuery({ selectedProducts });
    }
  };
  return (
    <Card className={classes.container}>
      <Box className={classes.title}>
        {product?.name ? (
          <>
            <Box>
              <Typography variant="h5">{product.name}</Typography>
            </Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Skeleton />
        )}
      </Box>
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableBody>
            {isAdmin && (
              <>
                <TableRow>
                  <TableCell variant="head">Sponsored:</TableCell>
                  <TableCell className={classes.checkBoxCell}>
                    {product ? (
                      <Checkbox
                        className={classes.checkBox}
                        checked={product?.sponsored.isSponsored}
                        onChange={(_, value) => {
                          dispatch(
                            patchProducts(
                              {
                                wholesalerId,
                                db,
                                customIds: [props.productId],
                              },
                              {
                                patch: { sponsored: { isSponsored: value } },
                              }
                            )
                          );
                        }}
                        size="small"
                      />
                    ) : (
                      <Skeleton />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Excluded:</TableCell>
                  <TableCell className={classes.checkBoxCell}>
                    {product ? (
                      <Checkbox
                        className={classes.checkBox}
                        checked={product?.sponsored.isExcluded}
                        onChange={(_, value) => {
                          dispatch(
                            patchProducts(
                              {
                                wholesalerId,
                                db,
                                customIds: [props.productId],
                              },
                              {
                                patch: { sponsored: { isExcluded: value } },
                              }
                            )
                          );
                        }}
                        size="small"
                      />
                    ) : (
                      <Skeleton />
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
            {tableData.map((item) => (
              <TableRow key={item[0]}>
                <TableCell variant="head">{item[0]}:</TableCell>
                <TableCell className={classes.cell}>
                  {product ? item[1] : <Skeleton />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
