import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISupplier, ISliceStatus } from "../interfaces";

interface IState {
  wholesalerId?: string;
  db?: string;
  suppliersById: {
    [customId: string]: ISupplier;
  };
  status: ISliceStatus;
}

const initialState: IState = {
  suppliersById: {},
  status: "idle",
};

export const suppliersSlice = createSlice({
  name: "suppliersWarehouse",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (
      state,
      action: PayloadAction<{
        items: ISupplier[];
        wholesalerId?: string;
        db?: string;
      }>
    ) => {
      if (!action.payload.items.length) {
        state.status = "empty";
        state.wholesalerId = action.payload.wholesalerId;
        state.db = action.payload.db;
      } else {
        action.payload.items.forEach((item) => {
          state.suppliersById[item.customId] = item;
        });
        state.status = "ready";
        state.wholesalerId = action.payload.wholesalerId;
        state.db = action.payload.db;
      }
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched } = suppliersSlice.actions;

export default suppliersSlice.reducer;
