import React from 'react';
import TextField from '@material-ui/core/TextField';

interface SearchFieldProps {
	label: string;
	value: string;
	onChange: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
	label,
	value,
	onChange,
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
	};

	return (
		<TextField
			label={label}
			value={value}
			onChange={handleChange}
			variant="outlined"
			size="small"
			inputProps={{
				style: {
					height: '0.7rem',
				},
			}}
			InputLabelProps={{
                style: {
                    marginTop: '-0.2rem'
                },
            }}
		/>
	);
};

export default SearchField;
