import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	container: {
		flex: 1,
		overflow: "hidden",
		maxHeight: "87vh",
	},
	buttonsContainer: {
		marginBottom: "1rem",
		maxWidth: "100%",
		minWidth: "50%",
		width: "100%",
		direction: "ltr",
	},
	itemsContainer: {
		maxWidth: "20%",
		minWidth: "20%",
		margin: "10px 20px",
		maxHeight: "100%",
		boxSizing: "border-box",
		direction: "rtl",
		position: "relative",
	},
	itemsListContainer: {
		marginLeft: "20px",
		maxWidth: "18.7%",
		minWidth: "18.7%",
	},
	widthButton: {
		width: "100%",
	},
	searchField: {
		marginBottom: "1rem",
		width: "100%",
		direction: "ltr",
	},
	notFound: {
		direction: "ltr",
		textAlign: "center",
		marginTop: 20,
	},
	circularProgress: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		marginTop: "20px",
		direction: "ltr",
	},
	pagination: {
		marginTop: "1rem",
		marginBottom: "1rem",
		display: "flex",
		justifyContent: "center",
		direction: "ltr",
		position: "absolute",
		bottom: 0,
		width: "100%",
	},
	mainContainer: {
		width: "100%",
		display: "flex",
		height: "85vh",
	},
	overflowHidden: {
		overflow: "hidden",
	},
	tableContainerHeight: {
		maxHeight: "76.5vh",
	},
	headerCell: {
		minWidth: 155,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	clickableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	emptyBox: {
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(2),
		display: "flex",
		flex: 1,
	},
	buttonGroupContainer: {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1.2),
	},
	loading: {
		alignItems: "center",
		width: "20px",
	},
	loadingFlex: {
		display: "flex",
	},
	totalRow: {
		backgroundColor: "#E8E8E8",
		"& > *": {
			fontWeight: "bold",
			textTransform: "uppercase",
		},
	},
	itemsList: {
		overflowY: "auto",
		maxHeight: "70%",
		minHeight: "60%",
	},
}));
