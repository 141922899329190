import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {
	ThemeProvider,
	createTheme,
	createStyles,
	makeStyles,
} from '@material-ui/core/styles';
import { ReactComponent as Logo } from './logo.svg';
import SignInView from './SignInView';
import ForgotPasswordView from './ForgotPasswordView';
import ResetPasswordView from './ResetPasswordView';

const theme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#fff',
		},
	},
	overrides: {
		MuiInputBase: {
			input: {
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color',
					WebkitBoxShadow: 'none !important',
				},
			},
		},
	},
});

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			display: 'flex',
			flex: 1,
			backgroundColor: theme.palette.primary.main,
			[theme.breakpoints.down('xs')]: {
				height: '100vh',
			},
		},
		textField: {
			marginBottom: theme.spacing(2),
		},
		container: {
			padding: 0,
			[theme.breakpoints.down('xs')]: {
				maxWidth: '100%',
			},
		},
		buttonsWrapper: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignSelf: 'stretch',
		},
	})
);

export default function LoginView() {
	const classes = useStyles();
	const [formType, setFormType] = React.useState<
		'signIn' | 'forgotPassword' | 'resetPassword'
	>('signIn');

	return (
		<ThemeProvider theme={theme}>
			<Box height="100vh" alignItems="center" display="flex" flex={1}>
				<Container maxWidth="xs" className={classes.container}>
					<Paper elevation={20} className={classes.paper}>
						<Box padding={2} display="flex" flexDirection="column" flex={1}>
							<Box
								flex={1}
								minHeight={224}
								alignItems="center"
								justifyContent="center"
								display="flex"
							>
								<Logo />
							</Box>
							{formType === 'signIn' && (
								<SignInView onForgotPw={() => setFormType('forgotPassword')} />
							)}
							{formType === 'forgotPassword' && (
								<ForgotPasswordView
									onBack={() => setFormType('signIn')}
									onSubmit={() => setFormType('resetPassword')}
								/>
							)}
							{formType === 'resetPassword' && (
								<ResetPasswordView goBack={() => setFormType('signIn')} />
							)}
						</Box>
					</Paper>
				</Container>
			</Box>
		</ThemeProvider>
	);
}
