import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { mapForExport } from "./mapForExport";

interface ExportCSVButtonProps {
	columns: string[];
	rows: any[];
	calculateTotalPayout: (rows: any[]) => number;
	calculateTotalPayoutMTD: (rows: any[]) => number;
	calculateTotalTarget: (rows: any[]) => number;
}

export const ExportCSVButton = ({
	columns,
	rows,
	calculateTotalPayout,
	calculateTotalPayoutMTD,
	calculateTotalTarget,
}: ExportCSVButtonProps) => {

	const filteredColumns = columns.filter(column => column !== "Accounts" && column !== "Products");

	const totalPayout = isNaN(calculateTotalPayout(rows))
		? "∞"
		: calculateTotalPayout(rows);
	const totalPayoutMTD = isNaN(calculateTotalPayoutMTD(rows))
		? "∞"
		: calculateTotalPayoutMTD(rows);
	const totalTarget = isNaN(calculateTotalTarget(rows))
		? "∞"
		: calculateTotalTarget(rows);

	const totalRow = [
		"Total",
		...filteredColumns.slice(1).map((column) => {
			if (column === "Potential") return totalPayout;
			if (column === "Payout MTD") return totalPayoutMTD;
			if (column === "Target") return totalTarget;
			return "";
		}),
	];
	const transformedRows = rows.map((row) =>
		mapForExport(row, filteredColumns)
	);

	const dataWithTotal = [...transformedRows, totalRow];

	return (
		<CSVLink
			data={dataWithTotal}
			headers={filteredColumns}
			filename="exported_data.csv"
			className="custom-csv-link"
			target="_blank"
		>
			<Button
				size="small"
				variant="outlined"
				color="primary"
				startIcon={<DownloadIcon />}
			>
				Export CSV
			</Button>
		</CSVLink>
	);
};
