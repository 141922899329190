import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../store/objectivesStore";

const useReportObjectives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const { objectives, secondaryCurrentPage, setSecondaryCurrentPage } =
		objectivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllSubObjectives = () => {
			const { objectives } = objectivesStoreZustand.getState();
			objectives.forEach((objective) => {
				if (objective.type === "multi") {
					objectivesStoreZustand
						.getState()
						.fetchSubObjectives({ wholesalerId }, objective._id);
				}
			});
		};

		objectivesStoreZustand.getState().fetchObjectivesReportTable(
			{
				wholesalerId,
				page: secondaryCurrentPage,
				limit: secondaryPageSize,
				searchPhrase,
				type,
			},
			fetchAllSubObjectives
		);
	}, [
		wholesalerId,
		secondaryCurrentPage,
		secondaryPageSize,
		searchPhrase,
		type,
	]);

	return {
		objectives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: objectivesStoreZustand((state) => state.totalPages),
		loading: objectivesStoreZustand((state) => state.loading),
		setLoading: objectivesStoreZustand((state) => state.setLoading),
	};
};

export default useReportObjectives;
