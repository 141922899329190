import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import snack from "../../utils/snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import path from "parse-filepath";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(),
  },
}));

interface IInputProps extends TableCellProps {
  defaultValue: string;
  onAcceptChanges: (value: string) => void;
  defaultToEdit: boolean;
}

export function EditableCellInput(props: IInputProps) {
  const allFileNames = useSelector((state: RootState) =>
    Object.keys(state.files.byId)
  );
  const { defaultValue, onAcceptChanges, defaultToEdit, ...otherProps } = props;
  const [isEditing, setEditing] = React.useState(defaultToEdit);
  let inputRef = React.createRef<HTMLInputElement>();
  const [isLoading, setLoading] = React.useState(false);
  const defaultFile = path(props.defaultValue);
  React.useEffect(() => {
    if (defaultFile.name) {
      setEditing(false);
    }
    setLoading(false);
  }, [defaultFile.name]);
  const onAccept = () => {
    if (!inputRef?.current?.value) {
      snack.error("File name can't be empty 🤷‍♂️");
      return;
    }
    const newName =
      (defaultFile.dir ? defaultFile.dir + "/" : "") +
      `${inputRef.current.value}${defaultFile.ext}`;
    if (newName.includes("newFile")) {
      snack.error("Specify a proper file name");
    } else if (newName !== defaultValue && allFileNames.includes(newName)) {
      snack.error("File with this name already exists");
    } else {
      if (newName === defaultValue) {
        setEditing(false);
      } else {
        setLoading(true);
        onAcceptChanges(newName);
      }
    }
  };
  return (
    <TableCell {...otherProps} onClick={() => !isEditing && setEditing(true)}>
      {isEditing ? (
        <Box display="flex" flexDirection="row">
          <Input
            inputRef={inputRef}
            autoFocus
            fullWidth
            onKeyPress={(key) => key.charCode === 13 && onAccept()}
            defaultValue={defaultFile.name}
            endAdornment={
              <InputAdornment position="end">{defaultFile.ext}</InputAdornment>
            }
          />
          <Controls
            isLoading={isLoading}
            onAccept={onAccept}
            onCancel={() => {
              setEditing(false);
              setLoading(false);
            }}
          />
        </Box>
      ) : (
        defaultFile.name + defaultFile.ext
      )}
    </TableCell>
  );
}

interface IMultiselectProps extends TableCellProps {
  defaultValue: string[];
  options: string[];
  onAcceptChanges: (value: any[]) => void;
}

export function EditableCellMultiselect(props: IMultiselectProps) {
  const [isEditing, setEditing] = React.useState(false);
  const { defaultValue, onAcceptChanges, options, ...otherProps } = props;
  const [currentValue, setCurrentValue] = React.useState(defaultValue);
  const theme = useTheme();
  React.useEffect(() => {
    setEditing(false);
    setLoading(false);
  }, [defaultValue]);
  const [isLoading, setLoading] = React.useState(false);

  return (
    <TableCell {...otherProps} onClick={() => !isEditing && setEditing(true)}>
      {isEditing ? (
        <Box display="flex" flexDirection="row">
          <Select
            fullWidth
            autoFocus
            multiple
            value={currentValue}
            onChange={(event) => {
              let val = (event.target.value as string[]) || [];
              if (!val.includes("manager")) {
                val = ["manager", ...val];
              }
              setCurrentValue(val);
            }}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(", ")}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                style={getStyles(option, currentValue, theme)}
              >
                <Checkbox checked={currentValue.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
          <Controls
            isLoading={isLoading}
            onAccept={() => {
              if (isEqual(currentValue, defaultValue)) {
                setEditing(false);
              } else {
                setLoading(true);
                onAcceptChanges(currentValue);
              }
            }}
            onCancel={() => {
              setCurrentValue(defaultValue);
              setEditing(false);
              setLoading(false);
            }}
          />
        </Box>
      ) : (
        defaultValue.join(", ")
      )}
    </TableCell>
  );
}

function getStyles(option: string, selectedOptions: string[], theme: Theme) {
  return {
    fontWeight: selectedOptions.includes(option)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

interface IControlsProps {
  onAccept: () => void;
  onCancel: () => void;
  isLoading: boolean;
}
function Controls(props: IControlsProps) {
  const classes = useStyles();
  return (
    <>
      <IconButton
        className={classes.icon}
        size="small"
        onClick={props.onAccept}
        edge="end"
      >
        {props.isLoading ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          <CheckIcon color="secondary" />
        )}
      </IconButton>
      <IconButton
        className={classes.icon}
        size="small"
        onClick={props.onCancel}
        edge="end"
      >
        <CloseIcon color="error" />
      </IconButton>
    </>
  );
}
