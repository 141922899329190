import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import moment from "moment";

const progressCircleSize = 90;
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    maxHeight: 178,
  },
  progressContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flex: 1,
    position: "relative",
  },
  progress: {},
  progressAnnotations: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontWeight: 500,
  },
  progressBackground: {
    backgroundColor: theme.palette.action.hover,
    height: progressCircleSize,
    width: progressCircleSize,
    position: "absolute",
    top: 0,
    bottom: 0,
    borderRadius: "50%",
  },
}));

export default function ExecutiveReportTime() {
  const lastTransactionDate = useSelector(
    (state: RootState) => state.wholesalerWarehouse.details?.lastTransactionDate
  );
  const thisYearSellingDaysPastMonth = useSelector(
    (state: RootState) =>
      state.wholesalerWarehouse.details?.thisYearSellingDaysPastMonth || 0
  );
  const thisYearSellingDaysTotal = useSelector(
    (state: RootState) =>
      (state.wholesalerWarehouse.details?.thisYearSellingDaysLeft || 0) +
      (state.wholesalerWarehouse.details?.thisYearSellingDaysPastMonth || 0)
  );
  const percent = thisYearSellingDaysTotal
    ? Math.round(
        (thisYearSellingDaysPastMonth / thisYearSellingDaysTotal) * 100
      )
    : 0;
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <Typography variant="body2" className={classes.title} align="center">
        Selling Days
      </Typography>
      <Box className={classes.progressContainer}>
        <div className={classes.progressBackground} />
        <CircularProgress
          size={progressCircleSize}
          className={classes.progress}
          variant="determinate"
          value={percent || 100}
        />
        <Box className={classes.progressAnnotations}>
          <Typography variant="body2">
            {thisYearSellingDaysPastMonth}/{thisYearSellingDaysTotal}
          </Typography>
          <Typography variant="body2">({percent}%)</Typography>
        </Box>
      </Box>
      <Typography variant="body2" align="center">
        {moment(lastTransactionDate).utc().format("MMMM Do YYYY")}
      </Typography>
    </Paper>
  );
}
