import React from "react";
import ExecutiveReportTable from "./ExecutiveReportTable";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  titleWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

interface IProps {
  tableName: "mtdGp" | "mtdVolume" | "ytdGp" | "ytdVolume";
}
export default function ExecutiveReportInfoBlock(props: IProps) {
  const classes = useStyles();
  const columns = useSelector((state: RootState) =>
    state.executiveReport[props.tableName].columns.filter(
      (_, index) =>
        !state.executiveReport[props.tableName].hiddenColumns.includes(index)
    )
  );
  const visible = useSelector(
    (state: RootState) => state.executiveReport[props.tableName].visible
  );
  const title = useSelector(
    (state: RootState) => state.executiveReport[props.tableName].title
  );
  const rows = useSelector((state: RootState) =>
    state.executiveReport[props.tableName].rows.map((cells) =>
      cells.filter(
        (_, index) =>
          !state.executiveReport[props.tableName].hiddenColumns.includes(index)
      )
    )
  );
  const total = useSelector((state: RootState) =>
    state.executiveReport[props.tableName].total.filter(
      (_, index) =>
        !state.executiveReport[props.tableName].hiddenColumns.includes(index)
    )
  );
  if (!visible) {
    return null;
  }
  return (
    <Grid item md={6} xs={12}>
      <Paper className={classes.container}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <ExecutiveReportTable
          tableName={props.tableName}
          columns={columns}
          rows={rows}
          total={total}
        />
      </Paper>
    </Grid>
  );
}
