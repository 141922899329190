import React, { useEffect, useState } from "react";
import useUsers from "../../../../hooks/useUsers";
import { Button, FormControl } from "@material-ui/core";
import Select from "react-select";
import { IUser } from "../../../../../../interfaces";
import { useStyles } from "./UsersSelection.styles";

type CustomUsersSelectionProps = {
	initialSelectedUserIds: string[];
	onChange: (selectedUsers: string[]) => void;
};

export const CustomUsersSelection: React.FC<CustomUsersSelectionProps> = ({
	initialSelectedUserIds,
	onChange,
}) => {
	const classes = useStyles();
	const { groupedUsers, users } = useUsers();
	const [selectedLeadership, setSelectedLeadership] = useState<IUser[]>([]);
	const [selectedTeamLead, setSelectedTeamLead] = useState<IUser[]>([]);
	const [selectedSalesRep, setSelectedSalesRep] = useState<IUser[]>([]);
	const [allSelected, setAllSelected] = useState<boolean>(false);

	const mapUserIdsToUsers = (
		userIds: string[],
		allUsers: IUser[]
	): IUser[] => {
		return userIds
			.map((userId) => allUsers.find((user) => user.customId === userId))
			.filter((user) => user) as IUser[];
	};

	useEffect(() => {
		const initialUsers = mapUserIdsToUsers(initialSelectedUserIds, users);
		setSelectedLeadership(initialUsers.filter((user) => user.leadership));
		setSelectedTeamLead(
			initialUsers.filter(
				(user) => user.role === "teamLead" && !user.leadership
			)
		);
		setSelectedSalesRep(
			initialUsers.filter((user) => user.role === "salesRep")
		);
	}, [initialSelectedUserIds, users]);

	useEffect(() => {
		const allUserIds = [
			...(groupedUsers?.Leadership || []),
			...(groupedUsers?.TeamLead || []),
			...(groupedUsers?.SalesRep || []),
		].map((user) => user.customId);
		const selectedUserIds = [
			...selectedLeadership,
			...selectedTeamLead,
			...selectedSalesRep,
		].map((user) => user.customId);

		setAllSelected(allUserIds.length === selectedUserIds.length);
	}, [selectedLeadership, selectedTeamLead, selectedSalesRep, groupedUsers]);

	const handleLeadershipSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map(
			(option: any) => option.value
		);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedLeadership(selectedUsers);
		updateAllSelectedState([
			...selectedUserIds,
			...selectedTeamLead.map((user) => user.customId),
			...selectedSalesRep.map((user) => user.customId),
		]);
	};

	const handleTeamLeadSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map(
			(option: any) => option.value
		);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedTeamLead(selectedUsers);
		updateAllSelectedState([
			...selectedLeadership.map((user) => user.customId),
			...selectedUserIds,
			...selectedSalesRep.map((user) => user.customId),
		]);
	};

	const handleSalesRepSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map(
			(option: any) => option.value
		);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedSalesRep(selectedUsers);
		updateAllSelectedState([
			...selectedLeadership.map((user) => user.customId),
			...selectedTeamLead.map((user) => user.customId),
			...selectedUserIds,
		]);
	};

	const updateAllSelectedState = (selectedUserIds: string[]) => {
		const allUserIds = [
			...(groupedUsers?.Leadership || []),
			...(groupedUsers?.TeamLead || []),
			...(groupedUsers?.SalesRep || []),
		].map((user) => user.customId);
		setAllSelected(allUserIds.length === selectedUserIds.length);
		onChange(selectedUserIds);
	};

	const handleSelectAllOrRemoveAll = () => {
		if (allSelected) {
			setSelectedLeadership([]);
			setSelectedTeamLead([]);
			setSelectedSalesRep([]);
			onChange([]);
		} else {
			const allLeadership = groupedUsers?.Leadership || [];
			const allTeamLead = groupedUsers?.TeamLead || [];
			const allSalesRep = groupedUsers?.SalesRep || [];

			setSelectedLeadership(allLeadership);
			setSelectedTeamLead(allTeamLead);
			setSelectedSalesRep(allSalesRep);

			const allUserIds = [
				...allLeadership.map((user) => user.customId),
				...allTeamLead.map((user) => user.customId),
				...allSalesRep.map((user) => user.customId),
			];

			onChange(allUserIds);
		}
	};

	return (
		<div className={classes.container}>
			<Button
				variant="outlined"
				color="primary"
				onClick={handleSelectAllOrRemoveAll}
				size="small"
				className={classes.button}
			>
				{allSelected ? "Remove All" : "Select All"}
			</Button>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="leadership"
					placeholder="Select Leadership"
					options={groupedUsers.Leadership.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					value={selectedLeadership.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					onChange={(multi) => handleLeadershipSelectChange(multi)}
				/>
			</FormControl>

			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="teamLead"
					placeholder="Select Team Lead"
					options={groupedUsers.TeamLead.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					value={selectedTeamLead.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					onChange={(multi) => handleTeamLeadSelectChange(multi)}
				/>
			</FormControl>

			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="salesRep"
					placeholder="Select Sales Rep"
					options={groupedUsers.SalesRep.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					value={selectedSalesRep.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					onChange={(multi) => handleSalesRepSelectChange(multi)}
				/>
			</FormControl>
		</div>
	);
};
