import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";

const useArchivedObjectives = ({ searchPhrase }: { searchPhrase?: string }) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);

	const loadingArchived = objectivesStoreZustand(
		(state) => state.loadingArchived
	);
	const archivedCurrentPage = objectivesStoreZustand(
		(state) => state.archivedCurrentPage
	);
	const setArchivedCurrentPage = objectivesStoreZustand(
		(state) => state.setArchivedCurrentPage
	);
	const archivedTotalPages = objectivesStoreZustand(
		(state) => state.archivedTotalPages
	);
	const archivedObjectives = objectivesStoreZustand(
		(state) => state.archivedObjectives
	);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchArchivedObjectives({
			wholesalerId,
			page: archivedCurrentPage,
			limit: pageSize,
			searchPhrase,
		});
	}, [wholesalerId, archivedCurrentPage, pageSize, searchPhrase]);

	return {
		setPageSize,
		archivedObjectives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
	};
};

export default useArchivedObjectives;