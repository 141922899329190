import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SpacingBox from "../spacingBox/SpacingBoxView";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  controls: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  controlsSkeleton: {
    flex: 1,
    maxWidth: 185,
  },
  content: {
    display: "flex",
    flexDirection: "row",
  },
  list: {
    width: 375,
    minWidth: 375,
  },
  contentSkeleton: {},
  secondaryText: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function SalesRepsSkeleton() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.controls}>
        <Skeleton className={classes.controlsSkeleton} />
        <SpacingBox />
        <Skeleton className={classes.controlsSkeleton} />
        <SpacingBox />
        <Skeleton className={classes.controlsSkeleton} />
      </Box>
      <Box className={classes.content}>
        <List className={classes.list} component={Paper} dense>
          {Array(5)
            .fill(undefined)
            .map((_, index) => (
              <ListItem key={`slControlsSkeletonList${index}`}>
                <ListItemText
                  primary={<Skeleton height={20} width={170} />}
                  secondary={
                    <Box className={classes.secondaryText}>
                      <Skeleton height={16} />
                      <Skeleton height={16} />
                      <Skeleton height={16} />
                    </Box>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
}
