import React, { useState } from "react";
import { Button, Dialog, TextField } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useStyles } from "./ItemComponent.styles";
import { Objective } from "../types/objectiveTypes";
import ItemList from "../objectivesIncentivesComponent/itemsList/ItemsList";
import { Incentive } from "../types/incentivesTypes";
import EditObjectiveComponent from "../objectivesIncentivesComponent/editObjIncComponents/EditObjectiveComponent";
import { EditIncentiveComponent } from "../objectivesIncentivesComponent/editObjIncComponents/EditIncentiveComponent";
import { AddNewItemComponent } from "../objectivesIncentivesComponent/addObjectiveComponents/addNewItem";

export type Item = Objective | Incentive;

interface ItemComponentProps {
	items: Item[];
	itemType: "objective" | "incentive";
	showAddButton?: boolean;
	showDeleteButton?: boolean;
	currentPage: number;
	loading: boolean;
	setCurrentPage: (page: number) => void;
	totalPages: number;
	onAdd: (
		data: Omit<Item, "_id">
	) => Promise<Objective | Incentive | undefined>;
	onEdit: (id: string, data: Partial<Item>) => void;
	onDelete: (id: string) => void;
	onFavorite: (id: string) => void;
	onDuplicate: (id: string) => void;
	onArchive: (id: string) => void;
	showArchive: boolean;
	onAction: (id: string) => void;
	onReset: (id: string) => void;
	searchTerm?: string;
	onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ItemComponent: React.FC<ItemComponentProps> = ({
	items,
	itemType,
	loading,
	currentPage,
	totalPages,
	showAddButton = true,
	showDeleteButton = true,
	onAdd,
	onEdit,
	onDelete,
	onFavorite,
	onDuplicate,
	onArchive,
	setCurrentPage,
	showArchive,
	onAction,
	onReset,
	searchTerm,
	onSearchChange
}) => {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editableItem, setEditableItem] = useState<Item | null>(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleSaveEditItem = (item: Item) => {
		setOpenEditModal(false);
		onEdit(item._id, item);
	};

	const handleEditItem = (id: string) => {
		if (openEditModal) {
			setOpenEditModal(false);
			setEditableItem(null);
		}

		const selectedItem = items.find((item) => item._id === id);
		if (selectedItem) {
			setEditableItem(selectedItem);
			setOpenEditModal(true);
		}
	};

	const handleAddItem = async (newItemData: Omit<Item, "_id">) => {
		const newItem = await onAdd(newItemData);
		if (newItem && newItem._id) {
			setEditableItem(newItem);
			setOpenEditModal(true);
		}
		return newItem;
	};

	return (
		<div className={classes.container}>
			<div className={classes.buttonAndSearchContainer}>
				{showAddButton && (
					<div className={classes.buttonContainer}>
						<Button
							className={classes.button}
							color="primary"
							startIcon={<AddCircleOutlineIcon />}
							onClick={handleClickOpen}
						>
							New {itemType === "objective" ? "Objective" : "Incentive"}
						</Button>
					</div>
				)}
				<div className={classes.searchContainer}>
					<TextField
						label="Search"
						value={searchTerm}
						onChange={onSearchChange}
						variant="outlined"
						size="small"
					/>
				</div>
			</div>
			{showAddButton && (
				<AddNewItemComponent
					open={open}
					itemType={itemType}
					onAdd={handleAddItem}
					onClose={() => setOpen(false)}
				/>
			)}

			<div className={classes.listWrapper}>
				<div className={classes.itemList}>
					<ItemList
						items={items}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						loading={loading}
						showDeleteButton={showDeleteButton}
						onDelete={onDelete}
						onEdit={handleEditItem}
						onFavorite={onFavorite}
						onDuplicate={onDuplicate}
						onArchive={onArchive}
						showArchive={showArchive}
						onAction={onAction}
						onReset={onReset}
						itemType={itemType}
					/>
				</div>
				{openEditModal && editableItem && (
					<Dialog
						open={openEditModal}
						onClose={() => setOpenEditModal(false)}
						fullWidth
						disableEnforceFocus
						PaperProps={{
							style: {
								position: "absolute",
								right: 0,
								minWidth: "55vw",
								maxHeight: "100vh",
								height: "100%",
								margin: 0,
								borderRadius: 0,
							},
						}}
					>
						<div>
							{itemType === "objective" ? (
								<EditObjectiveComponent
									key={editableItem._id}
									initialItemState={editableItem as Objective}
									onSave={handleSaveEditItem}
									onClose={() => setOpenEditModal(false)}
								/>
							) : (
								<EditIncentiveComponent
									key={editableItem._id}
									initialItemState={editableItem as Incentive}
									onSave={handleSaveEditItem}
									onClose={() => setOpenEditModal(false)}
								/>
							)}
						</div>
					</Dialog>
				)}
			</div>
		</div>
	);
};

export default ItemComponent;
