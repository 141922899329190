import React from 'react';
import {
	ITableRows,
	ITableCell,
} from '../executiveReport/executiveReportSlice';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { userStoreZustand } from './userStore';
import { IUser } from '../../interfaces';
import { mapTransformedDataToUser, transformToDTO } from './utils';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';
import { MenuItem, Select } from '@material-ui/core';

interface IProps {
	columns: string[];
	rows: ITableRows;
}

const useStyles = makeStyles((theme) => ({
	success: {
		color: theme.palette.success.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	hoveredRow: {
		backgroundColor: theme.palette.action.hover,
	},
	emptyBox: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(2),
		display: 'flex',
		flex: 1,
	},
	headerCell: {
		minWidth: 155,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: theme.spacing(1),
	},
	firstHeaderCell: {
		minWidth: 155,
		zIndex: 3,
		backgroundColor: '#FAFAFA',
	},
}));

export default function UserManagementReportTable(props: IProps) {
	const { columns, rows } = props;

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
		users,
	} = userStoreZustand();

	const classes = useStyles();

	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell className={classes.headerCell} key={index}>
								<TableSortLabel
									active={sortColumnName === column}
									direction={sortDirection}
									onClick={() => {
										setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
										setColumnName(column);
										setOrder(sortDirection === 'asc' ? 'desc' : 'asc', column);
									}}
								>
									{column}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.length !== 0 &&
						users.length !== 0 &&
						rows.map((row, index) => {
							return (
								<UserManagementReportTableRow
									key={'usrMng' + row[4].content}
									data={row}
									rowIndex={index}
									user={users.find(
										(usr: IUser) => usr.email === row[4].content
									)}
								/>
							);
						})}
				</TableBody>
			</Table>
			{rows.length === 0 && (
				<div className={classes.emptyBox}>
					<Typography variant="subtitle1">No data</Typography>
				</div>
			)}
		</TableContainer>
	);
}

interface IRowProps {
	data: ITableCell[];
	rowIndex: number;
	user: IUser | undefined;
}

function UserManagementReportTableRow(props: IRowProps) {
	const classes = useStyles();
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;
	const {
		hoveredId,
		selectedId,
		setHoveredId,
		setSelectedId,
		editUser,
		fetchUsers,
	} = userStoreZustand();

	const [editingCell, setEditingCell] = React.useState<number | null>(null);
	const [cellValue, setCellValue] = React.useState<string | number>();
	const [cellInitialValue, setCellInitialValue] = React.useState<
		string | number
	>();

	const handleCellClick = (index: number, value: string | number) => {
		setEditingCell(index);
		setCellValue(value);
		setCellInitialValue(value);
	};

	const handleTeamCellClick = (index: number, value: string) => {
		setEditingCell(index);
		setCellValue(value);
		setCellInitialValue(value);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCellValue(e.target.value);
	};

	const handleInputBlur = () => {
		// Here you can handle saving the updated value to the store or backend
		// For this example, I'll simply stop editing the cell without persisting changes
		if (cellValue !== cellInitialValue) {
			const newData = [...props.data];
			newData[editingCell as number].value = cellValue as string;

			let newUser = null;

			if (props.user) {
				newUser = mapTransformedDataToUser(newData, props.user);

				if (newUser) {
					newUser = transformToDTO(newUser);
					editUser(
						{
							customIds: [props.user.customId],
							wholesalerId: wholesalerId,
						},
						{
							patch: {
								name: newUser.name,
								email: newUser.email,
								routeNumber: newUser.routeNumber,
								totalPayout: newUser.totalPayout,
								gp: newUser.gp,
								volume: newUser.volume,
								fpl: newUser.fpl,
								wholesaler: newUser.wholesaler,
								teamLead: newUser.teamLead ? newUser.teamLead : [],
							},
						}
					)
						.then(() => {
							fetchUsers({ wholesalerId });
						})
						.catch((err: any) => {
							console.log('err', err);
						});
				}
			}
		}
	};

	return (
		<TableRow
			className={
				hoveredId === props.data[4].value ? classes.hoveredRow : undefined
			}
			hover
			onMouseEnter={() => {
				if (
					typeof props.data[4].value === 'string' ||
					typeof props.data[4].value === 'number'
				) {
					setHoveredId(props.data[4].value);
				} else {
					setHoveredId(props.data[4].value[0].name);
				}
			}}
			selected={selectedId === props.data[4].value}
			onClick={() => {
				if (
					typeof props.data[4].value === 'string' ||
					typeof props.data[4].value === 'number'
				) {
					setSelectedId(props.data[4].value);
				} else {
					setSelectedId(props.data[4].value[0]?.name || '');
				}
			}}
		>
			{props.data.map((cell, index) => {
				const isEditing = index === editingCell;

				const cellContent =
					isEditing && index !== 0 && index !== 1 ? (
						typeof cell.value === 'string' || typeof cell.value === 'number' ? (
							<>
								<input
									style={{ width: '100%', height: '46px' }}
									value={cellValue}
									onChange={handleInputChange}
									onBlur={handleInputBlur}
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											handleInputBlur();
											setEditingCell(null);
											setCellValue(undefined);
											setCellInitialValue(undefined);
										}
									}}
								/>
							</>
						) : (
							<Select
								value={cellValue}
								onChange={(e: any) => {
									setCellValue(e.target.value);
									handleInputBlur();
								}}
							>
								{cell.value.map(
									(option: { name: string; customId: string }) => (
										<MenuItem key={option.customId} value={option.name}>
											{option.name}
										</MenuItem>
									)
								)}
							</Select>
						)
					) : (
						cell.content
					);

				const cellComponent = (
					<TableCell
						variant={index === 0 ? 'head' : 'body'}
						key={'usrMng' + String(props.rowIndex) + index}
						className={
							index !== 0 &&
							index !== 1 &&
							hoveredId !== props.data[4].value &&
							selectedId !== props.data[4].value
								? classes.firstHeaderCell
								: undefined
						}
						onClick={() => {
							if (
								typeof cell.value === 'string' ||
								typeof cell.value === 'number'
							) {
								handleCellClick(index, cell.value);
							} else {
								handleTeamCellClick(index, cell.value[0]?.name || '');
							}
						}}
					>
						{cellContent}
					</TableCell>
				);
				return cell.tooltip ? (
					<Tooltip
						key={'tooltip usrMng' + String(props.rowIndex) + index}
						title={cell.tooltip}
					>
						{cellComponent}
					</Tooltip>
				) : (
					cellComponent
				);
			})}
		</TableRow>
	);
}
