import React from "react";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUsers } from "../../warehouse/usersWarehouse";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(),
    marginLeft: theme.spacing(3),
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  inputWrapper: {
    minWidth: 140,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const sortingOptions = [
  {
    label: "CE This Year",
    value: "0",
  },
  {
    label: "CE This Year (asc)",
    value: "1",
  },
  {
    label: "GP This Year",
    value: "2",
  },
  {
    label: "GP This Year (asc)",
    value: "3",
  },
];

const anyLabel = "Any";
export default function BuyersControls() {
  const { query, setQuery } = useQuery<IBuyersQuery>();
  const classes = useStyles();
  const salesReps = useSelector((state: RootState) => {
    const usersById = state.usersWarehouse.usersById;
    return sortBy(
      Object.keys(usersById)
        .filter((userId) => usersById[userId].role === "salesRep")
        .map((customId) => ({
          name: usersById[customId].name,
          customId,
        })),
      "name"
    );
  });
  const onSearch = debounce(
    (event) => setQuery({ searchPhrase: event.target.value?.toLowerCase() }),
    500
  );
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getUsers({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const onSelect = (key: string, val?: string[] | string) =>
    setQuery({ [key]: val });
  return (
    <Box className={classes.container}>
      <TextField
        className={classes.inputWrapper}
        label="Search by title"
        size="small"
        variant="outlined"
        defaultValue={query.searchPhrase}
        onChange={onSearch}
      />
      <FormControl
        className={classes.inputWrapper}
        variant="outlined"
        size="small"
      >
        <InputLabel>Sales Rep</InputLabel>
        <Select
          value={query.salesRep}
          native
          onChange={(e) => {
            if (e.target.value === anyLabel) {
              onSelect("salesRep");
            } else {
              onSelect("salesRep", e.target.value as string);
            }
          }}
          label={"Sales Rep"}
        >
          <option value={anyLabel}>{anyLabel}</option>
          {salesReps.map((item) => (
            <option key={item.customId} value={item.customId}>
              {item.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classes.inputWrapper}
        variant="outlined"
        size="small"
      >
        <InputLabel>Sort by</InputLabel>
        <Select
          value={query.sortBy || sortingOptions[0].value}
          native
          onChange={(e) => {
            onSelect("sortBy", e.target.value as string);
          }}
          label={"Sort by"}
        >
          {sortingOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
