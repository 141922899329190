import React from "react";
import Box from "@material-ui/core/Box";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SuppliersControls from "./SuppliersControls";
import SuppliersTable from "./SuppliersTable";
import { getSuppliersOrBrands } from "./suppliersSlice";
import Typography from "@material-ui/core/Typography";
import useQuery from "../../hooks/useQuery";
import { ISuppliersQuery, suppliersSchema } from "./suppliersQuery";

const useStyles = makeStyles((_) => ({
  tablesWrapper: {},

  printHeader: {
    textAlign: "center",
    fontWeight: 500,
    display: "none",
  },
  container: {
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function SuppliersView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { query } = useQuery<ISuppliersQuery>(suppliersSchema);

  React.useEffect(() => {
    dispatch(
      getSuppliersOrBrands({
        wholesalerId: query.wholesalerId,
        db: query.db,
        tableName: "suppliersTable",
        period: query.period,
        hiddenColumns: query.hiddenColumns,
        searchPhrase: query.searchPhrase,
        orderBy: query.orderBy,
        descending: query.descending,
        tags: query.tags,
      })
    );
  }, [
    dispatch,
    query.period,
    query.hiddenColumns,
    query.searchPhrase,
    query.orderBy,
    query.descending,
    query.wholesalerId,
    query.db,
  ]);
  const ref = React.useRef(null);
  return (
    <>
      <SuppliersControls tableName="suppliersTable" printRef={ref} />
      <div className={classes.container} id="suppliersPrintBox" ref={ref}>
        <Typography
          className={[classes.printHeader, "showOnPrint"].join(" ")}
          variant="h1"
        >
          {query.period}
        </Typography>
        <Box id="suppliersViewTables" className={classes.tablesWrapper}>
          <SuppliersTable tableName="suppliersTable" />
        </Box>
      </div>
    </>
  );
}
