import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Link } from "react-router-dom";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import { makeStyles } from "@material-ui/core/styles";
import { usd, volume, delta } from "../../utils/format";
import qs from "qs";

interface IProps {
  buyerId: string;
  selected: boolean;
}

const useStyles = makeStyles((theme) => ({
  trendWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginLeft: theme.spacing(),
  },
  secondaryText: {
    whiteSpace: "break-spaces",
  },
}));

export default function UserListItem(props: IProps) {
  const buyer = useSelector(
    (state: RootState) => state.buyers.buyersById[props.buyerId]
  );
  const { query } = useQuery<ISalesRepsQuery>();
  const classes = useStyles();

  return (
    <ListItem
      selected={props.selected}
      component={Link}
      to={{
        pathname: `/customers`,
        search: `?${qs.stringify({
          ...query,
          selectedBuyer: props.buyerId,
        })}`,
      }}
    >
      <ListItemText
        classes={{
          secondary: classes.secondaryText,
        }}
        primary={buyer.name}
        secondary={`GP TY: ${usd(buyer.gp.thisYear)} / LY: ${usd(
          buyer.gp.lastYear
        )} / ${delta(buyer.gp.diff, 0)}
CE TY: ${volume(buyer.volume.thisYear)} / LY: ${volume(
          buyer.volume.lastYear
        )} / ${delta(buyer.volume.diff, 0)}
GP/CE TY: ${usd(buyer.gp_ce.thisYear, 2)} / LY: ${usd(
          buyer.gp_ce.lastYear,
          2
        )} / ${delta(buyer.gp_ce.diff, 0)}`}
      />
    </ListItem>
  );
}
