import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((_) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        alignItems: 'center'
    },
    label: {
        marginTop: '1rem'
    },
    formControl: {
        minWidth: '9rem',
        maxWidth: '13rem'
    },
    textField: {
        maxWidth: '6rem',
    }
}));