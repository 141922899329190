import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';
import { deleteUser, patchUsers } from '../../warehouse/usersWarehouse';
import SpacingBoxView from '../spacingBox/SpacingBoxView';
import { MultiSelect } from '../select/Select';
import isEmail from '../../utils/isEmail';
import { validatePassword } from '../../utils/createUserPrompt';

const useStyles = makeStyles((theme) => ({
	container: {
		width: 375,
		minWidth: 375,
		display: 'flex',
		flexDirection: 'column',
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	formWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		paddingBottom: 0,
	},
	buttonsWrapper: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(2),
	},
}));

interface IProps {
	userId: string;
}
interface IInput {
	key: string;
	label: string;
	adornment?: string;
	validate: (str: string) => true | string;
	valueAsNumber: boolean;
	defaultValue?: number;
}
const validate = (str: string) => {
	const num = Number(str);
	if (isNaN(num)) {
		return `Doesn't look like a valid number`;
	}
	return true;
};
const inputs: IInput[] = [
	{
		key: 'name',
		label: 'Name',
		validate: (str) =>
			/^[a-zA-Z0-9 ']{1,30}$/.test(str) ||
			'Should be 1 to 30 letters and spaces',
		valueAsNumber: false,
	},
	{
		key: 'email',
		label: 'Email',
		validate: (str) => isEmail(str) || "It doesn't look like an email",
		valueAsNumber: false,
	},
	{
		key: 'routeNumber',
		label: 'Routes (comma-separated numbers)',
		validate: (str) =>
			/^[0-9, ]{1,2000}$/.test(str) || 'Should be comma-separated numbers',
		valueAsNumber: false,
	},

	{
		key: 'totalPayout',
		label: 'Total payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.lastYear',
		label: 'GP MTD LY',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.thisYear',
		label: 'GP MTD TY',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.achieved',
		label: 'GP % vs Goal',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.achievedSellingDays',
		label: 'GP PEM %',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.goal',
		label: 'GP Goal %',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.payout',
		label: 'GP Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.threshold',
		label: 'GP Threshold',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.bonus',
		label: 'GP Bonus',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'gp.totalPayout',
		label: 'GP Total Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},

	{
		key: 'volume.lastYear',
		label: 'Volume MTD LY',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.thisYear',
		label: 'Volume MTD TY',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.achieved',
		label: 'Volume % vs Goal',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.achievedSellingDays',
		label: 'Volume PEM %',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.goal',
		label: 'Volume Goal %',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.payout',
		label: 'Volume Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.threshold',
		label: 'Volume Threshold',
		adornment: '%',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.bonus',
		label: 'Volume Bonus',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'volume.totalPayout',
		label: 'Volume Total Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},

	{
		key: 'fpl.individual',
		label: 'FPL Individual',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'fpl.team',
		label: 'FPL Team',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'fpl.goal',
		label: 'FPL Goal',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'fpl.payout',
		label: 'FPL Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'fpl.totalPayout',
		label: 'FPL Total Payout',
		adornment: '$',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'wholesaler.maxFPL',
		label: 'Max FPL',
		validate,
		valueAsNumber: true,
		defaultValue: 0,
	},
	{
		key: 'password',
		label: 'Password',
		validate: (str) =>
			!str || validatePassword(str)
				? true
				: 'The password should be 6 to 32 characters long',
		valueAsNumber: false,
	},
];
export default function UserEditCard(props: IProps) {
	const { register, errors, handleSubmit } = useForm();
	const classes = useStyles();
	const user = useSelector(
		(state: RootState) => state.usersWarehouse.usersById[props.userId]
	);
	const { query } = useQuery<IGeneralQuery>();
	const dispatch = useDispatch();
	const teamLeads = useSelector((state: RootState) => {
		return Object.keys(state.usersWarehouse.usersById)
			.filter(
				(userId) => state.usersWarehouse.usersById[userId].role === 'teamLead'
			)
			.map((userId) => ({
				label: state.usersWarehouse.usersById[userId].name,
				key: state.usersWarehouse.usersById[userId].customId,
			}));
	});

	const [teamLead, setTeamLead] = useState<string[]>(
		user.teamLead.map((item) => item.customId)
	);
	if (!user) {
		return <></>;
	}
	return (
		<>
			<Box className={classes.formWrapper}>
				<MultiSelect
					options={teamLeads}
					selected={teamLead}
					onSelect={(val) => {
						setTeamLead(val || []);
					}}
					label="Team"
				/>
				<SpacingBoxView />
				{inputs.map((item) => (
					<TextField
						size="small"
						key={item.key}
						className={classes.input}
						defaultValue={get(user, item.key) || item.defaultValue}
						error={!!get(errors, item.key)}
						// placeholder={`${inputs[key].helper} (Unchanged)`}
						name={item.key}
						inputRef={register({
							valueAsNumber: item.valueAsNumber,
							validate: item.validate,
						})}
						label={item.label}
						helperText={get(errors, item.key)?.message}
						fullWidth
						variant="outlined"
						InputProps={{
							startAdornment: item.adornment && (
								<InputAdornment position="start">
									{item.adornment}
								</InputAdornment>
							),
						}}
					/>
				))}
			</Box>
			<Divider />
			<Box className={classes.buttonsWrapper}>
				<Button
					variant="outlined"
					fullWidth
					onClick={() => {
						if (window.confirm(`Delete ${user.name} (${user.email})?`)) {
							dispatch(
								deleteUser({
									wholesalerId: query.wholesalerId,
									db: query.db,
									customId: user.customId,
								})
							);
						}
					}}
				>
					Delete
				</Button>
				<SpacingBoxView />
				<Button
					variant="outlined"
					fullWidth
					onClick={handleSubmit((vals) => {
						const teamLeadsNames: { [customId: string]: string } = {};
						teamLeads.forEach(
							(item) => (teamLeadsNames[item.key] = item.label)
						);
						dispatch(
							patchUsers(
								{
									customIds: [props.userId],
									wholesalerId: query.wholesalerId,
									db: query.db,
								},
								{
									patch: {
										...vals,
										routeNumber: vals.routeNumber
											.split(',')
											.map((item: string) => Number(item))
											.filter(Boolean),
										teamLead: teamLead.map((customId) => ({
											customId,
											name: teamLeadsNames[customId],
										})),
									},
								}
							)
						);
					})}
				>
					Save
				</Button>
			</Box>
		</>
	);
}
