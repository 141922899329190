import React from "react";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SpacingBox from "../spacingBox/SpacingBoxView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import debounce from "lodash/debounce";
import Button from "@material-ui/core/Button";
import { postUser } from "../../warehouse/usersWarehouse";
import createUserPrompt from "../../utils/createUserPrompt";

export const sortingOptions = [
  {
    label: "GP Achieved",
    value: "rank",
  },
  {
    label: "CE Achieved",
    value: "volumeRank",
  },
  {
    label: "Total Payout",
    value: "-totalPayout",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(),
    },
  },
}));

export default function SalesRepsControls() {
  const { query, setQuery } = useQuery<ISalesRepsQuery>();
  const role = useSelector((state: RootState) => state.session.role);
  const teamLeads = useSelector((state: RootState) => {
    return Object.keys(state.usersWarehouse.usersById)
      .filter(
        (userId) =>
          state.usersWarehouse.usersById[userId].role === "teamLead" &&
          !state.usersWarehouse.usersById[userId].leadership
      )
      .map((userId) => ({
        name: state.usersWarehouse.usersById[userId].name,
        customId: state.usersWarehouse.usersById[userId].customId,
      }));
  });
  const classes = useStyles();

  const onSearch = debounce(
    (event) => setQuery({ searchPhrase: event.target.value?.toLowerCase() }),
    500
  );
  const dispatch = useDispatch();
  const addUser = () => {
    const creds = createUserPrompt();
    if (creds) {
      dispatch(
        postUser(
          { wholesalerId: query.wholesalerId, db: query.db },
          {
            role: "salesRep",
            ...creds,
          },
          () => {
            setQuery({
              selectedUser: creds.customId,
              searchPhrase: creds.name.toLowerCase(),
              bulkEdit: "true",
            });
          }
        )
      );
    }
  };

  return (
    <Box className={classes.container}>
      <FormControl variant="outlined" size="small">
        <InputLabel htmlFor="outlined-age-native-simple">Team</InputLabel>
        <Select
          native
          value={query.team}
          onChange={(e) =>
            setQuery({
              team:
                (e.target.value as string) === "All"
                  ? undefined
                  : (e.target.value as string),
            })
          }
          label="Team"
        >
          <option value="All">All</option>
          {teamLeads.map((teamLead) => (
            <option key={teamLead.customId} value={teamLead.customId}>
              {teamLead.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <SpacingBox />
      <FormControl variant="outlined" size="small">
        <InputLabel htmlFor="outlined-age-native-simple">Sort by</InputLabel>
        <Select
          native
          value={query.sortBy}
          onChange={(e) => setQuery({ sortBy: e.target.value as string })}
          label="Sort by"
        >
          {sortingOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <SpacingBox />
      <TextField
        defaultValue={query.searchPhrase}
        variant="outlined"
        size="small"
        label="Search by name"
        onChange={onSearch}
      />
      {role === "admin" && (
        <>
          <SpacingBox />
          <Button
            // variant={query.bulkEdit === 'true' ? 'contained' : 'outlined'}
            variant="outlined"
            color={query.bulkEdit === "true" ? "secondary" : undefined}
            onClick={() => {
              setQuery({
                bulkEdit: query.bulkEdit === "true" ? undefined : "true",
              });
            }}
          >
            {query.bulkEdit === "true" ? "Cancel Bulk Edit" : "Bulk Edit"}
          </Button>
          <SpacingBox />
          <Button variant="outlined" onClick={addUser}>
            Create SalesRep
          </Button>
        </>
      )}
    </Box>
  );
}
