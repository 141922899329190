import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { IGeneralQuery } from "../../hooks/useQuery";
import { IUser } from "../../interfaces";
import api from "../../utils/api";
import { IRole } from "../../utils/roles";
import snack from "../../utils/snack";

type IStatus = "idle" | "loading";
interface sessionState {
  isLoggedIn: boolean;
  wholesalerId?: string;
  customId?: string;
  authToken?: string;
  name?: string;
  email?: string;
  role?: IRole;
  status: IStatus;
  avatarHash?: string;
}

interface ILoggedInPayload {
  token: string;
  name: string;
  email: string;
  wholesalerId: string;
  customId: string;
  role: IRole;
}

const initialState: sessionState = {
  isLoggedIn: false,
  status: "idle",
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      api.config.setToken();
      state.authToken = undefined;
    },
    loggedIn: (state, action: PayloadAction<ILoggedInPayload>) => {
      state.status = "idle";
      state.authToken = action.payload.token;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.wholesalerId = action.payload.wholesalerId;
      state.customId = action.payload.customId;
      state.isLoggedIn = true;
    },
    profilePatched: (state, action: PayloadAction<IUser>) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    setAvatarHash: (state) => {
      state.avatarHash = Math.random().toString();
    },
  },
});

export const {
  loggedIn,
  logOut,
  setStatus,
  profilePatched,
  setAvatarHash,
} = sessionSlice.actions;

export const logInWithPassword = (
  email: string,
  password: string
): AppThunk => async (dispatch) => {
  dispatch(setStatus("loading"));
  const res = await api.fetch({
    path: "/login",
    method: "POST",
    headers: {
      Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString(
        "base64"
      )}`,
    },
  });
  if (res.token) {
    api.config.setToken(res.token);
    dispatch(
      loggedIn({
        token: res.token,
        name: res.payload.name,
        role: res.payload.role,
        email: res.payload.email,
        wholesalerId: res.payload.wholesalerId,
        customId: res.payload.customId,
      })
    );
  }
  dispatch(setStatus("idle"));
};

export const postRequestPasswordReset = (
  email: string
): AppThunk => async () => {
  const res = await api.fetch({
    path: "/passwordResetRequest",
    method: "POST",
    body: { email },
  });
  if (res.ok) {
    snack.success("Check your email");
  }
};

interface IPasswordReset {
  email: string;
  password: string;
  otp: string;
}

export const postPasswordReset = (
  body: IPasswordReset,
  cb: () => void
): AppThunk => async () => {
  const res = await api.fetch({
    path: "/passwordReset",
    method: "POST",
    body,
  });
  if (res.ok) {
    snack.success("Password changes. Now you can log in.");
    cb();
  }
};

interface IPatchProfileBody {
  email: string;
  name: string;
}

export const patchProfile = (
  query: IGeneralQuery,
  body: IPatchProfileBody
): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: "/userSelf",
    method: "PATCH",
    query,
    body,
  });
  if (res.ok) {
    dispatch(profilePatched(res.payload));
    snack.success("Profile updated!");
  }
};

interface IPostUserAvatarResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export const postUserAvatar = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  const res = await api.fetch({
    path: "/userAvatar",
    method: "POST",
    query,
  });
  if (res.ok) {
    const payload: IPostUserAvatarResponse = res.payload;
    const formdata = new FormData();
    Object.keys(payload.fields).forEach((fieldName) => {
      formdata.append(fieldName, payload.fields[fieldName]);
    });
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.click();
    await new Promise((resolve) => {
      fileInput.onchange = resolve;
    });

    if (!fileInput.files?.[0]) {
      snack.error("No picture selected");
      return;
    }
    formdata.append("file", fileInput.files[0]);
    snack.info("Saving...");
    await fetch(payload.url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        return response.text();
      })
      .then(() => {
        snack.success("Profile picture uploaded! 🚀");
        dispatch(setAvatarHash());
      })
      .catch((error) => {
        snack.error("Picture uploading error 😱");
        console.error("failed to upload file", error);
      });
  }
};

export default sessionSlice.reducer;
