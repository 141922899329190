import React from "react";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import capitalize from "lodash/capitalize";
import { usd, delta, volume } from "../../utils/format";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useGap from "../../hooks/useGap";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import makeAvatarUrl from "../../utils/makeAvatarUrl";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import UserCollegues from "./UserCollegues";
import Scroller from "../scroller/Scroller";
import get from "lodash/get";
import snack from "../../utils/snack";
import useKeyPress from "../../hooks/useKeyPress";
import { patchUsers, IUserPatch } from "../../warehouse/usersWarehouse";
import set from "lodash/set";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import isEmail from "../../utils/isEmail";
import UserEditCard from "./UserEditCard";

interface IProps {
  userId: string;
  isCompared?: boolean;
}

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  infoCol: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
    overflow: "hidden",
    minWidth: 56,
  },
  block: {
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },
  title: {
    padding: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  success: {
    color: theme.palette.success.dark,
  },
  error: {
    color: theme.palette.error.dark,
  },
  header: {
    fontWeight: 500,
  },
  teamLeadsHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  infoBlock: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
  },
  adminInfoBlock: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    justifyContent: "center",
  },
  infoTable: {
    flexDirection: "row",
    display: "flex",
    marginLeft: theme.spacing(2),
    overflow: "hidden",
  },
  avatar: {
    height: theme.spacing(9),
    width: theme.spacing(9),
  },
  table: {
    "& td, th": {
      paddingRight: 8,
      paddingLeft: 0,
    },
    "& tbody td:nth-child(1)": {
      paddingLeft: 16,
    },
  },
  container: {
    width: 375,
    minWidth: 375,
    display: "flex",
    flexDirection: "column",
  },
  elipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  empty: {
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  emptyText: {
    marginBottom: theme.spacing(2),
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    flex: 1,
  },
  adminHint: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(),
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  accordion: {
    boxShadow: "none",
  },
  pointer: {
    cursor: "pointer",
  },
}));

export default function UsersDetailsView(props: IProps) {
  const user = useSelector(
    (state: RootState) => state.usersWarehouse.usersById[props.userId]
  );
  const isAdmin = useSelector(
    (state: RootState) => state.session.role === "admin"
  );
  const gap = useGap({ userId: props.userId });
  const classes = useStyles();
  const { query, setQuery } = useQuery<ISalesRepsQuery>();
  const onClose = () => {
    if (query.compareWith?.includes(props.userId)) {
      const compareWith = query.compareWith.filter(
        (item) => item !== props.userId
      );
      setQuery({ compareWith });
    } else {
      setQuery({ selectedUser: undefined });
    }
  };

  const altKeyPressed = useKeyPress("Alt");

  const propsLabels = {
    name: "Name",
    email: "Email",
    routeNumber: "Routes (comma-separated numbers)",
    totalPayout: "Total payout",
    "gp.lastYear": "GP MTD LY",
    "gp.thisYear": "GP MTD TY",
    "gp.goal": "GP Goal %",
    "gp.achieved": "GP % vs Goal",
    "gp.achievedSellingDays": "GP PEM %",
    "gp.totalPayout": "GP Total Payout",
    "volume.lastYear": "Volume MTD LY",
    "volume.thisYear": "Volume MTD TY",
    "volume.goal": "Volume Goal %",
    "volume.achieved": "Volume % vs Goal",
    "volume.achievedSellingDays": "Volume PEM %",
    "volume.totalPayout": "Volume PaTotal Payoutyout",
    "fpl.individual": "FPL Individual",
    "fpl.team": "FPL Team",
    "fpl.goal": "FPL Goal",
    "fpl.totalPayout": "FPL Total Payout",
  };
  const numericValue = {
    validate: (str: string) => /^[0-9.,]{1,9}$/.test(str),
    message: "Should be a numeric value",
  };
  const propsValidator: {
    [key: string]: { validate: (str: string) => boolean; message: string };
  } = {
    name: {
      validate: (str) => /^[a-zA-Z0-9 ']{1,30}$/.test(str),
      message: "Should be 1 to 30 letters and spaces",
    },
    email: {
      validate: isEmail,
      message: "It doesn't look like an email",
    },
    routeNumber: {
      validate: (str) => /^[0-9, ]{1,200}$/.test(str),
      message: "Should be comma-separated numbers",
    },
  };
  const dispatch = useDispatch();

  const patchProperty = (patch: IUserPatch) => {
    dispatch(
      patchUsers(
        {
          customIds: [props.userId],
          wholesalerId: query.wholesalerId,
          db: query.db,
        },
        { patch }
      )
    );
  };
  const onEdit =
    (propId: keyof typeof propsLabels): React.MouseEventHandler<any> =>
    (e) => {
      if (isAdmin && e.altKey) {
        const value = prompt(propsLabels[propId], get(user, propId));
        window.dispatchEvent(new KeyboardEvent("keyup", { key: "Alt" }));
        const validator = propsValidator[propId] || numericValue;
        if (validator.validate(value || "")) {
          const patch: IUserPatch = {};
          if (propId === "routeNumber") {
            patch.routeNumber = value!
              .split(",")
              .map((item) => Number(item))
              .filter(Boolean);
          } else {
            set(patch, propId, value);
          }
          patchProperty(patch);
        } else if (value !== null) {
          snack.error(validator.message);
        }
      }
    };

  if (!user) {
    return (
      <Card className={[classes.container, classes.empty].join(" ")}>
        <Typography className={classes.emptyText}>
          User {props.userId} not found
        </Typography>
        <Button onClick={onClose}>Close this card</Button>
      </Card>
    );
  }

  const underline = altKeyPressed && isAdmin ? classes.underline : "";
  const underlineClass =
    altKeyPressed && isAdmin ? { className: underline } : {};
  return (
    <Scroller>
      <Card className={classes.container}>
        <Box className={classes.title}>
          {props.isCompared ? (
            <>
              <Box
                onClick={(e) => {
                  if (e.altKey) {
                    onEdit("name")(e);
                  } else {
                    setQuery({ selectedUser: props.userId });
                  }
                }}
              >
                <Typography {...underlineClass} variant="h5">
                  {user.name}
                </Typography>
              </Box>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Typography
              variant="h5"
              {...underlineClass}
              onClick={onEdit("name")}
            >
              {user.name}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box className={classes.scroll}>
          <Box className={classes.infoBlock}>
            <Avatar
              className={classes.avatar}
              src={makeAvatarUrl(user.wholesaler.customId, user.customId)}
            >
              {user.name?.match(/\b(\w)/g)?.join("")}
            </Avatar>
            <Box className={classes.infoTable}>
              <Box className={classes.infoCol}>
                {isAdmin && (
                  <Typography className={classes.header}>ID:</Typography>
                )}
                <Typography className={classes.header}>Email:</Typography>
                <Typography className={classes.header}>Role:</Typography>
                <Typography className={classes.header}>
                  Route{user.routeNumber.length > 1 ? "s" : ""}:
                </Typography>
                <Typography className={classes.header}>Payout:</Typography>
              </Box>
              <Box className={classes.infoCol}>
                {isAdmin && (
                  <Tooltip title={user.customId}>
                    <Typography
                      className={`${classes.elipsis} ${underline} ${classes.pointer}`}
                      onClick={() =>
                        window.prompt(
                          "Copy to clipboard: Ctrl+C, Enter",
                          user.customId
                        )
                      }
                    >
                      {user.customId}
                    </Typography>
                  </Tooltip>
                )}
                <Tooltip title={user.email}>
                  <Typography
                    className={`${classes.elipsis} ${underline}`}
                    onClick={onEdit("email")}
                  >
                    {user.email}
                  </Typography>
                </Tooltip>
                <Typography>{capitalize(user.role)}</Typography>
                {user.routeNumber.length > 5 ? (
                  <Tooltip title={user.routeNumber.join(", ")}>
                    <Typography
                      onClick={onEdit("routeNumber")}
                      className={`${classes.elipsis} ${underline}`}
                    >
                      {user.routeNumber.join(", ")}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    onClick={onEdit("routeNumber")}
                    className={`${classes.elipsis} ${underline}`}
                  >
                    {user.routeNumber.join(", ") || "none"}
                  </Typography>
                )}
                <Typography {...underlineClass} onClick={onEdit("totalPayout")}>
                  {usd(user.totalPayout)}
                </Typography>
              </Box>
            </Box>
          </Box>
          {isAdmin && (
            <>
              <Divider />
              <Box className={classes.adminInfoBlock}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    patchProperty({ viewOnly: !user.viewOnly });
                  }}
                  color={user.viewOnly ? "primary" : "default"}
                >
                  View Only
                </Button>
                <SpacingBoxView />
                <Button
                  variant="outlined"
                  onClick={() => {
                    patchProperty({ hidden: !user.hidden });
                  }}
                  color={user.hidden ? "primary" : "default"}
                >
                  Hidden
                </Button>
                {user.role === "teamLead" && (
                  <>
                    <SpacingBoxView />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        patchProperty({ leadership: !user.leadership });
                      }}
                      color={user.leadership ? "primary" : "default"}
                    >
                      Leadership
                    </Button>
                  </>
                )}
              </Box>

              <Divider />
            </>
          )}

          <TableContainer className={classes.block}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">MTD TY</TableCell>
                  <TableCell align="center">Goal</TableCell>
                  <TableCell align="center">% vs Goal</TableCell>
                  <TableCell align="center">PEM%</TableCell>
                  <TableCell align="center">Payout</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("gp.lastYear")}
                    variant="head"
                  >
                    GP:
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("gp.thisYear")}
                    align="center"
                  >
                    {usd(user.gp.thisYear)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("gp.goal")}
                    align="center"
                  >
                    {usd((user.gp.goal * user.gp.lastYear) / 100)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("gp.achieved")}
                    align="center"
                  >
                    {delta(user.gp.achieved, 0)}
                  </TableCell>
                  <TableCell
                    onClick={onEdit("gp.achievedSellingDays")}
                    align="center"
                    className={
                      user.gp.achievedSellingDays >= 100
                        ? `${classes.success} ${underline}`
                        : `${classes.error} ${underline}`
                    }
                  >
                    {delta(user.gp.achievedSellingDays, 0)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("gp.totalPayout")}
                    align="center"
                  >
                    {usd(user.gp.totalPayout)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("volume.lastYear")}
                    variant="head"
                  >
                    Vol:
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("volume.thisYear")}
                    align="center"
                  >
                    {volume(user.volume.thisYear)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("volume.goal")}
                    align="center"
                  >
                    {volume((user.volume.goal * user.volume.lastYear) / 100)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("volume.achieved")}
                    align="center"
                  >
                    {delta(user.volume.achieved, 0)}
                  </TableCell>
                  <TableCell
                    onClick={onEdit("volume.achievedSellingDays")}
                    align="center"
                    className={
                      user.volume.achievedSellingDays >= 100
                        ? `${classes.success} ${underline}`
                        : `${classes.error} ${underline}`
                    }
                  >
                    {delta(user.volume.achievedSellingDays, 0)}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("volume.totalPayout")}
                    align="center"
                  >
                    {usd(user.volume.totalPayout)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer className={classes.block}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Individual</TableCell>
                  <TableCell align="center">Team</TableCell>
                  <TableCell align="center">Team target</TableCell>
                  <TableCell align="center">Payout</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">FPL:</TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("fpl.individual")}
                    align="center"
                  >
                    {user.fpl.individual}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("fpl.team")}
                    align="center"
                  >
                    {user.fpl.team}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("fpl.goal")}
                    align="center"
                  >
                    {user.fpl.goal}
                  </TableCell>
                  <TableCell
                    {...underlineClass}
                    onClick={onEdit("fpl.totalPayout")}
                    align="center"
                  >
                    {usd(user.fpl.totalPayout)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">GP per day</TableCell>
                  <TableCell align="center">Vol per day</TableCell>
                  <TableCell align="center">Days left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">GAP to target:</TableCell>
                  <TableCell
                    align="center"
                    style={{ color: gap.gp.color }}
                    color={gap.gp.color}
                  >
                    {gap.gp.text}
                  </TableCell>
                  <TableCell align="center" style={{ color: gap.volume.color }}>
                    {gap.volume.text}
                  </TableCell>
                  <TableCell align="center">{gap.daysLeft}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <UserCollegues userId={props.userId} />
          {isAdmin && <UserEditCard key={props.userId} userId={props.userId} />}
        </Box>
      </Card>
      {isAdmin && (
        <Box className={classes.adminHint}>
          <Typography variant="caption" color={"textSecondary"}>
            ALT + Click on any property to edit
          </Typography>
        </Box>
      )}
    </Scroller>
  );
}
