import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Scroller from "../scroller/Scroller";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 375,
    minWidth: 375,
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
  },
  title: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default function WholesalerManagersSkeleton() {
  const classes = useStyles();
  return (
    <Scroller>
      <Paper className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h5">Managers</Typography>
          <Button size="small" variant="outlined">
            Add
          </Button>
        </Box>
        <Divider />
        <List dense>
          {Array(5)
            .fill(undefined)
            .map((_, index) => (
              <ListItem key={`slControlsSkeletonList${index}`}>
                <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              </ListItem>
            ))}
        </List>
      </Paper>
    </Scroller>
  );
}
