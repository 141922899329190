import React, { useState } from 'react';
import { Incentive } from '../../../../types/incentivesTypes';
import { Objective } from '../../../../types/objectiveTypes';
import useAccountTypes from '../../../../hooks/useAccountTypes';
import {
	Box,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { SelectionControls } from '../../selectComponent/SelectionControls';
import { useStyles } from './AccountsSelection.styles';
import { SingleSelect } from '../../../../../select/Select';
import AllAccountsComponent from './AllAccountsComponent';
import SelectedAccountsComponent from './SelectedAccountsComponent';

type ManualAccountsSelectionProps = {
	item: Incentive | Objective;
};

export const ManualAccountsSelection: React.FC<
	ManualAccountsSelectionProps
> = ({ item }) => {
	const classes = useStyles();
	const { accountTypes } = useAccountTypes();

	const [searchTerm, setSearchTerm] = useState('');
	const [selectedAccountType, setSelectedAccountType] = useState('');
	const [premise, setPremise] = useState('');

	const [showSelected, setShowSelected] = useState<boolean>(false);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [clearSelection, setClearSelection] = useState<boolean>(false);

	const handleShowSelectedChange = () => {
		setShowSelected((prev) => !prev);
		setAllSelected(false);
	};

	const handleSearchChange = (event: any) => {
		setSearchTerm(event.target.value);
	};

	const handleSelectAll = () => {
		setAllSelected(true);
		setClearSelection(false);
	};

	const handleClearSelection = () => {
		setClearSelection(true);
		setAllSelected(false);
	};

	return (
		<div className={classes.manualContainer}>
			<Box className={classes.flexColumn}>
				<div className={classes.flexColumn}>
					<SelectionControls
						onSelectAll={handleSelectAll}
						onClear={handleClearSelection}
					/>
					<TextField
						label="Search by title"
						size="small"
						variant="outlined"
						defaultValue={searchTerm}
						onChange={handleSearchChange}
					/>
					<FormControlLabel
						control={
							<Switch
								checked={showSelected}
								onChange={handleShowSelectedChange}
								color="primary"
							/>
						}
						label="Show Selected"
					/>
				</div>
				<div>
					<Typography variant="body1" className={classes.text}>
						Filters:
					</Typography>
					<div className={classes.filters}>
						<SingleSelect
							label="Account Type"
							options={accountTypes}
							defaultValue={selectedAccountType}
							onSelect={(value) => setSelectedAccountType(value as string)}
						/>
						<SingleSelect
							label="Premise"
							options={['on', 'off', 'both']}
							defaultValue={premise}
							onSelect={(value) => setPremise(value as string)}
						/>
					</div>
				</div>
			</Box>
			{showSelected ? (
				<SelectedAccountsComponent
					item={item}
					searchTerm={searchTerm}
					accountType={selectedAccountType}
					premise={premise}
					allSelected={allSelected}
					clearSelection={clearSelection}
				/>
			) : (
					<AllAccountsComponent
						item={item}
						searchTerm={searchTerm}
						accountType={selectedAccountType}
						premise={premise}
						allSelected={allSelected}
						clearSelection={clearSelection}
					/>
			)}
		</div>
	);
	};