import React from "react";
import ExecutiveReportTable from "./ExecutiveReportTable";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
}));

export default function ExecutiveReportSummary() {
  const classes = useStyles();
  const columns = useSelector((state: RootState) =>
    state.executiveReport.summary.columns.filter(
      (_, index) => !state.executiveReport.summary.hiddenColumns.includes(index)
    )
  );
  const rows = useSelector((state: RootState) =>
    state.executiveReport.summary.rows.map((cells) =>
      cells.filter(
        (_, index) =>
          !state.executiveReport.summary.hiddenColumns.includes(index)
      )
    )
  );
  const total = useSelector((state: RootState) =>
    state.executiveReport.summary.total.filter(
      (_, index) => !state.executiveReport.summary.hiddenColumns.includes(index)
    )
  );
  return (
    <Paper className={classes.container}>
      <ExecutiveReportTable
        total={total}
        tableName="summary"
        columns={columns}
        rows={rows}
      />
    </Paper>
  );
}
