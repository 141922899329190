import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { ITableNames } from "./suppliersSlice";
import useQuery from "../../hooks/useQuery";
import { ISuppliersQuery, suppliersSchema } from "./suppliersQuery";

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  firstHeadCell: {
    zIndex: 3,
  },
  highlightedCell: {
    backgroundColor: "rgb(121, 134, 203, 0.1)",
  },
  firstBodyCell: {
    top: "unset",
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  container: {
    whiteSpace: "nowrap",
    maxHeight: "calc(100vh - 164px)",
  },
}));

interface IProps {
  tableName: ITableNames;
}

export default function SuppliersTable({ tableName }: IProps) {
  const classes = useStyles();
  const headers = useSelector(
    (state: RootState) => state.suppliers[tableName].headers
  );
  const rows = useSelector(
    (state: RootState) => state.suppliers[tableName].rows
  );
  const totalsRow = useSelector(
    (state: RootState) => state.suppliers[tableName].totalsRow
  );
  const { query, setQuery } = useQuery<ISuppliersQuery>(suppliersSchema);
  const isLoading = useSelector((state: RootState) => {
    if (tableName === "suppliersTable") {
      return state.suppliersWarehouse.status !== "ready";
    } else {
      return state.brandsWarehouse.status !== "ready";
    }
  });

  if (!rows.length || isLoading) {
    return <></>;
  }
  return (
    <TableContainer
      id="supplierTable"
      component={Paper}
      className={classes.container}
    >
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((cell, cellIndex) => (
              <TableCell
                variant={"head"}
                key={`${tableName}_${cell.content}`}
                className={[cellIndex === 0 && classes.firstHeadCell]
                  .filter(Boolean)
                  .join(" ")}
              >
                {cellIndex === 0 ? (
                  cell.content
                ) : (
                  <TableSortLabel
                    active={query.orderBy === cell.content}
                    direction={query.descending ? "desc" : "asc"}
                    onClick={() =>
                      setQuery({
                        // @ts-ignore
                        orderBy: cell.content,
                        descending: !query.descending,
                      })
                    }
                  >
                    {cell.content}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow hover key={`${tableName}_${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <TableCell
                  variant={cellIndex === 0 ? "head" : "body"}
                  className={[
                    classes.firstBodyCell,
                    cell.color && classes[cell.color],
                  ]
                    .filter(Boolean)
                    .join(" ")}
                  key={`${tableName}_${rowIndex}_${cellIndex}`}
                >
                  {cellIndex === 0 ? `${rowIndex + 1}. ` : ""}
                  {cell.content}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            {totalsRow.map((cell, cellIndex) => (
              <TableCell
                variant={cellIndex === 0 ? "head" : "body"}
                key={`${tableName}_totals_${cellIndex}`}
                className={[
                  classes.firstBodyCell,
                  cell.color && classes[cell.color],
                ]
                  .filter(Boolean)
                  .join(" ")}
              >
                {cell.content}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
