import { Objective, ObjectiveTypeEnum } from "../types/objectiveTypes";
import { Incentive, IncentiveTypeEnum } from "../types/incentivesTypes";

const useItemValidation = <T extends Objective | Incentive>(
	item: T,
	subItems?: T[]
) => {
	let areAdvancedPaymentsValid = true;
	let areSubItemsValid = true;

	if (item.type === (ObjectiveTypeEnum.Multi || IncentiveTypeEnum.Multi)) {
		if (subItems !== undefined) {
			areSubItemsValid = subItems.every((subItem) => subItem.isValid);
		}
	}

	const isFormValid = () => areAdvancedPaymentsValid && areSubItemsValid;

	return {
		areAdvancedPaymentsValid,
		isFormValid,
		areSubItemsValid,
	};
};

export default useItemValidation;
