import React from "react";
import { usd, volume, delta } from "../../utils/format";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { IRecommendationSold } from "../../interfaces";
import isNumber from "lodash/isNumber";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  higher: {
    color: theme.palette.success.dark,
  },
  lower: {
    color: theme.palette.error.dark,
  },
}));

type IData = Pick<IRecommendationSold, "gp" | "volume" | "gpCe">;
interface IProps extends IData {
  accountType: string;
}

export default function BuyerSold(props: IProps) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center">ACCOUNT</TableCell>
          <TableCell align="center" colSpan={2}>
            AVG PKG {props.accountType}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell variant="head">GP:</TableCell>
          <TableCell align="center">{usd(props.gp.total, 2)}</TableCell>
          <TableCell align="center">{usd(props.gp.average, 2)}</TableCell>
          <TableCell align="center">
            <Diff num={props.gp.diff} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">CE:</TableCell>
          <TableCell align="center">{volume(props.volume.total, 2)}</TableCell>
          <TableCell align="center">
            {volume(props.volume.average, 2)}
          </TableCell>
          <TableCell align="center">
            <Diff num={props.volume.diff} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">GP/CE:</TableCell>
          <TableCell align="center">{usd(props.gpCe.total, 2)}</TableCell>
          <TableCell align="center">{usd(props.gpCe.average, 2)}</TableCell>
          <TableCell align="center">
            <Diff num={props.gpCe.diff} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function Diff(props: { num: number }) {
  const classes = useStyles();
  if (!isNumber(props.num)) {
    return <>N/A</>;
  }
  const num = Math.round(props.num);
  if (num === 100) {
    return <>equal</>;
  }
  if (num > 100) {
    return <span className={classes.higher}>{delta(num - 100, 0)} higher</span>;
  }
  // if num < 100
  return (
    <span className={classes.lower}>{delta(Math.abs(num - 100), 0)} lower</span>
  );
}
