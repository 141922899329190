import isArray from "lodash/isArray";

export default function removeUndefined<T>(obj: any): T {
  let newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (isArray(obj[key]) && obj[key].length) {
      newObj[key] = obj[key];
    } else if (obj[key] === Object(obj[key])) {
      newObj[key] = removeUndefined(obj[key]);
    } else if (!isNaN(obj[key]) || typeof obj[key] === "string") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}
