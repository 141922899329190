import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useState } from "react";
import usePodcasts from "./usePodcasts";
import { Podcast } from "./podcastLinksStore";
import { PodcastComponent } from "./PodcastComponent";
import { useStyles } from "./PodcastsComponent.styles";

export const PodcastsComponent = () => {
	const classes = useStyles();
	const { podcasts, addPodcast, editPodcast, deletePodcast } = usePodcasts();
	const [open, setOpen] = useState(false);
	const [name, setName] = useState("");
	const [link, setLink] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
		setName("");
		setLink("");
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAdd = async () => {
		await addPodcast({ name, url: link });
		setOpen(false);
		setName("");
		setLink("");
	};

	const handleEdit = (id: string, editedPodcast: Podcast) => {
		editPodcast(id, editedPodcast);
	};

	const handleDelete = (id: string) => {
		deletePodcast(id);
	};

	return (
		<div className={classes.container}>
			<div>
				<Button
					className={classes.button}
					color="primary"
					startIcon={<AddCircleOutlineIcon />}
					onClick={handleClickOpen}
				>
					New Podcast
				</Button>
			</div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Podcast</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						type="text"
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						margin="dense"
						id="link"
						label="Link"
						type="text"
						fullWidth
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleAdd} color="primary">
						Add
					</Button>
				</DialogActions>
			</Dialog>
			<div className={classes.listWrapper}>
				<div className={classes.itemList}>
					{podcasts.length > 0 && (
						<Table className={classes.customTable}>
							<TableHead>
								<TableRow className={classes.tableHead}>
									<TableCell className={classes.customCell}>
										Name
									</TableCell>
									<TableCell className={classes.customCell}>
										URL
									</TableCell>
									<TableCell className={classes.customCell}>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{podcasts.map((podcast) => (
									<PodcastComponent
										key={podcast._id}
										podcast={podcast}
										onEdit={handleEdit}
										onDelete={handleDelete}
									/>
								))}
							</TableBody>
						</Table>
					)}
				</div>
			</div>
		</div>
	);
};
