import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { makeStyles } from '@material-ui/core/styles';
import {
	getWholesalerUpdate,
	postWholesalerUpdate,
	publish,
	reset,
} from './wholesalerSlice';
import SpacingBoxView from '../spacingBox/SpacingBoxView';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ProgressIcon from '@material-ui/icons/Autorenew';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';
import { Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import snack from '../../utils/snack';
import {
	deleteWholesaler,
	postWholesaler,
} from '../../warehouse/wholesalerWarehouse';
import WholesalerDataDeletionForm from './WholesalerDataDeletionForm';
import Scroller from '../scroller/Scroller';
import useObjectivesIncentivesStore from './useObjIncUpdateStore';

const statusIcons = {
	Preparing: () => <ProgressIcon htmlColor={orange['300']} />,
	'Running GBQ': () => <MultilineChartIcon htmlColor={orange['300']} />,
	'Transferring to MongoDB': () => (
		<DoubleArrowIcon htmlColor={orange['300']} />
	),
	'GBQ error': () => <ErrorIcon htmlColor={red['300']} />,
	'Transfer error': () => <ErrorIcon htmlColor={red['300']} />,
	Completed: () => <CheckIcon htmlColor={green['300']} />,
};

const useStyles = makeStyles((theme) => ({
	title: {
		padding: theme.spacing(2),
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
	},
	contentScroll: {
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		'-ms-overflow-style': 'none',
		'scrollbar-width': 'none',
		overflowY: 'auto',
		height: '100%',
	},
	wholesalerUpdateList: {
		minWidth: 375,
		width: 375,
	},
	avatar: {
		backgroundColor: 'white',
	},
	buttonWrapper: {
		padding: theme.spacing(2),
		flexDirection: 'row',
		display: 'flex',
	},
}));

export default function WholesalerUpdateView() {
	const classes = useStyles();
	const { query, setQuery } = useQuery<IGeneralQuery>();
	const isGeneralAdmin = useSelector(
		(state: RootState) =>
			state.session.role === 'admin' && !state.session.wholesalerId
	);
	const { updateObjectivesIncentives } = useObjectivesIncentivesStore();
	const wholesalerUpdate = useSelector((state: RootState) => {
		const {
			wholesalerId,
			brands,
			buyers,
			products,
			suppliers,
			users,
			wholesaler,
		} = state.wholesaler.wholesalerUpdate;
		const isReady = state.wholesaler.status === 'ready';
		const statusData = [
			{
				title: 'Brands',
				status: brands.status,
				updatedAt: brands.updatedAt,
				errorMessage: brands.message,
				url: brands.url,
			},
			{
				title: 'Buyers',
				status: buyers.status,
				updatedAt: buyers.updatedAt,
				errorMessage: buyers.message,
				url: buyers.url,
			},
			{
				title: 'Products',
				status: products.status,
				updatedAt: products.updatedAt,
				errorMessage: products.message,
				url: products.url,
			},
			{
				title: 'Suppliers',
				status: suppliers.status,
				updatedAt: suppliers.updatedAt,
				errorMessage: suppliers.message,
				url: suppliers.url,
			},
			{
				title: 'Users',
				status: users.status,
				updatedAt: users.updatedAt,
				errorMessage: users.message,
				url: users.url,
			},
			{
				title: 'Wholesaler',
				status: wholesaler.status,
				updatedAt: wholesaler.updatedAt,
				errorMessage: wholesaler.message,
			},
		];
		const interval = Date.now() - 120000;
		let canRun = isReady;
		if (!statusData.filter((item) => item.errorMessage).length) {
			for (const item of statusData) {
				if (!canRun) {
					break;
				}
				if (item.status !== 'Completed' && item.updatedAt > interval) {
					canRun = false;
				}
			}
		}
		return { statusData, wholesalerId, canRun };
	});
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getWholesalerUpdate());
		const interval = setInterval(() => {
			!document.hidden && dispatch(getWholesalerUpdate(true));
		}, 2000);
		return () => clearInterval(interval);
	}, [dispatch]);
	const handleCreateWholesaler = () => {
		if (query.db !== 'preview') {
			snack.warning('Switch to "Preview" to do that');
			return;
		}
		const newWholesalerId = prompt('Enter new wholesaler ID');
		if (!newWholesalerId) {
			return;
		}
		if (!/^[A-Za-z]{3,10}$/.test(newWholesalerId)) {
			snack.error('Wholesaler ID should include 3 to 10 letters');
			return;
		}
		dispatch(
			postWholesaler(
				{ db: query.db, wholesalerId: query.wholesalerId },
				{ customId: newWholesalerId },
				() => {
					setQuery({ wholesalerId: newWholesalerId });
				}
			)
		);
	};
	const handleDeleteWholesaler = () => {
		if (
			window.confirm(`Do you want to delete wholesaler ${query.wholesalerId}?`)
		) {
			const deleteEverything =
				window.confirm(
					`Do you want to delete ${query.wholesalerId} from both Production and Preview databases?`
				) || false;
			if (
				window.confirm(
					`Are you 100% sure you want to delete ${query.wholesalerId}, all associated users, products, settings and so on? This action is hard to undo.`
				)
			) {
				dispatch(
					deleteWholesaler(
						{ db: query.db, wholesalerId: query.wholesalerId },
						{ deleteEverything },
						() => {
							setQuery({ wholesalerId: undefined });
						}
					)
				);
			}
		}
	};
	return (
		<Paper>
			<Box className={classes.title}>
				<Typography variant="h5">
					Update pipeline is {wholesalerUpdate.canRun ? 'READY' : 'BUSY'}
				</Typography>
			</Box>
			<Divider />
			<Scroller>
				<Box className={classes.contentScroll}>
					{!!isGeneralAdmin && (
						<>
							<List className={classes.wholesalerUpdateList} dense>
								{wholesalerUpdate.statusData.map((item) => {
									const Icon = statusIcons[item.status];
									return (
										<Tooltip
											key={item.title}
											disableFocusListener={!item.errorMessage}
											title={
												item.errorMessage
													? `${item.url ? '[CLICK FOR MORE]' : ''} ${item.errorMessage}`
													: `${item.url ? '[CLICK FOR MORE]' : ''}`
											}
										>
											<ListItem
												{...(item.url && {
													component: Link,
													to: { pathname: item.url },
													target: '_blank',
												})}
											>
												<ListItemAvatar>
													<Avatar className={classes.avatar}>
														<Icon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={`[${wholesalerUpdate.wholesalerId}] ${item.title}`}
													secondary={`${item.status} ${moment(
														item.updatedAt
													).fromNow()}`}
												/>
											</ListItem>
										</Tooltip>
									);
								})}
							</List>
							<Divider />
						</>
					)}
					<Box className={classes.buttonWrapper}>
						<Button
							disabled={!wholesalerUpdate.canRun}
							onClick={() => {
								if (query.db === 'preview') {
									dispatch(
										postWholesalerUpdate({ wholesalerId: query.wholesalerId })
									);
								} else {
									snack.warning('Switch to "Preview" to do that');
								}
							}}
							variant="outlined"
							fullWidth
						>
							Run update for {query.wholesalerId}
						</Button>
					</Box>
					<Divider />

					<Box className={classes.buttonWrapper}>
						<Button
							disabled={!wholesalerUpdate.canRun}
							onClick={() => {
								if (query.db === 'preview' && query.wholesalerId) {
									updateObjectivesIncentives({
										wholesalerId: query?.wholesalerId,
									});
								} else {
									snack.warning('Switch to "Preview" to do that', 10000);
								}
							}}
							variant="outlined"
							fullWidth
						>
							Get payout for objectives/incentives
						</Button>
					</Box>
					<Divider />
					<Box className={classes.buttonWrapper}>
						<Button
							disabled={!wholesalerUpdate.canRun}
							onClick={() => {
								dispatch(
									publish({
										wholesalerId: query.wholesalerId,
										db: query.db,
									})
								);
							}}
							variant="outlined"
							fullWidth
						>
							Publish {query.wholesalerId}
						</Button>
						<SpacingBoxView />
						<Button
							disabled={!wholesalerUpdate.canRun}
							onClick={() => {
								dispatch(
									reset({ wholesalerId: query.wholesalerId, db: query.db })
								);
							}}
							variant="outlined"
							fullWidth
						>
							Reset {query.wholesalerId}
						</Button>
					</Box>
					{!!isGeneralAdmin && (
						<>
							<Divider />
							<Box className={classes.buttonWrapper}>
								<Button
									disabled={!wholesalerUpdate.canRun}
									onClick={handleDeleteWholesaler}
									variant="outlined"
									fullWidth
								>
									Delete {query.wholesalerId}
								</Button>
								<SpacingBoxView />
								<Button
									disabled={!wholesalerUpdate.canRun}
									onClick={handleCreateWholesaler}
									variant="outlined"
									fullWidth
								>
									Create new wholesaler
								</Button>
							</Box>
							<Divider />
							<WholesalerDataDeletionForm isReady={wholesalerUpdate.canRun} />
						</>
					)}
				</Box>
			</Scroller>
		</Paper>
	);
}
