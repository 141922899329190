import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../../hooks/useQuery";
import { objectivesStoreZustand } from "../../store/objectivesStore";

const useReportGroupedObjectives = ({
	searchPhrase,
}: {
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);
	const [loadingChildren, setLoadingChildren] = useState(true);

	const { objectives, reportingGroupedPage, setReportingGroupedPage } =
		objectivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllChildrenByUser = async () => {
			setLoadingChildren(true);

			const objectivesStore = objectivesStoreZustand.getState();
			const objectives = objectivesStore.objectives;

			const childrenFetchPromises = objectives.map(async (objective) => {
				if (objective.users) {
					const userFetchPromises = objective.users.map((user) =>
						objectivesStore.fetchChildrenByUser(
							{ wholesalerId },
							objective._id,
							user.user
						)
					);
					await Promise.all(userFetchPromises);
				}
			});

			await Promise.all(childrenFetchPromises);

			setLoadingChildren(false);
		};
		objectivesStoreZustand.getState().fetchObjectivesReportTable(
			{
				wholesalerId,
				page: reportingGroupedPage,
				limit: secondaryPageSize,
				searchPhrase,
				type: "grouped",
			},
			fetchAllChildrenByUser
		);
	}, [wholesalerId, reportingGroupedPage, secondaryPageSize, searchPhrase]);

	return {
		objectives,
		reportingGroupedPage,
		setReportingGroupedPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: objectivesStoreZustand((state) => state.totalPages),
		loading: objectivesStoreZustand((state) => state.loading),
		setLoading: objectivesStoreZustand((state) => state.setLoading),
		loadingChildren,
	};
};

export default useReportGroupedObjectives;
