import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		marginLeft: "0",
	},
	productsContainer: {
		display: "flex",
		flexDirection: "column",
		width: 206,
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(),
	},
	marginTop: {
		marginTop: 10,
	},
	flexColumn: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
	},
	listContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "70%",
		minWidth: "70%",
		minHeight: "40rem",
		flexGrow: 1,
		paddingBottom: theme.spacing(2),
	},
	content: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	},
	buyersList: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		marginTop: 20,
	},
	primaryText: {
		whiteSpace: "break-spaces",
		marginLeft: "15px",
	},
	secondaryText: {
		whiteSpace: "break-spaces",
		marginLeft: "20px",
	},
	inputWrapper: {
		minWidth: 185,
	},
	selectionContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	progress: {
		alignSelf: "center",
		marginTop: 20,
	},
	list: {
		display: "flex",
		flexDirection: "column",
	},
	pager: {
		padding: theme.spacing(1),
		margin: "auto",
	},
	pagination: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(2),
		width: "100%",
	},
	notFound: {
		marginTop: "1rem",
	},
	table: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		width: "100%",
	},
	button: {
		width: "100%",
	},
	text: {
		marginBottom: "1rem",
	},
	boxContainer: {
		maxWidth: "90%",
		flex: "1",
	},
	manualContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		gap: "1.5rem",
		flex: "1",
	},
	filters: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		marginTop: "1rem",
	},
	tableContainer: {
		flex: 1,
		overflow: "auto",
	},
	saveButtonContainer: {
		display: "flex",
		justifyContent: "center",
		padding: theme.spacing(2),
	},
}));
